import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import Api from "../../config/Api";
import { toast } from "react-toastify";
import { redirect } from "react-router-dom";

export const getAllStudents = createAsyncThunk(
  "students/getAll",
  async (body = {}) => {
    const response = await Api.get("/student");
    return response.data;
  }
);

export const getStudent = createAsyncThunk(
  "students/get",
  async (body = {}) => {
    const response = await Api.get(`/student/${body}`);
    return response.data;
  }
);

export const getStudentData = createAsyncThunk(
  "students/getData",
  async (body = {}) => {
    const response = await Api.get(`/student/data/${body}`);
    return response.data;
  }
);

export const getStudentGroups = createAsyncThunk(
  "students/getGroups",
  async (body = {}) => {
    const response = await Api.get(`/student/groups/${body}`);
    return response.data;
  }
);

export const getStudentPayments = createAsyncThunk(
  "students/getPayments",
  async (body = {}) => {
    const response = await Api.get(`/student/payments/${body}`);
    return response.data;
  }
);

export const createStudent = createAsyncThunk(
  "students/create",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.post("/student", body?.data);
      body?.navigate();
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateStudent = createAsyncThunk(
  "students/update",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.put(`/student/${body?.id}`, body);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteStudent = createAsyncThunk(
  "students/delete",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.delete(`/student/${body?.id}`);

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const studentsSlice = createSlice({
  name: "students",
  initialState: {
    student: null,
    studentData: null,
    students: null,

    groups: null,
    payments: null,
    loading: false,
    updateLoading: false,
    error: null,
  },
  reducers: {
    resetError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      ///------------ GET students ------------------/////
      .addCase(getAllStudents.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllStudents.fulfilled, (state, action) => {
        state.loading = false;
        const students = action.payload?.data;
        // students.map((el) => {
        //   Object.keys(el.student.user).forEach((key) => {
        //     el[`student.user.${key}`] = el.student.user[key];
        //   });
        //   delete el.student.user;
        //   return el;
        // });
        state.students = students;
      })
      .addCase(getAllStudents.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ GET student ------------------/////
      .addCase(getStudent.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStudent.fulfilled, (state, { payload }) => {
        state.loading = false;

        state.student = payload?.data;
      })
      .addCase(getStudent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ GET student ------------------/////
      .addCase(getStudentData.pending, (state) => {
        // state.loading = true;
      })
      .addCase(getStudentData.fulfilled, (state, { payload }) => {
        // state.loading = false;

        state.studentData = payload?.data;
      })
      .addCase(getStudentData.rejected, (state, action) => {
        // state.loading = false;
        state.error = action.payload;
      })

      ///------------ GET student groups ------------------/////
      .addCase(getStudentGroups.pending, (state) => {
        // state.loading = true;
      })
      .addCase(getStudentGroups.fulfilled, (state, { payload }) => {
        // state.loading = false;

        state.groups = payload?.data;
      })
      .addCase(getStudentGroups.rejected, (state, action) => {
        // state.loading = false;
        state.error = action.payload;
      })

      ///------------ GET student payments ------------------/////
      .addCase(getStudentPayments.pending, (state) => {
        // state.loading = true;
      })
      .addCase(getStudentPayments.fulfilled, (state, { payload }) => {
        // state.loading = false;

        state.payments = payload?.data;
      })
      .addCase(getStudentPayments.rejected, (state, action) => {
        // state.loading = false;
        state.error = action.payload;
      })

      ///------------ CREATE students ------------------/////
      .addCase(createStudent.pending, (state) => {
        state.loading = true;
      })
      .addCase(createStudent.fulfilled, (state, { payload }) => {
        state.loading = false;
        // state.students = [payload?.data, ...state.students];
        toast.success(payload?.message);
      })
      .addCase(createStudent.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        toast.error(payload?.message);
      })

      ///------------ delete students ------------------/////
      .addCase(deleteStudent.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteStudent.fulfilled, (state, { payload }) => {
        state.loading = false;
        window.location.href = "/students";

        // const ctgIndex = findIndex(state.students, { id: payload?.data?.id });
        // state.students.splice(ctgIndex, 1);
        toast.success(payload?.message);
      })
      .addCase(deleteStudent.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        toast.error(payload?.message);
      })

      ///------------ UPDATE students ------------------/////
      .addCase(updateStudent.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(updateStudent.fulfilled, (state, { payload }) => {
        state.updateLoading = false;

        // const ctgIndex = findIndex(state.students, { id: payload?.data?.id });
        state.student = payload?.data;

        toast.success(payload?.message);
      })
      .addCase(updateStudent.rejected, (state, { payload }) => {
        state.updateLoading = false;
        state.error = payload;
        toast.error(payload?.message);
      });
  },
});

export const { resetError } = studentsSlice.actions;

export default studentsSlice.reducer;
