import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getuser, logout } from "../Login/authSlice";
import { CircularProgress } from "@nextui-org/react";
import HomePage from "../HomePage/HomePage";
import { RequireAuth } from "../../customHooks/protectedRoutes";
import { ROLE } from "../../lib/roles";
import Sidebar from "../../components/Sidebar/Sidebar";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import defaultLightImage from "../../assets/foresty_white.png";
import defaultDarkImage from "../../assets/foresty_green.png";
import LightDarkSwitch from "../../components/LightDarkSwitch";
import HeaderButton from "../../components/Dashboard/HeaderButton";
import {
  faBell,
  faCalendar,
  faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProfileButton from "../../components/Dashboard/ProfileButton";
import EmployeesPage from "../EmployeesPage/EmployeesPage";
import CreateEmployeePage from "../EmployeesPage/CreateEmployeePage";
import RoomsPage from "../RoomsPage/RoomsPage";
import SubjectsPage from "../SubjectPage/SubjectsPage";
import CoursesPage from "../CoursePage/CoursesPage";
import EmployeePage from "../EmployeesPage/EmployeePage/EmployeePage";
import EditEmployeePage from "../EmployeesPage/EditEmployeePage";
import TeachersPage from "../TeachersPage/TeachersPage";
import CreateTeacherPage from "../TeachersPage/CreateTeacherPage";
import TeacherPage from "../TeachersPage/TeacherPage/TeacherPage";
import UpdateTeacherPage from "../TeachersPage/UpdateTeacherPage";
import StudentsPage from "../StudentsPage/StudentsPage";
import CreateStudentPAge from "../StudentsPage/CreateStudentPage";
import GroupsPage from "../Groups/GroupsPage";
import CreateGroupPage from "../Groups/CreateGroupPage";
import GroupPage from "../Groups/GroupPage/GroupPage";
import StudentPage from "../StudentsPage/StudentPage/StudentPage";
import CalendarPage from "../CalendarPage/CalendarPage";
import FinancePage from "../FinancePage/FinancePage";
import IncomesPage from "../FinancePage/IncomesPage";
import ExpensesPage from "../FinancePage/ExpensesPage";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";
import ProfilePage from "../ProfilePage/ProfilePage";
import { getUserProfile } from "../ProfilePage/profileSlice";
import SettingsPage from "../SettingsPage/SettingsPage";
import { getLogoSrc } from "../../utils/profile";
import SMSPage from "../SMSPage/SMSPage";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = () => {
  const loc = useLocation();

  const { user, loading } = useSelector((state) => state.profile);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserProfile());
  }, []);

  return loading ? (
    <div className='flex w-screen h-screen items-center justify-center'>
      <CircularProgress />
    </div>
  ) : (
    user && (
      <>
        <div className='-z-10 fixed w-screen h-screen bg-fyTropic-100 dark:bg-[#0f0f0f]'></div>
        <div className='flex flex-row bg-fyTropic-100 dark:bg-[#0f0f0f] p-[21px] gap-8'>
          <Sidebar user={user} />

          {/* <motion.div
          layout
          className="w-[50px] h-[50px] bg-fyTropic"
        ></motion.div> */}
          <div className='flex flex-col p-3 gap-5 grow'>
            <div className='flex flex-row justify-between'>
              <div className='flex items-center justify-center gap-5'>
                <img
                  src={
                    getLogoSrc(user?.educationCenter?.logo)
                    // user?.centerLogoId
                    //   ? // ? `https://platform.foresty.uz/api/image/${user?.centerLogoId}`
                    //     // // : user?.centerLogoId ?? theme === "light"
                    //     defaultLightImage
                    //   : defaultDarkImage
                  }
                  className='w-[50px] h-[50px] object-cover bg-contain bg-no-repeat rounded-2xl'
                />
                <span className='text-[28px] font-raleway font-extrabold text-fyTropic dark:text-foresty-white'>
                  {user?.educationCenter?.name}
                </span>
              </div>
              <div className='flex flex-row justify-around items-center gap-[23px] pr-5'>
                <LightDarkSwitch />

                <HeaderButton
                  icon={faCalendar}
                  visited={loc.pathname == "/calendar"}
                  to={"/calendar"}
                />

                <ProfileButton user={user} />
                <button
                  onClick={() => dispatch(logout())}
                  className='group flex justify-center items-center bg-foresty-white/30 hover:bg-foresty-white w-[38px] h-[38px] rounded-full text-center -ml-3 transition duration-300 ease-in-out hover:shadow-lg hover:shadow-foresty-500/50 dark:hover:shadow-fyTropic/50 dark:bg-forestydark-500'
                >
                  <FontAwesomeIcon
                    icon={faRightFromBracket}
                    className='text-[14px] text-fyTropic/80 dark:text-[#787e89] dark:group-hover:text-fyTropic-200 shadow-lg transition duration-300 ease-in-out'
                  />
                </button>
              </div>
            </div>
            <Routes>
              <Route
                element={
                  <RequireAuth
                    allowedRoles={[
                      ROLE.ADMIN,
                      ROLE.MODERATOR,
                      ROLE.FINANCIER,
                      ROLE.OWNER,
                    ]}
                  />
                }
              >
                <Route path='/home' element={<HomePage />} />
              </Route>

              <Route
                element={
                  <RequireAuth
                    allowedRoles={[ROLE.ADMIN, ROLE.MODERATOR, ROLE.OWNER]}
                  />
                }
              >
                <Route path='/employees' element={<EmployeesPage />} />
                <Route path='/employees/:id' element={<EmployeePage />} />
                <Route
                  path='/employees/addEmployee'
                  element={<CreateEmployeePage />}
                />

                <Route
                  path='/employees/edit/:id'
                  element={<EditEmployeePage />}
                />

                <Route path='/teachers' element={<TeachersPage />} />
                <Route
                  path='/teachers/create'
                  element={<CreateTeacherPage />}
                />

                <Route path='/teachers/:id' element={<TeacherPage />} />

                <Route
                  path='/teachers/update/:id'
                  element={<UpdateTeacherPage />}
                />

                <Route path='/students' element={<StudentsPage />} />
                <Route
                  path='/students/create'
                  element={<CreateStudentPAge />}
                />
                <Route path='/students/:id' element={<StudentPage />} />

                <Route path='/groups' element={<GroupsPage />} />
                <Route path='/groups/create' element={<CreateGroupPage />} />
                <Route path='/groups/:id' element={<GroupPage />} />

                <Route path='/finance' element={<FinancePage />} />
                <Route path='/finance/incomes' element={<IncomesPage />} />
                <Route path='/finance/expenses' element={<ExpensesPage />} />
                {/*
                <Route path='/students/:id' element={<StudentsProfile />} />
                
                <Route path='/students/edit/:id' element={<StudentEdit />} />

                
                <Route path='/groups/edit/:id' element={<GroupEdit />} /> */}
              </Route>

              {/* <Route
              element={
                <RequireAuth
                  allowedRoles={[ROLE.ADMIN, ROLE.MODERATOR, ROLE.OWNER]}
                />
              }
            >
              <Route path='/employees' element={<Employees />} />
              <Route path='/employees/:id' element={<EmployeeProfile />} />
              <Route path='/employees/addEmployee' element={<AddEmployee />} />
              <Route path='/employees/edit/:id' element={<EmployeeEdit />} />

              <Route path='/teachers' element={<Teachers />} />
              <Route path='/teachers/:id' element={<TeacherProfile />} />
              <Route path='/teachers/addTeacher' element={<AddTeacher />} />
              <Route path='/teachers/edit/:id' element={<TeacherEdit />} />

              <Route path='/students' element={<Students />} />
              <Route path='/students/:id' element={<StudentsProfile />} />
              <Route path='/students/addStudent' element={<AddStudent />} />
              <Route path='/students/edit/:id' element={<StudentEdit />} />

              <Route path='/groups' element={<Groups />} />
              <Route path='/groups/:id' element={<Group />} />
              <Route path='/groups/addGroup' element={<AddGroup />} />
              <Route path='/groups/edit/:id' element={<GroupEdit />} />
            </Route>

       

              <Route
                element={
                  <RequireAuth
                    allowedRoles={[ROLE.ADMIN, ROLE.FINANCIER, ROLE.OWNER]}
                  />
                }
              >
                <Route path='/finance' element={<Finance />} />

                <Route path='/finance/groups' element={<FinanceGroups />} />
                <Route path='/finance/groups/:id' element={<FinanceGroup />} />

                <Route path='/finance/teachers' element={<FinanceTeachers />} />
                <Route
                  path='/finance/teachers/:id'
                  element={<FinanceTeacher />}
                />

                <Route
                  path='/finance/employees'
                  element={<FinanceEmployees />}
                />
                <Route
                  path='/finance/employees/:id'
                  element={<FinanceEmployee />}
                />

                <Route path='/finance/income' element={<FinanceIncome />} />
                <Route path='/finance/expense' element={<FinanceExpense />} />
                <Route path='/finance/profit' element={<FinanceProfit />} />
              </Route>

                   */}

              <Route
                element={
                  <RequireAuth
                    allowedRoles={[
                      ROLE.ADMIN,
                      ROLE.MODERATOR,
                      ROLE.OWNER,
                      ROLE.FINANCIER,
                    ]}
                  />
                }
              >
                <Route path='/rooms' element={<RoomsPage />} />
                <Route path='/courses' element={<CoursesPage />} />
                <Route path='/subjects' element={<SubjectsPage />} />
                <Route path='/calendar' element={<CalendarPage />} />
                <Route path='/profile' element={<ProfilePage />} />
                <Route path='/sms' element={<SMSPage />} />
                <Route path='/settings' element={<SettingsPage />} />
                <Route path='/' element={<Navigate to='/home' replace />} />
                {/* 
              <Route path='/profile' element={<Profile />} />
              <Route path='/profile/edit' element={<EditProfile />} />
           <Route path="/notifications" element={<Notifications />} /> 
               

              <Route path='/*' element={<Navigate to='/404' replace />} />
            </Route>
            <Route
              element={<RequireAuth allowedRoles={[ROLE.ADMIN, ROLE.OWNER]} />}
            >
              <Route path='/settings' element={<Settings />} /> */}
              </Route>
            </Routes>
          </div>
        </div>
      </>
    )
  );
};

export default Dashboard;
