import React, { useEffect } from "react";
import PageContainer from "../../components/Container/PageContainer";
import PageHeader from "../../components/Dashboard/PageHeader";
import PageBoard from "../../components/Container/PageBoard";
import Floor from "../../components/Room/Floor";
import Door from "../../components/Room/Door";
import { useDispatch, useSelector } from "react-redux";
import { createRoom, deleteRoom, getAllRooms, updateRoom } from "./roomsSlice";
import { uniqueId } from "lodash";
import CreateEditModal from "../../components/Modal/CreateEditModal";
import { emptyValues, fields, floorsData, validationSchema } from "./data";
import { DeleteButton, EditButton } from "../../components/Buttons/CRUDbuttons";
import DeleteModal from "../../components/Modal/DeleteModal";

const RoomsPage = () => {
  const dispatch = useDispatch();
  const { rooms, floors, loading } = useSelector((state) => state.rooms);

  useEffect(() => {
    dispatch(getAllRooms());
  }, []);

  return (
    <PageContainer>
      <PageHeader title='Xonalar' count={rooms?.length} />
      <PageBoard>
        <div className='flex flex-row justify-end px-5 pt-2'>
          <CreateEditModal
            title={"Xona qo'shish"}
            loading={loading}
            btnText={"Qo'shish"}
            fields={fields.map((el) => {
              if (el.name === "floor") {
                el.selectData = floorsData;
                return el;
              }
              return el;
            })}
            initialValues={emptyValues}
            validationSchema={validationSchema}
            submitBtnText={"Qo'shish"}
            handleSubmit={(body) => dispatch(createRoom(body))}
          />
        </div>
        {rooms && (
          <div className='flex flex-col gap-2 w-full h-fit p-3 border-[1px] border-forestydark-100/50 dark:bg-forestydark-800 rounded-xl'>
            {floors.map((i) => (
              <Floor floor={i} key={uniqueId()}>
                {rooms
                  .filter((el) => el.floor === i)
                  .map((door) => (
                    <Door
                      key={uniqueId()}
                      door={door}
                      deleteModal={
                        <DeleteModal
                          button={<DeleteButton />}
                          contextText={`${door.roomNumber}-xonani`}
                          handleSubmit={() =>
                            dispatch(deleteRoom({ id: door.id }))
                          }
                        />
                      }
                      editModal={
                        <CreateEditModal
                          button={<EditButton />}
                          title={"Xonani tahrirlash"}
                          loading={loading}
                          btnText={"Tahrirlash"}
                          fields={fields.map((el) => {
                            if (el.name === "floor") {
                              el.selectData = floorsData;
                              return el;
                            }
                            return el;
                          })}
                          initialValues={{
                            ...door,
                            floor: "" + door.floor,
                          }}
                          validationSchema={validationSchema}
                          submitBtnText={"Tahrirlash"}
                          handleSubmit={(body) =>
                            dispatch(updateRoom({ ...body, id: door.id }))
                          }
                        />
                      }
                    />
                  ))}
              </Floor>
            ))}

            {/* <Floor floor={3}>
              {doorsThirdFloor.map((door) => (
                <Door door={door} />
              ))}
            </Floor>
            <Floor floor={2}>
              {doorsSecondFloor.map((door) => (
                <Door door={door} />
              ))}
            </Floor>
            <Floor floor={1}>
              {doorsFirstFloor.map((door) => (
                <Door door={door} />
              ))}
            </Floor> */}
          </div>
        )}
      </PageBoard>
    </PageContainer>
  );
};

export default RoomsPage;
