import { faApple } from "@fortawesome/free-brands-svg-icons";
import {
  faAppleWhole,
  faAtom,
  faBook,
  faBrain,
  faCompassDrafting,
  faDna,
  faFlask,
  faLandmark,
  faLaptopCode,
  faLeaf,
  faMicroscope,
  faPencil,
  faSeedling,
  faShapes,
  faSquareRootVariable,
  faStore,
} from "@fortawesome/free-solid-svg-icons";

export const icons = [
  { id: "MATH", name: "" },
  { id: "ATOM", name: "" },
  { id: "LEAF", name: "" },
  { id: "SEEDLING", name: "" },
  { id: "SHAPES", name: "" },
  { id: "DNA", name: "" },
  { id: "BRAIN", name: "" },
  { id: "LANDMARK", name: "" },
  { id: "STORE", name: "" },
  { id: "PENCIL", name: "" },
  { id: "COMPASS_DRAFTING", name: "" },
  { id: "BOOK", name: "" },
  { id: "MICROSCOPE", name: "" },
  { id: "FLASK", name: "" },
  { id: "LAPTOP_CODE", name: "" },
  { id: "APPLE", name: "" },
];

export const getIcon = (i) => {
  return i === "" ? faLeaf : Icon[i];
};

export const Icon = {
  MATH: faSquareRootVariable,
  ATOM: faAtom,
  LEAF: faLeaf,
  SEEDLING: faSeedling,
  SHAPES: faShapes,
  DNA: faDna,
  BRAIN: faBrain,
  LANDMARK: faLandmark,
  STORE: faStore,

  PENCIL: faPencil,
  COMPASS_DRAFTING: faCompassDrafting,

  BOOK: faBook,
  MICROSCOPE: faMicroscope,
  FLASK: faFlask,

  LAPTOP_CODE: faLaptopCode,
  APPLE: faAppleWhole,
};
