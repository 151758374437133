import React from "react";
import ForestyForm from "../../components/Form/ForestyForm";
import { useDispatch, useSelector } from "react-redux";
import {
  createFields,
  emptyValues,
  fields,
  roles,
  validationSchema,
} from "./data";
import { createEmployee } from "./employeeSlice";
import { generateRandomPassword } from "../../utils/auth";
import VerticalBoard from "../../components/Container/VerticalBoard";
import { useNavigate } from "react-router-dom";

const CreateEmployeePage = () => {
  const { loading } = useSelector((state) => state.employees);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <VerticalBoard>
      <span className='text-[22px] mt-3 dark:text-foresty-white text-center font-bold'>
        {"Xodim qo'shish"}
      </span>
      <ForestyForm
        isLoading={loading}
        colsNum={2}
        initialValues={{
          ...emptyValues,
          password: generateRandomPassword(),
        }}
        fields={createFields.map((el) => {
          if (el.name === "role") {
            el.selectData = roles;
            return el;
          }
          return el;
        })}
        validationSchema={validationSchema}
        handleSubmit={(body) =>
          dispatch(
            createEmployee({
              data: body,
              navigate: () => navigate("/employees"),
            })
          )
        }
        submitBtnText="Qo'shish"
      />
    </VerticalBoard>
  );
};

export default CreateEmployeePage;
