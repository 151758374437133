import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

export default function GroupTimeButton({ context, change }) {
  const [showButtons, setShowButtons] = useState(false);
  return (
    <div
      className='relative group'
      onMouseEnter={() => setShowButtons(true)}
      onMouseLeave={() => setShowButtons(false)}
    >
      {showButtons && (
        <>
          <button
            onClick={() => change(1)}
            className='flex justify-center items-center left-[50%] translate-x-[-50%] absolute top-[-10px] w-[12px] h-[12px]'
          >
            <FontAwesomeIcon icon={faCaretUp} className='text-foresty-500' />
          </button>
          <button
            onClick={() => change(-1)}
            className='flex justify-center items-center left-[50%] translate-x-[-50%] absolute bottom-[-10px] w-[12px] h-[12px]'
          >
            <FontAwesomeIcon icon={faCaretDown} className='text-foresty-500' />
          </button>
        </>
      )}
      <button
        // onClick={() => setShowButtons(!showButtons)}

        className='w-[30px] h-[30px] text-[14px] flex justify-center items-center rounded-md border-[1.5px] dark:text-foresty-white border-fyTropic/20 group-hover:border-foresty-500 group-hover:font-bold dark:border-fyTropic-200/10'
      >
        {context}
      </button>
    </div>
  );
}
