export const columns = [
  { name: "ID", uid: "rowOrder", sortable: true },
  //   { name: "NOMI", uid: "name", sortable: true },
  { name: "Context", uid: "context", sortable: true },
  { name: "Context2", uid: "secondaryContext", sortable: true },
  { name: "Harakat", uid: "activityName", sortable: true },
  { name: "SANASI", uid: "created_at", sortable: true },
  // { name: "STATUS", uid: "status", sortable: true },
];

export const INITIAL_VISIBLE_COLUMNS = [
  "rowOrder",
  "context",
  "secondaryContext",
  "activityName",
  //   "name",
  "created_at",
];

export const searchIndexes = ["name"];
