export const getDateRangeInitialDatas = () => {
  let fromDate = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    1,
    0,
    0,
    0,
    0
  );

  let toDate = new Date(
    fromDate.getFullYear(),
    fromDate.getMonth() + 1,
    fromDate.getDate(),
    0,
    0,
    0,
    0
  );

  return {
    startDate: fromDate,
    endDate: toDate,
    key: "selection",
  };
};

export const getOptions = (theme) => ({
  tension: 0.2,
  maintainAspectRatio: false,
  responsive: true,
  scales: {
    y: {
      grid: {
        color: theme == "dark" ? "#383838" : "#3838381A",
      },
      ticks: {
        color: theme == "dark" ? "#d1d1d1" : "#383838",
      },
    },
    x: {
      grid: {
        color: theme == "dark" ? "#383838" : "#3838381A",
      },
      ticks: {
        color: theme == "dark" ? "#d1d1d1" : "#383838",
      },
    },
  },

  plugins: {
    legend: {
      position: "top",
      labels: {
        font: {
          size: 12,
          weight: 500,
        },
        color: theme == "dark" ? "#d1d1d1" : "#383838",
        boxWidth: 8,
        boxHeight: 8,
        usePointStyle: true,
        pointStyle: "circle",
      },
    },
  },
});

export const labels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
];
