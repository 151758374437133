import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash";
import Api from "../../config/Api";
import { toast } from "react-toastify";

export const getLatestActivities = createAsyncThunk(
  "activities/getLatest",
  async (body = {}) => {
    const response = await Api.get("/activity/latest");
    return response.data;
  }
);

export const getActivitiesByEmployeeId = createAsyncThunk(
  "activities/getByEmployeeId",
  async (body = {}) => {
    const response = await Api.get(`/activity/${body}`);
    return response.data;
  }
);

const activitiesSlice = createSlice({
  name: "activities",
  initialState: {
    activities: null,
    loading: false,
    error: null,
  },
  reducers: {
    resetError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      ///------------ GET activities ------------------/////
      .addCase(getLatestActivities.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLatestActivities.fulfilled, (state, action) => {
        state.loading = false;
        state.activities = action.payload?.data;
      })
      .addCase(getLatestActivities.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      ///------------ GET activities ------------------/////
      .addCase(getActivitiesByEmployeeId.pending, (state) => {
        state.loading = true;
      })
      .addCase(getActivitiesByEmployeeId.fulfilled, (state, action) => {
        state.loading = false;
        state.activities = action.payload?.data;
      })
      .addCase(getActivitiesByEmployeeId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetError } = activitiesSlice.actions;

export default activitiesSlice.reducer;
