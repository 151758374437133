import date from "date-and-time";
import { getPhoneNumberPattern } from "../../../../utils/regex";

export const getStatusStyle = (status) => {
  switch (status) {
    case "NEW":
      return "flex flex-col w-full px-5 py-8 items-center gap-5 bg-gradient-to-t from-[#C0F741]/10 dark:from-[#C0F741]/10";
    case "ACTIVE":
      return "flex flex-col w-full px-5 py-8 items-center gap-5 bg-gradient-to-t from-foresty-400/10 dark:from-[#3d653f]/20";

    case "FROZEN":
      return "flex flex-col w-full px-5 py-8 items-center gap-5 bg-gradient-to-t from-[#0085ff]/10 dark:from-[#0085ff]/20";

    case "BLOCKED":
      return "flex flex-col w-full px-5 py-8 items-center gap-5 bg-gradient-to-t from-[#fa3435]/10 dark:from-[#fa3435]/20";
  }
};

export const getUpdateInitialValues = (student = {}) => {
  return {
    firstName: student?.student?.user?.firstName,
    lastName: student?.student?.user?.lastName,
    fatherName: student?.student?.user?.fatherName,
    parentPhoneNumber: getPhoneNumberPattern(student?.parentPhoneNumber),
    secondaryParentPhoneNumber: getPhoneNumberPattern(
      student?.secondaryParentPhoneNumber
    ),

    email: student?.student?.user?.email,
    phoneNumber: getPhoneNumberPattern(student?.student?.user?.phoneNumber),
    birthDate: date.format(
      new Date(student?.student?.user?.birthDate),
      "YYYY-MM-DD"
    ),
  };
};
