import {
  CubeIcon,
  UserGroupIcon,
  CalendarDaysIcon,
  BanknotesIcon,
  WrenchScrewdriverIcon,
  DocumentDuplicateIcon,
  RectangleStackIcon,
  Squares2X2Icon,
} from "@heroicons/react/24/solid";

export const tabs = [
  {
    title: (
      <div className='flex items-center space-x-2'>
        <CubeIcon className='w-[20px]' />
        <span>{"O'qituvchi ma'lumotlari"}</span>
      </div>
    ),
    key: "details",
  },
  {
    title: (
      <div className='flex items-center space-x-2'>
        <Squares2X2Icon className='w-[20px]' />
        <span>{"Guruhlar"}</span>
      </div>
    ),
    key: "groups",
  },

  {
    title: (
      <div className='flex items-center space-x-2'>
        <BanknotesIcon className='w-[20px]' />
        <span>{"To'lovlar"}</span>
      </div>
    ),
    key: "payments",
  },
  {
    title: (
      <div className='flex items-center space-x-2'>
        <DocumentDuplicateIcon className='w-[20px]' />
        <span>{"Hujjatlar"}</span>
      </div>
    ),
    key: "documents",
  },
  {
    title: (
      <div className='flex items-center space-x-2'>
        <WrenchScrewdriverIcon className='w-[20px]' />
        <span>{"Sozlamalar"}</span>
      </div>
    ),
    key: "settings",
  },
];

export const weekDays = ["Yak", "Du", "Se", "Chor", "Pay", "Ju", "Sha"];
