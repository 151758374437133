import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getTeacher, updateTeacher } from "./teachersSlice";
import { Spinner } from "@nextui-org/react";
import VerticalBoard from "../../components/Container/VerticalBoard";
import ForestyForm from "../../components/Form/ForestyForm";
import {
  createFields,
  getUpdateValidationSchema,
  validationSchema,
} from "./data";
import { getUpdateInitialValues } from "./utils";
import { getAllSubjects } from "../SubjectPage/subjectsSlice";

const UpdateTeacherPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { teacher, loading, updateLoading } = useSelector(
    (state) => state.teachers
  );
  const { subjects } = useSelector((state) => state.subjects);

  useEffect(() => {
    dispatch(getTeacher(id));
    dispatch(getAllSubjects());
  }, []);
  return loading ? (
    <Spinner />
  ) : (
    <VerticalBoard>
      <span className='text-[22px] mt-3 dark:text-foresty-white text-center font-bold'>
        {"O'qituvchini tahrirlash"}
      </span>
      {teacher && (
        <ForestyForm
          isLoading={updateLoading}
          colsNum={2}
          initialValues={getUpdateInitialValues(teacher)}
          fields={createFields.map((el) => {
            if (el.name === "subjectsId") {
              el.selectData = subjects;
              return el;
            }
            return el;
          })}
          validationSchema={getUpdateValidationSchema()}
          handleSubmit={(body) =>
            dispatch(
              updateTeacher({
                ...body,
                id: teacher?.id,
                navigate: () => {
                  navigate("/teachers");
                },
              })
            )
          }
          submitBtnText='Tahrirlash'
        />
      )}
    </VerticalBoard>
  );
};

export default UpdateTeacherPage;
