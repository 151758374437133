import React from "react";
import { AnimatePresence, motion } from "framer-motion";

export default function DayButton({
  day,
  date,
  isPressed,
  index,
  selectButton,
  isToday,
}) {
  return (
    <button
      onClick={() => !isPressed && selectButton(index)}
      className="relative w-[60px] h-[50px] text-fyTropic dark:text-foresty-white leading-4 py-[8px] rounded-lg"
    >
      <div className="absolute top-0 w-full h-full flex flex-col justify-center items-center z-10">
        <span>{day}</span>
        <span className="text-[18px] font-semibold">{date}</span>
      </div>

      {isPressed && (
        <AnimatePresence initial={false}>
          <motion.div
            layoutId="inline"
            className="absolute top-0 dark:bg-[#2f2f2f] bg-foresty-white w-full h-full rounded-lg z-[5]"
          ></motion.div>
        </AnimatePresence>
      )}
      {isToday && (
        <div className="absolute top-0 dark:bg-forestydark-800 bg-forestydark-100/10 w-full h-full rounded-lg z-0"></div>
      )}
    </button>
  );
}
