import date from "date-and-time";
import { getMoneyPatternInput, getPhoneNumberPattern } from "../../utils/regex";

export const getStatusStyle = (userStatus) => {
  switch (userStatus) {
    case "ACTIVE":
      return "flex flex-col gap-5 px-12 py-8 bg-gradient-to-t from-foresty-400/10 dark:from-[#3d653f]/20";

    case "NEW":
      return "flex flex-col gap-5 px-12 py-8 bg-gradient-to-t from-[#C0F741]/10 dark:from-[#C0F741]/10";

    case "BLOCKED":
      return "flex flex-col gap-5 px-12 py-8 bg-gradient-to-t from-[#fa3435]/10 dark:from-[#fa3435]/20";
    case "FINISH":
      return "flex flex-col gap-5 px-12 py-8 bg-gradient-to-t from-[#0086ff]/10 dark:from-[#0086ff]/20";
    case "COMPLETED":
      return "flex flex-col gap-5 px-12 py-8 bg-gradient-to-t from-[#9e73c7]/10 dark:from-[#9e73c7]/20";
  }
};

export const getUpdateInitialValues = (employee = {}) => {
  return {
    firstName: employee?.employee?.user?.firstName,
    lastName: employee?.employee?.user?.lastName,
    fatherName: employee?.employee?.user?.fatherName,

    email: employee?.employee?.user?.email,
    username: employee?.employee?.user?.username,
    phoneNumber: getPhoneNumberPattern(employee?.employee?.user?.phoneNumber),
    birthDate: date.format(
      new Date(employee?.employee?.user?.birthDate),
      "YYYY-MM-DD"
    ),

    role: employee?.employee?.user?.role?.roleName,
    salary: getMoneyPatternInput(employee?.currentSalary),
    jobStartsDate: date.format(new Date(employee?.jobStartsDate), "YYYY-MM-DD"),
    testDaysCount: employee?.testDaysCount,
  };
};
