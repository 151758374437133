import React from "react";

export const UserCircleIcon = ({ height = 40, width = 40 }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 4 4'
      height={height}
      width={width}
    >
      <g id='user-circle-single--circle-geometric-human-person-single-user'>
        <path
          id='Vector'
          fill='#b8fa8f'
          d='M2 4c1.28 0 2 -0.72 2 -2s-0.72 -2 -2 -2 -2 0.72 -2 2 0.72 2 2 2Z'
          strokeWidth={0.2857142857142857}
        />
        <path
          id='Union'
          fill='#28c53e'
          fillRule='evenodd'
          d='M2.673142857142857 1.5054285714285713c0 0.4308571428571428 -0.24228571428571427 0.673142857142857 -0.673142857142857 0.673142857142857s-0.673142857142857 -0.24228571428571427 -0.673142857142857 -0.673142857142857S1.569142857142857 0.8322857142857142 2 0.8322857142857142s0.673142857142857 0.24228571428571427 0.673142857142857 0.673142857142857ZM2 4c0.5308571428571428 0 0.9657142857142856 -0.12371428571428571 1.292285714285714 -0.36a1.3577142857142857 1.3577142857142857 0 0 0 -2.584571428571428 0C1.0342857142857143 3.8757142857142854 1.469142857142857 4 2 4Z'
          clipRule='evenodd'
          strokeWidth={0.2857142857142857}
        />
      </g>
    </svg>
  );
};

export const AddressIcon = ({ height = 40, width = 40 }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 4 4'
      height={height}
      width={width}
    >
      <g id='location-pin-3--navigation-map-maps-pin-gps-location'>
        <path
          id='Union'
          fill='#8c28c5'
          d='M0.8071428571428572 2.642857142857143a0.42857142857142855 0.42857142857142855 0 0 0 -0.3814285714285714 0.23314285714285712L0.09999999999999999 3.5125714285714285a0.2857142857142857 0.2857142857142857 0 0 0 0.2542857142857143 0.416h3.291428571428571a0.2857142857142857 0.2857142857142857 0 0 0 0.2534285714285714 -0.4177142857142857l-0.3322857142857143 -0.6377142857142858a0.42857142857142855 0.42857142857142855 0 0 0 -0.38 -0.2302857142857143H0.8071428571428572Z'
          strokeWidth={0.2857142857142857}
        />
        <path
          id='Union_2'
          fill='#cf8ffa'
          fillRule='evenodd'
          d='M1.9842857142857142 0a1.368857142857143 1.368857142857143 0 0 0 -1.3657142857142857 1.2714285714285714c-0.024285714285714285 0.34085714285714286 0.05228571428571428 0.6948571428571428 0.26685714285714285 0.9768571428571429 0.2554285714285714 0.3354285714285714 0.5157142857142857 0.5657142857142857 0.9222857142857143 0.8365714285714285 0.11628571428571427 0.07714285714285714 0.26799999999999996 0.07714285714285714 0.38457142857142856 0 0.4065714285714286 -0.27085714285714285 0.6668571428571428 -0.5011428571428571 0.9219999999999999 -0.8365714285714285 0.21485714285714286 -0.282 0.2914285714285714 -0.636 0.26685714285714285 -0.9771428571428571A1.368857142857143 1.368857142857143 0 0 0 2.0157142857142856 0h-0.031428571428571424Z'
          clipRule='evenodd'
          strokeWidth={0.2857142857142857}
        />
        <path
          id='Vector'
          fill='#8c28c5'
          d='M2 1.741142857142857c0.27428571428571424 0 0.42857142857142855 -0.15428571428571428 0.42857142857142855 -0.42857142857142855s-0.15428571428571428 -0.42857142857142855 -0.42857142857142855 -0.42857142857142855 -0.42857142857142855 0.15428571428571428 -0.42857142857142855 0.42857142857142855 0.15428571428571428 0.42857142857142855 0.42857142857142855 0.42857142857142855Z'
          strokeWidth={0.2857142857142857}
        />
      </g>
    </svg>
  );
};
