import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import React from "react";
import HomePageWarning from "./homePageWarning";

export default function WarningBoard({ data }) {
  return (
    <div className='relative flex flex-col gap-3 w-full bg-foresty-white/50 dark:bg-forestydark-600 p-3 rounded-2xl border-[0.5px] border-[#f01b14]/50 dark:border-[#ff526c] dark:drop-shadow-[0px_0px_8px_rgba(255,82,108,0.5)]'>
      <span className='absolute right-[-13px] top-[-13px] h-[28px] w-[28px] bg-foresty-white dark:bg-[#292e31] rounded-full'>
        <ExclamationCircleIcon className='stroke-[#f01b14]' />
      </span>
      {data?.student > 0 && (
        <HomePageWarning
          text="Qarzdor o'quvchilar soni"
          count={data?.student}
        />
      )}
      {data?.teacher > 0 && (
        <HomePageWarning
          text="Guruh to'lovi to'lanmagan o'qituvchilar soni"
          count={data?.teacher}
        />
      )}
      {data?.employee > 0 && (
        <HomePageWarning
          text="Maoshi to'lanmagan xodimlar soni"
          count={data?.employee}
        />
      )}
    </div>
  );
}
