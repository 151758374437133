import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@nextui-org/react";

import { useNavigate } from "react-router-dom";
import CreateEditModal from "../../../components/Modal/CreateEditModal";
import { createFields, getUpdateValidationSchema } from "../data";
import { deleteTeacher, updateTeacher } from "../teachersSlice";
import DeleteModal from "../../../components/Modal/DeleteModal";
import { DeleteButtonText } from "../../../components/Buttons/CRUDbuttons";
import { getUpdateInitialValues } from "../utils";
import { getAllSubjects } from "../../SubjectPage/subjectsSlice";

const TeacherSettingsPage = ({ teacher }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { subjects } = useSelector((state) => state.subjects);

  useEffect(() => {
    dispatch(getAllSubjects());
  }, []);
  return (
    teacher && (
      <div className='flex flex-col items-center'>
        <div className='flex flex-col gap-8 bg-neutral-50 dark:bg-neutral-800/50 p-5 rounded-2xl w-[800px]'>
          <div className='grid grid-cols-1 w-full gap-5'>
            <CreateEditModal
              size='3xl'
              colsNum={2}
              title={"O'qituvchi ma'lumotlarini tahrirlash"}
              // loading={loading}
              submitBtnText={"Tahrirlash"}
              button={<Button>{"O'qituvchi ma'lumotlarini tahrirlash"}</Button>}
              fields={createFields.map((el) => {
                if (el.name === "subjectsId") {
                  el.selectData = subjects;
                  return el;
                }
                return el;
              })}
              initialValues={getUpdateInitialValues(teacher)}
              validationSchema={getUpdateValidationSchema()}
              handleSubmit={(body) =>
                dispatch(updateTeacher({ ...body, id: teacher?.id }))
              }
            />
          </div>

          <div className='flex flex-row gap-5 mt-5 rounded-3xl border-[1px] border-red-500 p-4'>
            <DeleteModal
              button={
                <DeleteButtonText>{"O'qituvchini o'chirish"}</DeleteButtonText>
              }
              contextText={`${teacher?.teacher?.user.lastName} ${teacher?.teacher?.user.firstName} ${teacher?.teacher?.user.fatherName} o'qituvchini`}
              handleSubmit={() =>
                dispatch(
                  deleteTeacher({
                    id: teacher?.id,
                    navigate: () => navigate("/teachers"),
                  })
                )
              }
            />
          </div>
        </div>
      </div>
    )
  );
};

export default TeacherSettingsPage;
