import React, { useEffect, useState } from "react";
import {
  Input,
  CheckboxGroup,
  Checkbox,
  Link,
  User,
  Chip,
  cn,
  Tooltip,
  ModalBody,
  ModalFooter,
  Button,
} from "@nextui-org/react";
import { useDispatch, useSelector } from "react-redux";
import { getLessonTimes, getLessonTimesForUpdate } from "../utils";
import { getGroupLessonTimes, updateGroupLessonTimes } from "../groupsSlice";
import useLessonTime from "../../../customHooks/useLessonTime";
import GroupTimeRow from "../../../components/Group/GroupTimeRow";
import { PlusIcon } from "@heroicons/react/24/outline";

const LessonTimesModalContent = ({ onClose, lessonTimes, groupId }) => {
  const dispatch = useDispatch();

  const [times, addTime, deleteTimeRow, updateTime, clearTimes] = useLessonTime(
    [...getLessonTimesForUpdate(lessonTimes)]
  );

  //   useEffect(() => {

  //   },[lessonTimes])

  return (
    <>
      <ModalBody>
        <div className='flex flex-col w-full gap-5'>
          <div className='flex flex-col gap-3 mb-3 bg-white dark:bg-neutral-800 shadow-lg h-fit pr-12 p-8 rounded-2xl border-[1px] border-neutral-200 dark:border-neutral-600'>
            <span className='flex flex-row gap-1 text-[14px] dark:text-foresty-white font-bold ml-2'>
              {"Guruh dars vaqtlari"}
              <Tooltip
                content={<span className='text-[12px]'>Required</span>}
                color='secondary'
              >
                <span className='text-[14px] font-bold text-forestyFrozen'>
                  *
                </span>
              </Tooltip>
            </span>
            <div className='flex flex-col px-1 items-end gap-5'>
              {times.map((item, index) => (
                <GroupTimeRow
                  key={index}
                  time={item}
                  changeTimeRow={updateTime}
                  dayOrder={index + 1}
                  deleteBtnVisible={index == times.length - 1 && index !== 0}
                  deleteRow={() => {
                    deleteTimeRow(index);
                  }}
                />
              ))}

              {times.length < 7 && (
                <button
                  onClick={addTime}
                  className='flex w-fit justify-center items-center font-bold bg-fyTropic-400/10 text-foresty-500 p-2 rounded-md'
                >
                  <PlusIcon className='w-[20px] stroke-[2px]' />
                </button>
              )}
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color='danger' variant='flat' onPress={onClose}>
          {"Bekor qilish"}
        </Button>
        <Button
          onPress={() => {
            dispatch(
              updateGroupLessonTimes({
                id: groupId,
                data: getLessonTimes(times),
                onClose,
              })
            );
          }}
          //   isLoading={addStudentLoading}
          disabled={
            getLessonTimesForUpdate(lessonTimes).length === times.length
              ? times.every((el) => {
                  let lt = getLessonTimesForUpdate(lessonTimes).find(
                    (l) => l.day === el.day
                  );
                  if (lt == undefined) {
                    return false;
                  }
                  return (
                    el.fromHour === lt.fromHour &&
                    el.fromMinute === lt.fromMinute &&
                    el.toHour === lt.toHour &&
                    el.toMinute === lt.toMinute
                  );
                })
              : false
          }
          color={
            getLessonTimesForUpdate(lessonTimes).length === times.length
              ? times.every((el) => {
                  let lt = getLessonTimesForUpdate(lessonTimes).find(
                    (l) => l.day === el.day
                  );
                  if (lt == undefined) {
                    return false;
                  }
                  return (
                    el.fromHour === lt.fromHour &&
                    el.fromMinute === lt.fromMinute &&
                    el.toHour === lt.toHour &&
                    el.toMinute === lt.toMinute
                  );
                })
                ? "default"
                : "primary"
              : "primary"
          }
        >
          {"O'zgartirish"}
        </Button>
      </ModalFooter>
    </>
  );
};

export default LessonTimesModalContent;
