import React, { useEffect } from "react";
import UserStatus from "../../../components/Status/UserStatus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-regular-svg-icons";
import { joinedDate } from "../../../utils/date";
import { getPhoneNumberString } from "../../../utils/data";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import {
  Avatar,
  Chip,
  Spinner,
  Divider,
  Code,
  Snippet,
} from "@nextui-org/react";
import { getMoneyPattern, getPhoneNumberPattern } from "../../../utils/regex";
import { getStatusStyle } from "./utils/utils";
import date from "date-and-time";
import { useDispatch, useSelector } from "react-redux";
import { getStudentData } from "../studentsSlice";
import { getProfileAvatar } from "../../../utils/profile";

const StudentDetailsPage = ({ student }) => {
  const { studentData } = useSelector((state) => state.students);
  const dispatch = useDispatch();
  useEffect(() => {
    student && dispatch(getStudentData(student?.id));
  }, []);

  return (
    <div className='flex flex-row w-full gap-8'>
      <div className='flex flex-col flex-none w-[300px] h-fit items-center bg-foresty-white dark:bg-forestydark-500 rounded-[20px]'>
        <div className={getStatusStyle(student?.status)}>
          <Avatar
            className='w-[80px] h-[80px]'
            size='lg'
            src={getProfileAvatar(student?.student?.profilePhoto)}
            // src={student?.student??.avatar == null ? defaultImage : defaultImage}
            isBordered
            color='success'
          />
          <div className='flex flex-col gap-3 items-center'>
            <span className='flex gap-2 text-[14px] font-bold text-fyTropic dark:text-foresty-white'>
              {student?.student?.user?.lastName +
                " " +
                student?.student?.user?.firstName +
                " " +
                student?.student?.user?.fatherName}{" "}
              <UserStatus status={student?.status} isText={false} />
            </span>
            <span className='flex gap-3 items-center text-[12px] font-bold text-forestydark-100'>
              {student?.student?.role?.roleName}
              {/* <NetworkStatus isOnline={true} /> */}
            </span>
          </div>
          <div className='flex flex-row gap-5 px-2'>
            <span className='flex flex-row items-center text-[10px] gap-2 font-bold dark:text-foresty-white text-forestydark-300'>
              <FontAwesomeIcon
                icon={faCalendarDays}
                className='mr-1 text-[12px] text-forestydark-100'
              />
              {`Qo'shilgan ${joinedDate(student?.created_at)}`}
            </span>
          </div>
        </div>
        <span className='h-[1px] w-full dark:bg-forestydark-300 bg-forestydark-100/30' />
        <div className='flex flex-col px-5 pb-8 items-center gap-5 mt-5'>
          {/* <div className='flex flex-col w-full gap-2'>
          <span className='text-left ml-8 text-[14px] font-bold dark:text-foresty-white'>
            Fanlar:
          </span>
          <div className='flex flex-row flex-wrap gap-2 justify-center'>
            {student?.subjects?.map((item) => (
              <Chip
                style={{ backgroundColor: item.color.hexCode }}
                className='text-[12px] text-white'
                size='sm'
                key={item.id}
                //   color={item.color.hexCode}
                //   lightColor={item.color.lightHexCode}
              >
                {item.name}
              </Chip>
            ))}
          </div>
        </div> */}
          {/* <div className="flex flex-col gap-1 items-center">
              <div className="flex flex-row text-[12px] gap-3">
                <span className=" text-forestydark-500 dark:text-forestydark-100">
                  Oylik maosh
                </span>
              </div>
              <span className="font-bold text-[14px] dark:text-foresty-white">
                1 000 000 so'm
              </span>
            </div> */}

          <div className='flex flex-col gap-2'>
            <div className='w-full flex flex-col justify-center items-center'>
              <span className='text-[12px]'>{"Username"}</span>
              <Code color='default' className='mt-1'>
                {" "}
                {student?.student?.user?.username}
              </Code>
            </div>

            <Divider className='my-1' />
            <div className='w-full flex flex-col justify-center items-center'>
              <span className='text-[12px]'>{"Email"}</span>
              <Code
                size='sm'
                hideSymbol
                color='default'
                className='mt-1 text-[12px]'
              >
                {student?.student?.user?.email}
              </Code>
            </div>
            <Divider className='my-1' />

            <div className='w-full flex flex-col justify-center items-center'>
              <span className='text-[12px]'>{"Tug'ilgan sanasi"}</span>
              <span className='text-[14px] font-space font-semibold text-amber-500'>
                {joinedDate(student?.student?.user?.birthDate)}
              </span>
            </div>
            <Divider className='my-1' />

            <div className='w-full flex flex-col justify-center items-center'>
              <span className='text-[12px]'>{"Tel. raqami"}</span>
              <Code color='success' className='mt-1'>
                {getPhoneNumberPattern(student?.student?.user?.phoneNumber)}
              </Code>
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-col grow gap-2 bg-white/50 dark:bg-neutral-800/50 p-2 rounded-[30px] h-fit'>
        {studentData && (
          <div className='flex flex-row w-[full] gap-5'>
            <div className='flex flex-col gap-1 items-center justify-center w-[50%] bg-white dark:bg-neutral-700/40 rounded-3xl p-5 h-[180px]'>
              <span className='text-[14px] text-center'>{"Guruhlar soni"}</span>
              <span className='text-[32px] text-center text-orange-500 dark:text-orange-400 font-semibold'>
                {studentData?.groups_count}
              </span>
            </div>
            <div className='flex flex-col items-center justify-center w-[50%] bg-white dark:bg-neutral-700/40 rounded-3xl p-5'>
              <span className='text-[14px] text-center'>
                {"Qoldirilgan darslar soni"}
              </span>
              <span className='text-[32px] text-center text-blue-500 dark:text-blue-400 font-semibold'>
                {studentData?.totalAbsents}
              </span>
            </div>
            <div className='flex flex-col items-center justify-center w-[50%] bg-white dark:bg-neutral-700/40 rounded-3xl p-5'>
              <span className='text-[14px] text-center'>
                {"O'rtacha oylik to'lovi"}
              </span>
              <span className='text-[18px] font-space text-center text-green-500 dark:text-green-400 font-semibold'>
                {`${getMoneyPattern(studentData?.totalPayments)} so'm`}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default StudentDetailsPage;
