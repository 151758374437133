import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../pages/Login/authSlice";
import employeeReducer from "../pages/EmployeesPage/employeeSlice";
import documentReducer from "../pages/Documents/documentSlice";
import roomReducer from "../pages/RoomsPage/roomsSlice";
import subjectReducer from "../pages/SubjectPage/subjectsSlice";
import courseReducer from "../pages/CoursePage/coursesSlice";
import teacherReducer from "../pages/TeachersPage/teachersSlice";
import studentReducer from "../pages/StudentsPage/studentsSlice";
import groupReducer from "../pages/Groups/groupsSlice";
import calendarReducer from "../pages/CalendarPage/calendarSlice";
import financeReducer from "../pages/FinancePage/financeSlice";
import profileReducer from "../pages/ProfilePage/profileSlice";
import smsReducer from "../pages/SMSPage/smsSlice";
import activityReducer from "../pages/EmployeesPage/activitiesSlice";
import homeReducer from "../pages/HomePage/homeSlice";
// import plansReducer from "../pages/PlanPage/plansSlice";
// import educationCenterReducer from "../pages/EducationCenter/educationCentersSlice";
// import categoryReducer from "../pages/Categories/categoriesSlice";
// import incomeReducer from "../pages/IncomesPage/incomesSlice";
// import outcomeReducer from "../pages/OutcomesPage/outcomesSlice";
// import employeeReducer from "../pages/EmployeesPage/employeesSlice";
// import inventoryReducer from "../pages/InventoryPage/inventorySlice";

export default configureStore({
  reducer: {
    auth: authReducer,
    employees: employeeReducer,
    rooms: roomReducer,
    subjects: subjectReducer,
    courses: courseReducer,
    teachers: teacherReducer,
    students: studentReducer,
    groups: groupReducer,
    documents: documentReducer,
    calendar: calendarReducer,
    finance: financeReducer,
    profile: profileReducer,
    sms: smsReducer,
    activities: activityReducer,
    home: homeReducer,
    // plans: plansReducer,
    // educationCenters: educationCenterReducer,
    // categories: categoryReducer,
    // incomes: incomeReducer,
    // outcomes: outcomeReducer,
    // employees: employeeReducer,
    // inventory: inventoryReducer,
  },
});
