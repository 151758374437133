import {
  faHouse,
  faUserGroup,
  faUsers,
  faUsersRectangle,
  faDoorClosed,
  faShapes,
  faDatabase,
  faListCheck,
  faGear,
  faMoneyBills,
  faGraduationCap,
  faCubesStacked,
  faCommentSms,
} from "@fortawesome/free-solid-svg-icons";
export function getMenuList(role) {
  if (role === "OWNER" || role === "ADMIN") {
    return [
      {
        name: "Bosh sahifa",
        element: faHouse,
        to: "/home",
      },
      { name: "Xodimlar", element: faUserGroup, to: "/employees" },
      { name: "O'qituvchilar", element: faUsers, to: "/teachers" },
      { name: "Talabalar", element: faGraduationCap, to: "/students" },
      { name: "Guruhlar", element: faUsersRectangle, to: "/groups" },
      { name: "Moliya", element: faMoneyBills, to: "/finance" },
      { name: "Kurslar", element: faCubesStacked, to: "/courses" },
      { name: "Xonalar", element: faDoorClosed, to: "/rooms" },
      { name: "Fanlar", element: faShapes, to: "/subjects" },
      { name: "SMS xabarlar", element: faCommentSms, to: "/sms" },
      { name: "Sozlamalar", element: faGear, to: "/settings" },
    ];
  }
  if (role === "FINANCIER") {
    return [
      {
        name: "Bosh sahifa",
        element: faHouse,
        to: "/home",
      },
      // { name: "Xodimlar", element: faUserGroup, to: "/employees" },
      // { name: "O'qituvchilar", element: faUsers, to: "/teachers" },
      // { name: "Talabalar", element: faGraduationCap, to: "/students" },
      // { name: "Guruhlar", element: faUsersRectangle, to: "/groups" },
      { name: "Moliya", element: faMoneyBills, to: "/finance" },
      { name: "Xonalar", element: faDoorClosed, to: "/rooms" },
      { name: "Fanlar", element: faShapes, to: "/subjects" },
    ];
  }
  if (role === "MODERATOR") {
    return [
      {
        name: "Bosh sahifa",
        element: faHouse,
        to: "/home",
      },
      { name: "Xodimlar", element: faUserGroup, to: "/employees" },
      { name: "O'qituvchilar", element: faUsers, to: "/teachers" },
      { name: "Talabalar", element: faGraduationCap, to: "/students" },
      { name: "Guruhlar", element: faUsersRectangle, to: "/groups" },

      { name: "Xonalar", element: faDoorClosed, to: "/rooms" },
      { name: "Fanlar", element: faShapes, to: "/subjects" },
    ];
  }
}
