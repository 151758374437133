import React, { useEffect, useState } from "react";
import { Tooltip, Tabs, Tab, Button } from "@nextui-org/react";
import GroupTimeRow from "../../components/Group/GroupTimeRow";
import { PlusIcon } from "@heroicons/react/24/outline";
import useLessonTime from "../../customHooks/useLessonTime";
import VerticalBoard from "../../components/Container/VerticalBoard";
import ForestyForm from "../../components/Form/ForestyForm";
import {
  courseBasedValues,
  subjectBasedValues,
  validationSchemaSubject,
  validationSchemaCourse,
  subjectBasedFields,
  courseBasedFields,
} from "./data/form";
import { useDispatch, useSelector } from "react-redux";
import { createGroup } from "./groupsSlice";
import { useNavigate } from "react-router-dom";
import { getAllTeachers } from "../TeachersPage/teachersSlice";
import { getAllSubjects } from "../SubjectPage/subjectsSlice";
import { getAllCourses } from "../CoursePage/coursesSlice";
import { getAllRooms } from "../RoomsPage/roomsSlice";
import { getLessonTimes } from "./utils";

const CreateGroupPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [groupType, setGroupType] = useState("subject");
  const [typeSelected, setTypeSelected] = useState(false);

  const { loading } = useSelector((state) => state.groups);
  const { teachers } = useSelector((state) => state.teachers);
  const { subjects } = useSelector((state) => state.subjects);
  const { courses } = useSelector((state) => state.courses);
  const { rooms } = useSelector((state) => state.rooms);

  const [times, addTime, deleteTimeRow, updateTime, clearTimes] = useLessonTime(
    [
      {
        day: 0,
        fromHour: 5,
        fromMinute: 0,
        toHour: 6,
        toMinute: 0,
      },
    ]
  );

  useEffect(() => {
    dispatch(getAllTeachers());
    dispatch(getAllSubjects());
    dispatch(getAllCourses());
    dispatch(getAllRooms());
  }, []);

  return (
    <VerticalBoard>
      <span className='text-[22px] mt-3 dark:text-foresty-white text-center font-bold'>
        {"Guruh qo'shish"}
      </span>
      {!typeSelected ? (
        <div className='flex py-14 w-full items-center justify-center'>
          <div className='flex flex-col gap-5 shadow-lg bg-white border-[1px] border-neutral-300 dark:border-neutral-700 dark:bg-neutral-800/50 rounded-2xl p-5 w-[400px] items-center'>
            <span className='text-[16px] font-semibold'>
              {"Guruhning turini tanlash"}
            </span>
            <Tabs
              radius='full'
              size='lg'
              color='primary'
              aria-label='Tabs radius'
              selectedKey={groupType}
              onSelectionChange={setGroupType}
            >
              <Tab key='subject' title='Fan asosida' />
              <Tab key='course' title='Kurs asosida' />
            </Tabs>
            <Button onPress={() => setTypeSelected(true)}>Tanlash</Button>
          </div>
        </div>
      ) : (
        <div className='grid grid-cols-2 gap-8'>
          <ForestyForm
            // isValidate={false}
            isLoading={loading}
            colsNum={1}
            initialValues={
              groupType == "subject" ? subjectBasedValues : courseBasedValues
            }
            fields={
              groupType == "subject"
                ? subjectBasedFields.map((el) => {
                    if (el.name === "subjectId") {
                      el.selectData = subjects;
                      return el;
                    } else if (el.name === "teacherId") {
                      el.selectData = teachers.map((t) => {
                        return {
                          id: t.id,
                          name: `${t?.teacher.user.firstName} ${t?.teacher.user.lastName} ${t?.teacher.user.fatherName}`,
                        };
                      });
                      return el;
                    } else if (el.name === "roomId") {
                      el.selectData = rooms.map((r) => ({
                        id: r.id,
                        name: `${r.roomNumber}-xona`,
                      }));
                      return el;
                    }
                    return el;
                  })
                : courseBasedFields.map((el) => {
                    if (el.name === "courseId") {
                      el.selectData = courses;
                      return el;
                    } else if (el.name === "teacherId") {
                      el.selectData = teachers.map((t) => {
                        return {
                          id: t.id,
                          name: `${t?.teacher.user.firstName} ${t?.teacher.user.lastName} ${t?.teacher.user.fatherName}`,
                        };
                      });
                      return el;
                    } else if (el.name === "roomId") {
                      el.selectData = rooms.map((r) => ({
                        id: r.id,
                        name: `${r.roomNumber}-xona`,
                      }));
                      return el;
                    }
                    return el;
                  })
            }
            validationSchema={
              groupType == "subject"
                ? validationSchemaSubject
                : validationSchemaCourse
            }
            handleSubmit={(body) => {
              let reqbody = {
                ...body,
                lessonTimes: getLessonTimes(times),
                type: groupType,
              };
              dispatch(
                createGroup({
                  data: reqbody,
                  navigate: () => navigate("/groups"),
                })
              );
            }}
            submitBtnText="Qo'shish"
          />
          <div className='flex flex-col gap-3 mb-3 bg-white dark:bg-neutral-800 shadow-lg h-fit pr-12 p-8 rounded-2xl border-[1px] border-neutral-200 dark:border-neutral-600'>
            <span className='flex flex-row gap-1 text-[14px] dark:text-foresty-white font-bold ml-2'>
              {"Guruh dars vaqtlari"}
              <Tooltip
                content={<span className='text-[12px]'>Required</span>}
                color='secondary'
              >
                <span className='text-[14px] font-bold text-forestyFrozen'>
                  *
                </span>
              </Tooltip>
            </span>
            <div className='flex flex-col px-1 items-end gap-5'>
              {times.map((item, index) => (
                <GroupTimeRow
                  key={index}
                  time={item}
                  changeTimeRow={updateTime}
                  dayOrder={index + 1}
                  deleteBtnVisible={index == times.length - 1 && index !== 0}
                  deleteRow={() => {
                    deleteTimeRow(index);
                  }}
                />
              ))}

              {times.length < 7 && (
                <button
                  onClick={addTime}
                  className='flex w-fit justify-center items-center font-bold bg-fyTropic-400/10 text-foresty-500 p-2 rounded-md'
                >
                  <PlusIcon className='w-[20px] stroke-[2px]' />
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </VerticalBoard>
  );
};

export default CreateGroupPage;
