import * as Yup from "yup";

export const updateSmsAccountFields = [
  {
    name: "username",
    type: "text",
    label: "Email (Login)",
    placeholder: "Username yoki Emailni kiriting...",
  },
  {
    name: "secretKey",
    type: "text",
    label: "Yashirin kalit (Parol)",
    placeholder: "Yashirin kalitni kiriting...",
  },
];

export const updateSmsAccountEmptyValues = {
  username: "",
  secretKey: "",
};

export const updateSmsAccountValidationSchema = Yup.object().shape({
  username: Yup.string(),
  secretKey: Yup.string(),
});

export const updateEduCenterDescriptionFields = [
  {
    name: "description",
    type: "textarea",
    label: "Markaz haqida",
    placeholder: "Ma'lumot kiriting...",
  },
];

export const updateEduCenterDescriptionValidationSchema = Yup.object().shape({
  description: Yup.string(),
});

export const updateEduCenterDataFields = [
  {
    name: "name",
    type: "text",
    label: "Ismi",
    placeholder: "Ismini kiriting...",
    isRequired: true,
  },
  {
    name: "username",
    type: "text",
    label: "Username",
    placeholder: "Usernameni kiriting...",
    isRequired: true,
  },
  {
    name: "phoneNumber",
    type: "text",
    label: "Tel. raqami",
    isRequired: true,
    startContent: "+998",
  },
];

export const updateEduCenterDataEmptyValues = {
  name: "",
  username: "",
  phoneNumber: "",
};

export const updateEduCenterDataValidationSchema = Yup.object().shape({
  name: Yup.string().required("Bo'sh bo'lmasligi kerak!"),
  username: Yup.string().required("Bo'sh bo'lmasligi kerak!"),

  phoneNumber: Yup.string()
    .matches(/^\(\d{2}\) \d{3}-\d{2}-\d{2}$/, "Tel. raqamni to'g'ri kiriting!")
    .required("Bo'sh bo'lmasligi kerak!"),
});

export const updateSocialLinksFields = [
  {
    name: "telegram",
    type: "text",
    label: "Telegram",
    placeholder: "Linkni kiriting...",
  },
  {
    name: "instagram",
    type: "text",
    label: "Instagram",
    placeholder: "Linkni kiriting...",
  },
  {
    name: "youTube",
    type: "text",
    label: "YouTube",
    placeholder: "Linkni kiriting...",
  },
  {
    name: "facebook",
    type: "text",
    label: "Facebook",
    placeholder: "Linkni kiriting...",
  },
  {
    name: "twitter",
    type: "text",
    label: "Twitter",
    placeholder: "Linkni kiriting...",
  },
  {
    name: "website",
    type: "text",
    label: "Veb sayt",
    placeholder: "Linkni kiriting...",
  },
];

export const updateSocialLinksEmptyValues = {
  telegram: "",
  instagram: "",
  youTube: "",
  facebook: "",
  twitter: "",
  website: "",
};

export const updateSocialLinksValidationSchema = Yup.object().shape({
  telegram: Yup.string().url(),
  instagram: Yup.string().url(),
  youTube: Yup.string().url(),
  facebook: Yup.string().url(),
  twitter: Yup.string().url(),
  website: Yup.string().url(),
});
