import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash";
import Api from "../../config/Api";
import { toast } from "react-toastify";
import { changeFloorState } from "./utils";

export const getAllRooms = createAsyncThunk(
  "rooms/getAll",
  async (body = {}) => {
    const response = await Api.get("/room");
    return response.data;
  }
);

export const createRoom = createAsyncThunk(
  "rooms/create",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.post("/room", body);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateRoom = createAsyncThunk(
  "rooms/update",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.put(`/room/${body?.id}`, body);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteRoom = createAsyncThunk(
  "rooms/delete",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.delete(`/room/${body?.id}`);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const roomsSlice = createSlice({
  name: "rooms",
  initialState: {
    rooms: null,
    floors: null,
    loading: false,
    error: null,
  },
  reducers: {
    resetError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      ///------------ GET rooms ------------------/////
      .addCase(getAllRooms.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllRooms.fulfilled, (state, action) => {
        state.loading = false;
        state.rooms = action.payload?.data;
        state.floors = changeFloorState(state);
      })
      .addCase(getAllRooms.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ CREATE rooms ------------------/////
      .addCase(createRoom.pending, (state) => {
        state.loading = true;
      })
      .addCase(createRoom.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.rooms = [...state.rooms, payload?.data];

        state.floors = changeFloorState(state);

        toast.success(payload?.message);
      })
      .addCase(createRoom.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        toast.error(payload?.message);
      })

      ///------------ delete rooms ------------------/////
      .addCase(deleteRoom.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteRoom.fulfilled, (state, { payload }) => {
        state.loading = false;

        const ctgIndex = findIndex(state.rooms, { id: payload?.data?.id });
        state.rooms.splice(ctgIndex, 1);
        state.floors = changeFloorState(state);

        toast.success(payload?.message);
      })
      .addCase(deleteRoom.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        toast.error(payload?.message);
      })

      ///------------ UPDATE rooms ------------------/////
      .addCase(updateRoom.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateRoom.fulfilled, (state, { payload }) => {
        state.loading = false;

        const ctgIndex = findIndex(state.rooms, { id: payload?.data?.id });
        state.rooms[ctgIndex] = payload?.data;
        state.floors = changeFloorState(state);

        toast.success(payload?.message);
      })
      .addCase(updateRoom.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        toast.error(payload?.message);
      });
  },
});

export const { resetError } = roomsSlice.actions;

export default roomsSlice.reducer;
