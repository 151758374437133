import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ForestyTable from "../../../components/Table/ForestyTable";

import {
  INITIAL_VISIBLE_COLUMNS,
  columns,
  searchIndexes,
} from "./data/paymentsTable";
import { getStudentGroups, getStudentPayments } from "../studentsSlice";

const StudentPaymentsPage = ({ student }) => {
  const { payments } = useSelector((state) => state.students);

  const dispatch = useDispatch();

  useEffect(() => {
    student && dispatch(getStudentPayments(student?.id));
  }, []);
  return (
    <div>
      {payments && (
        <div className='bg-white p-5 rounded-3xl dark:bg-neutral-800/50'>
          <ForestyTable
            isStriped
            searchIndexes={searchIndexes}
            //   viewButton={(id) => (
            //     <NavigateViewButton isLight path={`/students/${id}`} />
            //   )}

            tableData={payments}
            columns={columns}
            initialVisibleColumns={INITIAL_VISIBLE_COLUMNS}
            editButton={null}
            deleteButton={null}
          />
        </div>
      )}
    </div>
  );
};

export default StudentPaymentsPage;
