import React, { useEffect } from "react";
import PageContainer from "../../components/Container/PageContainer";
import PageHeader from "../../components/Dashboard/PageHeader";
import PageBoard from "../../components/Container/PageBoard";
import ForestyTable from "../../components/Table/ForestyTable";
import { useDispatch, useSelector } from "react-redux";
import {
  createCourse,
  deleteCourse,
  getAllCourses,
  updateCourse,
} from "./coursesSlice";
import {
  INITIAL_VISIBLE_COLUMNS,
  colors,
  columns,
  emptyValues,
  fields,
  searchIndexes,
  validationSchema,
} from "./data";
import CreateEditModal from "../../components/Modal/CreateEditModal";
import { DeleteButton, EditButton } from "../../components/Buttons/CRUDbuttons";
import DeleteModal from "../../components/Modal/DeleteModal";
import { icons } from "../../utils/icons";

const CoursesPage = () => {
  const dispatch = useDispatch();
  const { courses, loading } = useSelector((state) => state.courses);

  useEffect(() => {
    !courses && dispatch(getAllCourses());
  }, []);
  return (
    <PageContainer>
      <PageHeader title='Kurslar' count={courses?.length} />
      <PageBoard>
        {courses && (
          <ForestyTable
            searchIndexes={searchIndexes}
            createModal={
              <CreateEditModal
                title={"Kurs qo'shish"}
                loading={loading}
                btnText={"Kurs qo'shish"}
                fields={fields.map((el) => {
                  if (el.name === "colorName") {
                    el.selectData = colors;
                    return el;
                  }
                  if (el.name === "iconName") {
                    el.selectData = icons;
                    return el;
                  }
                  return el;
                })}
                initialValues={emptyValues}
                validationSchema={validationSchema}
                submitBtnText={"Qo'shish"}
                handleSubmit={(body) => dispatch(createCourse(body))}
              />
            }
            tableData={courses}
            columns={columns}
            initialVisibleColumns={INITIAL_VISIBLE_COLUMNS}
            editButton={(data) => (
              <CreateEditModal
                button={<EditButton isLight />}
                title={"Kursni tahrirlash"}
                loading={loading}
                btnText={"Tahrirlash"}
                fields={fields.map((el) => {
                  if (el.name === "colorName") {
                    el.selectData = colors;
                    return el;
                  }
                  if (el.name === "iconName") {
                    el.selectData = icons;
                    return el;
                  }
                  return el;
                })}
                initialValues={{
                  ...data,
                  colorName: data.color.colorName,
                  iconName: data.icon,
                }}
                validationSchema={validationSchema}
                submitBtnText={"Tahrirlash"}
                handleSubmit={(body) =>
                  dispatch(updateCourse({ ...body, id: data.id }))
                }
              />
            )}
            deleteButton={(data) => (
              <DeleteModal
                button={<DeleteButton isLight />}
                contextText={`${data.name} kursni`}
                handleSubmit={() => dispatch(deleteCourse({ id: data.id }))}
              />
            )}
          />
        )}
      </PageBoard>
    </PageContainer>
  );
};

export default CoursesPage;
