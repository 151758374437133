import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getEskizUser,
  getSmsAccount,
  updateSmsAccount,
} from "../ProfilePage/profileSlice";
import { getMoneyPattern } from "../../utils/regex";
import ForestyForm from "../../components/Form/ForestyForm";
import {
  updateSmsAccountEmptyValues,
  updateSmsAccountFields,
  updateSmsAccountValidationSchema,
} from "./data/updateForm";

const SmsServicePage = () => {
  const { smsAccount, smsEskizUser } = useSelector((state) => state.profile);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSmsAccount());
    dispatch(getEskizUser());
  }, []);
  return (
    <div className='flex flex-col w-full items-center'>
      <div className='grid grid-cols-2 w-full bg-white/50 dark:bg-neutral-800/50 p-5 rounded-3xl gap-5'>
        <div className='w-full flex flex-col gap-5 items-center py-8 p-5 bg-white dark:bg-neutral-800 rounded-2xl'>
          <img
            src='https://my.eskiz.uz/assets/images/eskiz.svg'
            className='w-[150px] bg-white p-5 rounded-xl'
          />
          {smsEskizUser ? (
            <div className='grid grid-cols-3 gap-3 w-full text-[16px] p-5'>
              <span className='font-bold'>{"F.I.Sh"}</span>
              <span className='col-span-2'>{smsEskizUser?.name}</span>

              <span className='font-bold'>{"Email"}</span>
              <span className='col-span-2'>{smsEskizUser?.email}</span>

              <span className='font-bold'>{"Status"}</span>
              <span className='col-span-2'>{smsEskizUser?.status}</span>

              <span className='font-bold'>{"Balance"}</span>
              <span className='col-span-2'>{`${getMoneyPattern(
                smsEskizUser?.balance,
                ","
              )} so'm`}</span>
            </div>
          ) : (
            <span>{"Ma'lumot topilmadi."}</span>
          )}
          {/* {smsEskizUser && } */}
        </div>

        <div className='w-full flex flex-col gap-5 items-center py-8 p-5 bg-white dark:bg-neutral-800 rounded-2xl'>
          <ForestyForm
            enableReinitialize
            // isValidate={false}
            // isLoading={loading}
            colsNum={1}
            initialValues={
              !smsAccount ? updateSmsAccountEmptyValues : smsAccount
            }
            fields={updateSmsAccountFields}
            validationSchema={updateSmsAccountValidationSchema}
            handleSubmit={(body) => dispatch(updateSmsAccount(body))}
            submitBtnText='Saqlash'
          />
        </div>
      </div>
    </div>
  );
};

export default SmsServicePage;
