import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import VerticalBoard from "../../components/Container/VerticalBoard";
import ForestyForm from "../../components/Form/ForestyForm";
import { emptyValues, validationSchema, createFields } from "./data";
import { createTeacher } from "./teachersSlice";
import { getAllSubjects } from "../SubjectPage/subjectsSlice";

const CreateTeacherPage = () => {
  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state.teachers);
  const { subjects } = useSelector((state) => state.subjects);

  useEffect(() => {
    dispatch(getAllSubjects());
  }, []);

  return (
    <VerticalBoard>
      <span className='text-[22px] mt-3 dark:text-foresty-white text-center font-bold'>
        {"O'qituvchi qo'shish"}
      </span>
      {subjects && (
        <ForestyForm
          // isValidate={false}
          isLoading={loading}
          colsNum={2}
          initialValues={emptyValues}
          fields={createFields.map((el) => {
            if (el.name === "subjectsId") {
              el.selectData = subjects;
              return el;
            }
            return el;
          })}
          validationSchema={validationSchema}
          handleSubmit={(body) => dispatch(createTeacher(body))}
          submitBtnText="Qo'shish"
        />
      )}
    </VerticalBoard>
  );
};

export default CreateTeacherPage;
