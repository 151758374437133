import React from "react";
import UploadImageInput from "../../components/Form/UploadImageInput";
import { useDispatch, useSelector } from "react-redux";
import { getHeaderImageSrc, getLogoSrc } from "../../utils/profile";
import { v4 } from "uuid";
import {
  updateEduCenterDataFields,
  updateEduCenterDataValidationSchema,
  updateEduCenterDescriptionFields,
  updateEduCenterDescriptionValidationSchema,
  updateSocialLinksEmptyValues,
  updateSocialLinksFields,
  updateSocialLinksValidationSchema,
} from "./data/updateForm";
import ForestyForm from "../../components/Form/ForestyForm";
import {
  updateEduCenterAddress,
  updateEduCenterData,
  updateEduCenterDescription,
  updateSocialLinks,
} from "../ProfilePage/profileSlice";
import { getPhoneNumberPattern } from "../../utils/regex";
import {
  updateAddressEmptyValues,
  updateAddressFields,
  updateAddressValidationSchema,
} from "../ProfilePage/data/profileForm";
import { regions } from "../ProfilePage/data/regions";
import { Skeleton, Textarea } from "@nextui-org/react";

const SettingsUpdatePage = ({ eduCenter }) => {
  const dispatch = useDispatch();
  const { uploadLoading, socialLinks, linksLoaded } = useSelector(
    (state) => state.profile
  );

  return (
    eduCenter && (
      <div className='grid grid-cols-3 w-full gap-5'>
        <div className='flex flex-col col-span-2 w-full gap-5'>
          <div className='flex flex-row w-full bg-white/50 dark:bg-neutral-800 rounded-3xl py-2'>
            <UploadImageInput
              fileId='logoFileId'
              key={v4()}
              isCompact
              label='Logo'
              imageData={eduCenter?.logo}
              imageSrc={getLogoSrc(eduCenter?.logo)}
              uploadLoading={uploadLoading}
              uploadUrl={"/user/updateLogo"}
              deleteUrl={"/user/removeLogo"}
            />
            <UploadImageInput
              fileId='hiFileId'
              key={v4()}
              isCompact
              widthFull
              label='Header Image'
              imageData={eduCenter?.headerImage}
              imageSrc={getHeaderImageSrc(eduCenter?.headerImage)}
              uploadLoading={uploadLoading}
              uploadUrl={"/user/updateHeaderImage"}
              deleteUrl={"/user/removeHeaderImage"}
            />
          </div>
          <div className='flex flex-col w-full bg-white dark:bg-neutral-800 rounded-3xl p-5'>
            {" "}
            <ForestyForm
              isSmall
              enableReinitialize
              // isValidate={false}
              // isLoading={loading}
              colsNum={1}
              initialValues={eduCenter}
              fields={updateEduCenterDescriptionFields}
              validationSchema={updateEduCenterDescriptionValidationSchema}
              handleSubmit={(body) =>
                dispatch(updateEduCenterDescription(body))
              }
              submitBtnText='Saqlash'
            />
          </div>
        </div>
        <div className='flex flex-col w-full gap-5'>
          <div className='flex flex-col w-full bg-white dark:bg-neutral-800 rounded-3xl p-5'>
            <ForestyForm
              isSmall
              enableReinitialize
              // isValidate={false}
              // isLoading={loading}
              colsNum={1}
              initialValues={{
                ...eduCenter,
                phoneNumber: getPhoneNumberPattern(eduCenter?.phoneNumber),
              }}
              fields={updateEduCenterDataFields}
              validationSchema={updateEduCenterDataValidationSchema}
              handleSubmit={(body) => dispatch(updateEduCenterData(body))}
              submitBtnText='Saqlash'
            />
          </div>
          <div className='flex flex-col w-full bg-white dark:bg-neutral-800 rounded-3xl p-5'>
            <ForestyForm
              isSmall
              enableReinitialize
              // isValidate={false}
              // isLoading={loading}
              colsNum={1}
              initialValues={
                !eduCenter?.address
                  ? updateAddressEmptyValues
                  : eduCenter?.address
              }
              fields={updateAddressFields.map((el) => {
                if (el.name === "region") {
                  el.selectData = regions;
                  return el;
                }
                return el;
              })}
              validationSchema={updateAddressValidationSchema}
              handleSubmit={(body) => dispatch(updateEduCenterAddress(body))}
              submitBtnText='Saqlash'
            />
          </div>

          <div className='flex flex-col w-full bg-white dark:bg-neutral-800 rounded-3xl p-5'>
            <Skeleton isLoaded={linksLoaded} className='rounded-lg'>
              <ForestyForm
                isSmall
                enableReinitialize
                // isValidate={false}
                // isLoading={loading}
                colsNum={1}
                initialValues={
                  !socialLinks ? updateSocialLinksEmptyValues : socialLinks
                }
                fields={updateSocialLinksFields}
                validationSchema={updateSocialLinksValidationSchema}
                handleSubmit={(body) => dispatch(updateSocialLinks(body))}
                submitBtnText='Saqlash'
              />
            </Skeleton>
          </div>
        </div>
      </div>
    )
  );
};

export default SettingsUpdatePage;
