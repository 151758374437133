export const columns = [
  { name: "ID", uid: "rowOrder", sortable: true },

  { name: "To'langan summa", uid: "amount", sortable: true },
  { name: "To'lov turi", uid: "paymentType", sortable: true },
  { name: "Context", uid: "secondaryContext", sortable: true },

  { name: "SANASI", uid: "created_at", sortable: true },
  // { name: "STATUS", uid: "status", sortable: true },
  // { name: "ACTIONS", uid: "actions" },
];

export const INITIAL_VISIBLE_COLUMNS = [
  "rowOrder",
  "amount",
  "secondaryContext",
  "paymentType",

  "created_at",
  // "actions",
];

export const searchIndexes = ["amount"];
