import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash";
import Api from "../../config/Api";
import { toast } from "react-toastify";

export const getAllCourses = createAsyncThunk(
  "courses/getAll",
  async (body = {}) => {
    const response = await Api.get("/course");
    return response.data;
  }
);

export const createCourse = createAsyncThunk(
  "courses/create",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.post("/course", body);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateCourse = createAsyncThunk(
  "courses/update",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.put(`/course/${body?.id}`, body);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteCourse = createAsyncThunk(
  "courses/delete",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.delete(`/course/${body?.id}`);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const coursesSlice = createSlice({
  name: "courses",
  initialState: {
    courses: null,
    floors: null,
    loading: false,
    error: null,
  },
  reducers: {
    resetError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      ///------------ GET courses ------------------/////
      .addCase(getAllCourses.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllCourses.fulfilled, (state, action) => {
        state.loading = false;
        state.courses = action.payload?.data;
      })
      .addCase(getAllCourses.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ CREATE courses ------------------/////
      .addCase(createCourse.pending, (state) => {
        state.loading = true;
      })
      .addCase(createCourse.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.courses = [payload?.data, ...state.courses];
        toast.success(payload?.message);
      })
      .addCase(createCourse.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        toast.error(payload?.message);
      })

      ///------------ delete courses ------------------/////
      .addCase(deleteCourse.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteCourse.fulfilled, (state, { payload }) => {
        state.loading = false;

        const ctgIndex = findIndex(state.courses, { id: payload?.data?.id });
        state.courses.splice(ctgIndex, 1);
        toast.success(payload?.message);
      })
      .addCase(deleteCourse.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        toast.error(payload?.message);
      })

      ///------------ UPDATE courses ------------------/////
      .addCase(updateCourse.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCourse.fulfilled, (state, { payload }) => {
        state.loading = false;

        const ctgIndex = findIndex(state.courses, { id: payload?.data?.id });
        state.courses[ctgIndex] = payload?.data;
        toast.success(payload?.message);
      })
      .addCase(updateCourse.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        toast.error(payload?.message);
      });
  },
});

export const { resetError } = coursesSlice.actions;

export default coursesSlice.reducer;
