import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash";
import Api from "../../config/Api";
import { toast } from "react-toastify";
import { redirect } from "react-router-dom";

export const getAllTeachers = createAsyncThunk(
  "teachers/getAll",
  async (body = {}) => {
    const response = await Api.get("/teacher");
    return response.data;
  }
);

export const getTeacher = createAsyncThunk(
  "teachers/get",
  async (body = {}) => {
    const response = await Api.get(`/teacher/${body}`);
    return response.data;
  }
);

export const getTeacherData = createAsyncThunk(
  "teachers/getData",
  async (body = {}) => {
    const response = await Api.get(`/teacher/data/${body}`);
    return response.data;
  }
);

export const getTeacherGroups = createAsyncThunk(
  "teachers/getGroups",
  async (body = {}) => {
    const response = await Api.get(`/teacher/groups/${body?.id}`);
    return response.data;
  }
);

export const createTeacher = createAsyncThunk(
  "teachers/create",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.post("/teacher", body);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateTeacher = createAsyncThunk(
  "teachers/update",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.put(`/teacher/${body?.id}`, body);

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteTeacher = createAsyncThunk(
  "teachers/delete",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.delete(`/teacher/${body?.id}`);
      body?.navigate();
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getTeacherPaymentGroups = createAsyncThunk(
  "teachers/getPaymentGroups",
  async (body = {}) => {
    const response = await Api.get(`/teacher/payments/groups/${body?.id}`);
    return response.data;
  }
);

export const getTeacherPaymentsByGroupId = createAsyncThunk(
  "teachers/getPaymentsByGroupId",
  async (body = {}) => {
    const response = await Api.get(`/teacher/payments/${body?.id}`);
    return response.data;
  }
);

export const payTeacherPayment = createAsyncThunk(
  "teachers/payPayment",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.post(`/payment/payTeacherPayment`, body?.data);
      // body?.onClose();
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const teachersSlice = createSlice({
  name: "teachers",
  initialState: {
    teacher: null,
    teacherData: null,
    teachers: null,
    groups: null,

    paymentGroups: null,
    payments: null,

    floors: null,
    loading: false,
    updateLoading: false,
    paymentLoading: false,
    error: null,
  },
  reducers: {
    resetError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      ///------------ GET teachers ------------------/////
      .addCase(getAllTeachers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllTeachers.fulfilled, (state, action) => {
        state.loading = false;
        const teachers = action.payload?.data;

        state.teachers = teachers;
      })
      .addCase(getAllTeachers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ GET teacher ------------------/////
      .addCase(getTeacher.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTeacher.fulfilled, (state, { payload }) => {
        state.loading = false;

        state.teacher = payload?.data;
      })
      .addCase(getTeacher.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ GET teacher ------------------/////
      .addCase(getTeacherData.pending, (state) => {
        // state.loading = true;
      })
      .addCase(getTeacherData.fulfilled, (state, { payload }) => {
        // state.loading = false;

        state.teacherData = payload?.data;
      })
      .addCase(getTeacherData.rejected, (state, action) => {
        // state.loading = false;
        state.error = action.payload;
      })

      ///------------ GET teacher Groups ------------------/////
      .addCase(getTeacherGroups.pending, (state) => {})
      .addCase(getTeacherGroups.fulfilled, (state, { payload }) => {
        state.groups = payload?.data;
      })
      .addCase(getTeacherGroups.rejected, (state, action) => {
        state.error = action.payload;
      })

      ///------------ GET teacher PAYMENT Groups ------------------/////
      .addCase(getTeacherPaymentGroups.pending, (state) => {})
      .addCase(getTeacherPaymentGroups.fulfilled, (state, { payload }) => {
        state.paymentGroups = payload?.data;
      })
      .addCase(getTeacherPaymentGroups.rejected, (state, action) => {
        state.error = action.payload;
      })

      ///------------ GET teacher payments by group id  ------------------/////
      .addCase(getTeacherPaymentsByGroupId.pending, (state) => {})
      .addCase(getTeacherPaymentsByGroupId.fulfilled, (state, { payload }) => {
        let payments = payload?.data.sort(
          (a, b) => a.groupMonth?.month - b?.groupMonth?.month
        );
        state.payments = payments;
      })
      .addCase(getTeacherPaymentsByGroupId.rejected, (state, action) => {
        state.error = action.payload;
      })

      ///------------ CREATE teachers ------------------/////
      .addCase(createTeacher.pending, (state) => {
        state.loading = true;
      })
      .addCase(createTeacher.fulfilled, (state, { payload }) => {
        state.loading = false;
        // state.teachers = [payload?.data, ...state.teachers];
        toast.success(payload?.message);
      })
      .addCase(createTeacher.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        toast.error(payload?.message);
      })

      ///------------ delete teachers ------------------/////
      .addCase(deleteTeacher.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteTeacher.fulfilled, (state, { payload }) => {
        state.loading = false;

        // const ctgIndex = findIndex(state.teachers, { id: payload?.data?.id });
        // state.teachers.splice(ctgIndex, 1);
        toast.success(payload?.message);
      })
      .addCase(deleteTeacher.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        toast.error(payload?.message);
      })

      ///------------ UPDATE teachers ------------------/////
      .addCase(updateTeacher.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(updateTeacher.fulfilled, (state, { payload }) => {
        state.updateLoading = false;

        // const ctgIndex = findIndex(state.teachers, { id: payload?.data?.id });
        state.teacher = payload?.data;

        toast.success(payload?.message);
      })
      .addCase(updateTeacher.rejected, (state, { payload }) => {
        state.updateLoading = false;
        state.error = payload;
        toast.error(payload?.message);
      })

      ///------------ PAY teacher payment ------------------/////
      .addCase(payTeacherPayment.pending, (state) => {
        state.paymentLoading = true;
      })
      .addCase(payTeacherPayment.fulfilled, (state, { payload }) => {
        state.paymentLoading = false;

        const ctgIndex = findIndex(state.payments, {
          id: payload?.data?.payment?.id,
        });
        state.payments[ctgIndex] = payload?.data?.payment;
        const ctgIndexMonth = findIndex(state.paymentGroups, {
          id: payload?.data?.group?.id,
        });
        state.paymentGroups[ctgIndexMonth] = payload?.data?.group;

        toast.success(payload?.message);
      })
      .addCase(payTeacherPayment.rejected, (state, { payload }) => {
        state.paymentLoading = false;
        state.error = payload;
        toast.error(payload?.message);
      });
  },
});

export const { resetError } = teachersSlice.actions;

export default teachersSlice.reducer;
