import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllGroupLessonsByDate } from "./calendarSlice";
import { get, getAllRooms } from "../RoomsPage/roomsSlice";
import { timeArr } from "./data";
import { v4 } from "uuid";
import { sortLessonTimesByRoom } from "./utils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareRootVariable } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "next-themes";
import { getTime } from "../../utils/lessonTime";
import CalendarBar from "./calendarBar";
import { todayDateText } from "../../utils/date";
import { getIcon } from "../../utils/icons";

const CalendarPage = () => {
  let today = new Date();
  const [selectedDate, setSelectedDate] = useState(
    new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0, 0)
  );

  const { theme } = useTheme();
  const [roomsData, setRoomsData] = useState([]);

  const [roomsCol, setRoomsCol] = useState([]);

  const dispatch = useDispatch();
  const { groupLessons } = useSelector((state) => state.calendar);
  const { rooms } = useSelector((state) => state.rooms);
  useEffect(() => {
    dispatch(getAllRooms());
    dispatch(
      getAllGroupLessonsByDate({
        data: { date: selectedDate.toISOString() },
      })
    );
  }, [selectedDate]);

  useEffect(() => {
    if (groupLessons && rooms) {
      let cols = sortLessonTimesByRoom(groupLessons);
      console.log(cols);
      const roomsArr = rooms.map((el) => ({
        ...el,
        count:
          cols.find((item) => item.roomNumber == el.roomNumber)?.lessonTimes
            .length ?? 0,
        name: `${el.roomNumber} - xona`,
      }));

      setRoomsData(
        roomsArr.sort((a, b) => parseFloat(b.count) - parseFloat(a.count))
      );

      setRoomsCol(cols);
    }
  }, [groupLessons, rooms]);

  return (
    <div className='flex flex-col w-full dark:bg-[#1f1f1f] bg-fyTropic-200 rounded-xl font-space'>
      <div className='flex flex-row justify-between items-end px-8 py-3'>
        <span className=' text-fyTropic dark:text-foresty-white text-[20px] font-bold mt-3'>
          <span className='font-normal mr-2'>{todayDateText(false)[0]}</span>
          {todayDateText(false)[1]}{" "}
          <span className='font-normal ml-2'>{todayDateText(false)[2]}</span>
        </span>
        {/* <input
          onChange={(e) => {
            console.log(new Date(e.target.value));
            setSelectedDate(new Date(e.target.value));
          }}
          placeholder='Sanani kiriting'
          type='date'
          className='w-[230px] h-[30px] px-3 py-5 text-[14px] rounded-xl dark:bg-forestydark-600 bg-foresty-white dark:text-foresty-white'
        /> */}
      </div>
      {/* <CalendarBar selectDate={setSelectedDate} selectedDate={selectedDate} />
      <CalendarBoard selectedDate={selectedDate} /> */}

      <CalendarBar selectDate={setSelectedDate} selectedDate={selectedDate} />

      <div className='w-full rounded-b-xl  dark:bg-forestydark-700 bg-foresty-white border-[1px] border-forestydark-100/20 dark:border-forestydark-300 mt-2 pb-3 '>
        <div
          // ref={boxSizeRef}
          className='flex flex-row items-start min-w-full justify-center w-full mt-5 h-[720px] overflow-scroll'
        >
          <div className='flex flex-col sticky left-0 bg-foresty-white dark:bg-forestydark-700 z-20'>
            <div className='flex flex-none w-[90px] h-[50px] justify-center items-center text-forestydark-100 sticky bg-foresty-white dark:bg-forestydark-700 top-0 z-[12]'>
              VAQT
              <span className='absolute right-0 w-[1px] bg-forestydark-100 h-[20px]' />
            </div>
            <div className='flex flex-col relative px-2 w-full items-center h-fit dark:text-foresty-white'>
              {/* {showTimeLine && (
                <div
                  onMouseOver={() => setShowCurrentTime(true)}
                  onMouseLeave={() => setShowCurrentTime(false)}
                  style={{ top: `${currentTime.top * 60 + 10}px` }}
                  className='absolute h-[1px] left-0 w-full bg-gradient-to-r from-forestyWarning/50 via-forestyWarning/10 to-forestyWarning'
                >
                  <span className='absolute text-[12px] w-[5px] h-[5px] p-1 bg-forestyWarning rounded-full left-[8px] translate-y-[-50%] top-[50%] overflow-y-clip'></span>
                  {showCurrentTime && (
                    <span className='absolute text-[12px] p-1 bg-forestyWarning rounded-full left-[24px] translate-y-[-50%] top-[50%] overflow-y-clip z-50'>
                      {currentTime.time}
                    </span>
                  )}
                </div>
              )} */}
              {timeArr.map((item) => {
                return (
                  <React.Fragment key={v4()}>
                    <span className='flex justify-center items-center text-[14px] text-forestydark-100 h-[20px]'>
                      {`${item}:00`}
                    </span>
                    <span className='h-[40px] w-[13px] flex flex-col justify-evenly'>
                      <span className='h-[1px] w-full bg-forestydark-100 dark:bg-forestydark-300 rounded-full'></span>
                      <span className='h-[1px] w-full bg-forestydark-100 dark:bg-forestydark-300 rounded-full'></span>
                      <span className='h-[1px] w-full bg-forestydark-100 dark:bg-forestydark-300 rounded-full'></span>
                    </span>
                  </React.Fragment>
                );
              })}
              <span className='flex justify-center items-center text-forestydark-100 text-[14px] h-[20px]'>
                23:00
              </span>
              <span className='w-full bg-foresty-white dark:bg-forestydark-700 h-[30px]' />
            </div>
          </div>

          {roomsData && groupLessons ? (
            <div className='flex flex-col flex-auto relative z-10'>
              <div className='flex flex-row justify-between w-0 sticky top-0 bg-foresty-white dark:bg-forestydark-700 z-10'>
                {roomsData.map((item, index) => {
                  return (
                    <div key={v4()} className='flex flex-col'>
                      <div className='flex items-center min-w-[280px] gap-3 bg-foresty-white dark:bg-forestydark-700 text-fyTropic dark:text-foresty-white justify-center h-[50px] relative'>
                        {`${item.roomNumber} - xona`}

                        <span className='text-forestydark-100 text-[14px]'>
                          {item.count}
                        </span>
                        {index !== roomsData.length - 1 && (
                          <span className='absolute right-0 w-[1px] bg-forestydark-100 h-[20px] z-10' />
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className='flex flex-row w-0 h-[1120px]'>
                {roomsData.map((item, index) => {
                  return (
                    <div key={v4()} className='flex flex-col relative z-0'>
                      {/* {showTimeLine && (
                      <div
                        style={{ top: `${currentTime.top * 60 + 10}px` }}
                        className='absolute h-[1px] left-0 top-[10px] w-full bg-forestyWarning'
                      ></div>
                    )} */}
                      <div className='flex flex-col items-center min-w-[280px] gap-3 justify-center'>
                        {roomsCol.map(
                          (el, ind) =>
                            el.roomNumber == item.roomNumber && (
                              <div key={v4()} className='relative w-full'>
                                {el.lessonTimes.map((lessonTime) => (
                                  <div
                                    style={{
                                      marginTop: `${
                                        (lessonTime.fromTime - 5) * 60 + 10
                                      }px`,
                                      height: `${
                                        (lessonTime.toTime -
                                          lessonTime.fromTime) *
                                        60
                                      }px`,
                                    }}
                                    className='flex w-full px-2 absolute'
                                  >
                                    <div
                                      style={{
                                        backgroundColor:
                                          theme == "dark"
                                            ? lessonTime?.groupMonth?.group?.[
                                                lessonTime?.groupMonth?.group
                                                  ?.type
                                              ].color.hexCode + "4D"
                                            : lessonTime?.groupMonth?.group?.[
                                                lessonTime?.groupMonth?.group
                                                  ?.type
                                              ].color.hexCode + "1A",
                                      }}
                                      className='flex flex-row gap-3 items-start w-full rounded-xl p-3'
                                    >
                                      <span
                                        style={{
                                          backgroundColor:
                                            lessonTime?.groupMonth?.group?.[
                                              lessonTime?.groupMonth?.group
                                                ?.type
                                            ].color.hexCode,
                                        }}
                                        className='flex justify-center items-center w-[40px] h-[40px] text-[18px] rounded-xl text-foresty-white '
                                      >
                                        <FontAwesomeIcon
                                          icon={getIcon(
                                            lessonTime?.groupMonth?.group?.[
                                              lessonTime?.groupMonth?.group
                                                ?.type
                                            ].icon
                                          )}
                                        />
                                      </span>
                                      <div className='flex flex-col '>
                                        <span className='text-[14px] font-semibold text-fyTropic dark:text-foresty-white'>
                                          {lessonTime?.groupMonth?.group?.name}
                                        </span>
                                        <span className='text-[14px] font-bold text-fyTropic dark:text-foresty-white/80'>
                                          {getTime(lessonTime)}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <span>{"No data"}</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default CalendarPage;
