import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getHomeCountsForChart,
  getHomeDebtors,
  getHomePageData,
} from "./homeSlice";
import HomePageCard from "../../components/Home/homePageCard";
import {
  AcademicCapIcon,
  UserGroupIcon,
  UserIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import date from "date-and-time";

import { useTheme } from "next-themes";
import { Line } from "react-chartjs-2";
import { getOptions } from "../FinancePage/data/chartData";
import WarningBoard from "../../components/Home/warningBoard";
import { getAllGroupLessonsByDate } from "../CalendarPage/calendarSlice";
import TimetableBoard from "./timetableBoard";

const HomePage = () => {
  const { theme } = useTheme();

  const [chartData, setChartData] = useState(null);

  const {
    counts,
    chartData: countsChartData,
    debtors,
  } = useSelector((state) => state.home);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getHomePageData());
    dispatch(getHomeCountsForChart());
    dispatch(getHomeDebtors());
  }, []);

  useEffect(() => {
    if (countsChartData !== null) {
      const chartData = {
        labels: countsChartData?.map((entry) =>
          date.format(new Date(entry?.date), "DD MMM YYYY")
        ),
        datasets: [
          {
            label: "Xodimlar",
            data: countsChartData?.map((entry) => entry?.employees),
            borderColor: "rgb(126, 48, 225)",
            backgroundColor: "rgba(126, 48, 225,0.8)",
            borderRadius: 13,

            fill: false,
          },
          {
            label: "O'qituvchilar",
            data: countsChartData?.map((entry) => entry?.teachers),
            borderColor: "rgb(251, 139, 36)",
            backgroundColor: "rgba(251, 139, 36,0.8)",
            borderRadius: 13,

            fill: false,
          },
          {
            label: "O'quvchilar",
            data: countsChartData?.map((entry) => entry?.students),

            borderColor: "rgb(101, 183, 65)",
            backgroundColor: "rgba(101, 183, 65,0.8)",

            borderRadius: 13,

            fill: false,
          },
          {
            label: "Guruhlar",
            data: countsChartData?.map((entry) => entry?.groups),

            borderColor: "rgb(0, 169, 255)",
            backgroundColor: "rgba(0, 169, 255,0.8)",

            borderRadius: 13,

            fill: false,
          },
        ],
      };
      setChartData(chartData);
    }
  }, [countsChartData]);

  return (
    <div className='flex flex-row w-full h-fit p-3 gap-10'>
      <div className='flex flex-col grow gap-8'>
        <div className='flex flex-wrap w-fit gap-5'>
          <HomePageCard
            card={{
              title: "Xodimlar",
              count: counts?.employee,
              percentage: "+12.3%",
              icon: (
                <UserIcon
                  width={30}
                  className='stroke-[2px] stroke-[#cb62dd] bg-[#cb62dd]/30 p-[8px] rounded-xl'
                />
              ),
            }}
          />
          <HomePageCard
            card={{
              title: "O'qituvchilar",
              count: counts?.teacher,
              percentage: "+12.3%",
              icon: (
                <UsersIcon
                  width={30}
                  className='stroke-[2px] stroke-[#ffa200] bg-[#ffa200]/30 p-[8px] rounded-xl'
                />
              ),
            }}
          />
          <HomePageCard
            card={{
              title: "O'quvchilar",
              count: counts?.student,
              percentage: "+12.3%",
              icon: (
                <AcademicCapIcon
                  width={30}
                  className='stroke-[2px] stroke-[#04d100] bg-[#04d100]/30 p-[8px] rounded-xl'
                />
              ),
            }}
          />
          <HomePageCard
            card={{
              title: "Guruhlar",
              count: counts?.group,
              percentage: "+12.3%",
              icon: (
                <UserGroupIcon
                  width={30}
                  className='stroke-[2px] stroke-[#00acfb] bg-[#00acfb]/30 p-[8px] rounded-xl'
                />
              ),
            }}
          />
        </div>
        {chartData && (
          <div className='relative m-auto w-full bg-white dark:bg-neutral-800/80 p-5 rounded-3xl'>
            <Line
              className='w-full text-white'
              options={getOptions(theme)}
              data={chartData}
            />
          </div>
        )}
      </div>

      {/* <div className='grid grid-cols-2 items-start justify-center w-full gap-8 pr-8 '>
          <div className='flex flex-col gap-3 bg-foresty-white/60 dark:bg-forestydark-500/80 p-5 rounded-[30px]'>
            <span className='text-[16px] font-raleway font-bold ml-5 text-fyTropic dark:text-forestydark-100'>
              Top guruhlar
            </span>
            <div className='grid grid-cols-2 gap-5'>
              {topGroups &&
                topGroups.map((el, index) => (
                  <div
                    key={v4()}
                    style={{ gridColumn: index == 0 ? "2 span" : "1 span" }}
                    className='w-full h-[150px] bg-gradient-to-tr rounded-[20px] from-forestyWarning to-[#6762ff] p-[1px]'
                  >
                    <div className='relative flex flex-col justify-center items-start w-full h-full dark:bg-forestydark-500 bg-foresty-white rounded-[20px] px-8'>
                      <span className='absolute top-4 right-5 text-[12px] flex flex-row items-center gap-1 font-space font-bold'>
                        {el.studentsCount}
                        <FontAwesomeIcon
                          icon={faGraduationCap}
                          className='text-[14px] text-foresty-500'
                        />
                      </span>
                      <span
                        style={{ fontSize: index == 0 && "24px" }}
                        className='text-[16px] font-raleway font-bold'
                      >
                        {el?.name}
                      </span>

                      <span
                        style={{ fontSize: index == 0 && "16px" }}
                        className='text-[12px] font-raleway font-normal'
                      >
                        {el?.subject?.name}
                      </span>
                    </div>
                  </div>
                ))}
            </div>
          </div>

          <div className='flex flex-col gap-3 bg-foresty-white/60 dark:bg-forestydark-500/80 p-5 rounded-[30px]'>
            <span className='text-[16px] font-raleway font-bold ml-5 text-fyTropic dark:text-forestydark-100'>
              Top fanlar
            </span>
            <div className='grid grid-cols-2 gap-5 bg-foresty-white dark:bg-forestydark-400/50 p-5 rounded-[20px]'>
              <Doughnut data={data(topSubjects)} />
            </div>
          </div>
        </div>
      </div>
*/}
      <div className='flex flex-col w-[380px] gap-8'>
        {debtors?.student > 0 ||
        debtors?.teacher > 0 ||
        debtors?.employee > 0 ? (
          <WarningBoard data={debtors} />
        ) : null}
        <TimetableBoard />
      </div>
    </div>
  );
};

export default HomePage;
