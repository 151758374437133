import React, { useState } from "react";

export default function useSelect(initialData) {
  const [items, setItems] = useState(initialData);

  const changeSelection = (index) => {
    setItems(
      items.map((item, subIndex) => {
        subIndex === index
          ? (item.isSelected = true)
          : (item.isSelected = false);
        return item;
      })
    );
  };

  const getSelectedItem = () => {
    return items.find((el) => el.isSelected);
  };

  const clearSelection = () => {
    return items.map((el) => {
      el.isSelected = false;
      return el;
    });
  };

  return [items, setItems, changeSelection, getSelectedItem, clearSelection];
}
