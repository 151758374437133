import {
  faDoorClosed,
  faGraduationCap,
  faMoneyBill,
  faMoneyBills,
  faShapes,
  faUserGroup,
  faUsers,
  faUsersRectangle,
} from "@fortawesome/free-solid-svg-icons";

export function getActivityIcon(category) {
  if (category.includes("xodim")) {
    return faUserGroup;
  } else if (category.includes("o'qituvchi")) {
    return faUsers;
  } else if (category.includes("o'quvchi")) {
    return faGraduationCap;
  } else if (category.includes("guruh")) {
    return faUsersRectangle;
  } else if (category.includes("to'lov")) {
    return faMoneyBills;
  } else if (category.includes("xona")) {
    return faDoorClosed;
  } else if (category.includes("fan")) {
    return faShapes;
  } else if (category.includes("kurs")) {
    return faShapes;
  }
}
