import { getPhoneNumberPattern } from "../../utils/regex";
import date from "date-and-time";

export const getStatusStyle = (status) => {
  switch (status) {
    case "NEW":
      return "flex flex-col w-full px-5 py-8 items-center gap-5 bg-gradient-to-t from-[#C0F741]/10 dark:from-[#C0F741]/10";
    case "ACTIVE":
      return "flex flex-col w-full px-5 py-8 items-center gap-5 bg-gradient-to-t from-foresty-400/10 dark:from-[#3d653f]/20";

    case "FROZEN":
      return "flex flex-col w-full px-5 py-8 items-center gap-5 bg-gradient-to-t from-[#0085ff]/10 dark:from-[#0085ff]/20";

    case "BLOCKED":
      return "flex flex-col w-full px-5 py-8 items-center gap-5 bg-gradient-to-t from-[#fa3435]/10 dark:from-[#fa3435]/20";
  }
};

export const getUpdateInitialValues = (teacher = {}) => {
  const subjectsId = [];

  teacher?.subjects?.forEach((el) => {
    subjectsId.push(el.id);
  });

  return {
    firstName: teacher?.teacher?.user?.firstName,
    lastName: teacher?.teacher?.user?.lastName,
    fatherName: teacher?.teacher?.user?.fatherName,

    email: teacher?.teacher?.user?.email,
    phoneNumber: getPhoneNumberPattern(teacher?.teacher?.user?.phoneNumber),
    birthDate: date.format(
      new Date(teacher?.teacher?.user?.birthDate),
      "YYYY-MM-DD"
    ),
    subjectsId,
  };
};
