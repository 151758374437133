import * as Yup from "yup";

export const createFields = [
  {
    name: "firstName",
    type: "text",
    label: "Ismi",
    placeholder: "Ismini kiriting...",
    isRequired: true,
  },
  {
    name: "lastName",
    type: "text",
    label: "Familiyasi",
    placeholder: "Familiyasini kiriting...",
    isRequired: true,
  },
  {
    name: "fatherName",
    type: "text",
    label: "Otasining ismi",
    placeholder: "Otasining ismini kiriting...",
    isRequired: true,
  },

  {
    name: "phoneNumber",
    type: "text",
    label: "Tel. raqami",
    // placeholder: "O'quv markaz tel. raqamini kiriting...",
    isRequired: true,
    startContent: "+998",
  },

  {
    name: "parentPhoneNumber",
    type: "text",
    label: "Otasi/Onasi ning tel. raqami",
    // placeholder: "O'quv markaz tel. raqamini kiriting...",
    isRequired: true,
    startContent: "+998",
  },

  {
    name: "secondaryParentPhoneNumber",
    type: "text",
    label: "Otasi/Onasi ning tel. raqami 2",
    // placeholder: "O'quv markaz tel. raqamini kiriting...",
    startContent: "+998",
  },

  {
    name: "birthDate",
    type: "date",
    label: "Tug'ilgan kuni",
    placeholder: "Tug'ilgan kunini kiriting...",
    isRequired: true,
  },
  {
    name: "email",
    type: "text",
    label: "Emaili",
    placeholder: "Emailini kiriting...",
  },
];

export const emptyValues = {
  firstName: "",
  lastName: "",
  fatherName: "",

  email: "",
  phoneNumber: "",
  birthDate: "",
  parentPhoneNumber: "",
  secondaryParentPhoneNumber: "",
};

export const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("Bo'sh bo'lmasligi kerak!"),
  lastName: Yup.string().required("Bo'sh bo'lmasligi kerak!"),
  fatherName: Yup.string().required("Bo'sh bo'lmasligi kerak!"),

  email: Yup.string().email("Yaroqsiz email!"),
  phoneNumber: Yup.string()
    .matches(/^\(\d{2}\) \d{3}-\d{2}-\d{2}$/, "Tel. raqamni to'g'ri kiriting!")
    .required("Bo'sh bo'lmasligi kerak!"),
  parentPhoneNumber: Yup.string()
    .matches(/^\(\d{2}\) \d{3}-\d{2}-\d{2}$/, "Tel. raqamni to'g'ri kiriting!")
    .required("Bo'sh bo'lmasligi kerak!"),
  secondaryParentPhoneNumber: Yup.string().matches(
    /^\(\d{2}\) \d{3}-\d{2}-\d{2}$/,
    "Tel. raqamni to'g'ri kiriting!"
  ),
  birthDate: Yup.string().required("Bo'sh bo'lmasligi kerak!"),
});

export const columns = [
  { name: "ID", uid: "rowOrder", sortable: true },
  { name: "ISMI", uid: "student.user.firstName", sortable: true },
  { name: "FAMILIYA", uid: "student.user.lastName", sortable: true },
  { name: "OTASINING ISMI", uid: "student.user.fatherName", sortable: true },
  { name: "Status", uid: "status", sortable: true },
  { name: "TEL. RAQAM", uid: "student.user.phoneNumber", sortable: true },
  { name: "OTASI/ONASI TEL. RAQAM", uid: "parentPhoneNumber", sortable: true },
  {
    name: "OTASI/ONASI TEL. RAQAM 2",
    uid: "secondaryParentPhoneNumber",
    sortable: true,
  },

  { name: "SANASI", uid: "created_at", sortable: true },
  // { name: "STATUS", uid: "status", sortable: true },
  { name: "ACTIONS", uid: "actions" },
];

export const INITIAL_VISIBLE_COLUMNS = [
  "rowOrder",
  "status",
  "student.user.firstName",
  "student.user.lastName",
  "student.user.fatherName",
  "student.user.phoneNumber",
  "parentPhoneNumber",

  "created_at",
  "actions",
];

export const searchIndexes = [
  ["student", "user", "firstName"],
  ["student", "user", "lastName"],
  ["student", "user", "fatherName"],
  ["student", "user", "phoneNumber"],

  "parentPhoneNumber",
  "secondaryParentPhoneNumber",
];
