import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSentMessages } from "../smsSlice";
import ForestyTable from "../../../components/Table/ForestyTable";
import {
  INITIAL_VISIBLE_COLUMNS,
  columns,
  searchIndexes,
} from "./data/sentMsgTable";
import {
  Avatar,
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
  ScrollShadow,
} from "@nextui-org/react";
import { getProfileAvatar } from "../../../utils/profile";
import { getProviderLogo } from "../SendSmsPage/data/provider";
import { getPhoneNumberPattern } from "../../../utils/regex";

const SmsMessagesPage = () => {
  const dispatch = useDispatch();
  const { sentMessages } = useSelector((state) => state.sms);

  useEffect(() => {
    dispatch(getSentMessages());
  }, []);

  return (
    <div>
      {sentMessages && (
        <ForestyTable
          isStriped
          searchIndexes={searchIndexes}
          // viewButton={(id) => (
          //   <NavigateViewButton isLight path={`/students/${id}`} />
          // )}
          extraButton={(data) => (
            <Popover placement='left'>
              <PopoverTrigger>
                <Button color='primary' variant='flat' size='sm'>
                  Qabul qiluvchilar
                </Button>
              </PopoverTrigger>
              <PopoverContent className='rounded-[30px]'>
                <ScrollShadow
                  size={40}
                  className='overflow-scroll scrollbar-hide flex flex-col w-full gap-3 max-h-[300px] bg-neutral-200 dark:bg-neutral-800 p-2 rounded-3xl'
                >
                  {data?.sentMessageDetail.length > 0 ? (
                    data?.sentMessageDetail &&
                    data?.sentMessageDetail.map((u) => {
                      let user = u?.user;
                      return (
                        <div className='flex flex-row justify-between gap-3 items-center bg-white dark:bg-neutral-700/50 w-full rounded-2xl p-3'>
                          <div className='flex flex-row items-center gap-3 w-full'>
                            <Avatar
                              src={getProfileAvatar(user?.profilePhoto)}
                              className='w-[40px] h-[40px]'
                            />
                            <div className='flex flex-col'>
                              <span className='font-normal text-[13px] truncate w-[150px]'>{`${user?.user?.lastName} ${user?.user?.firstName} ${user?.user?.fatherName}`}</span>
                              <span className='font-normal text-[13px] text-orange-500'>{`${getPhoneNumberPattern(
                                user?.user?.phoneNumber
                              )}`}</span>
                            </div>
                          </div>
                          <img
                            className='w-[45px] mr-2 p-1 bg-white rounded-md object-cover'
                            src={getProviderLogo(user?.user?.phoneNumber)}
                          />
                        </div>
                      );
                    })
                  ) : (
                    <span className='text-[14px] flex justify-center items-center'>
                      {"No data."}
                    </span>
                  )}
                </ScrollShadow>
              </PopoverContent>
            </Popover>
          )}
          tableData={sentMessages}
          columns={columns}
          initialVisibleColumns={INITIAL_VISIBLE_COLUMNS}
          editButton={null}
          deleteButton={null}
        />
      )}
    </div>
  );
};

export default SmsMessagesPage;
