import {
  Avatar,
  Badge,
  Button,
  Chip,
  ScrollShadow,
  Select,
  SelectItem,
  Tab,
  Tabs,
  Textarea,
} from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import { TABS, tabs } from "./data/tabs";
import { useDispatch, useSelector } from "react-redux";
import { getAllGroups } from "../../Groups/groupsSlice";
import {
  getEmployees,
  getStudents,
  getTeachers,
  getUsersByGroup,
  resetUsers,
  sendMessages,
} from "../smsSlice";
import ForestyTable from "../../../components/Table/ForestyTable";
import {
  INITIAL_VISIBLE_COLUMNS,
  columns,
  searchIndexes,
} from "./data/usersTable";
import { getProfileAvatar } from "../../../utils/profile";
import { getPhoneNumberPattern } from "../../../utils/regex";
import { getProviderLogo } from "./data/provider";
import { PaperAirplaneIcon } from "@heroicons/react/24/solid";

const SendSmsPage = () => {
  const dispatch = useDispatch();
  const { groups } = useSelector((state) => state.groups);
  const { users, sendMsgLoading } = useSelector((state) => state.sms);

  const [group, setGroup] = React.useState(new Set([]));
  const [message, setMessage] = React.useState("");

  const [selected, setSelected] = useState(TABS.GROUPS);
  const [selectedUsers, setSelectedUsers] = useState([]);

  useEffect(() => {
    dispatch(getAllGroups());
  }, []);

  useEffect(() => {
    if (Array.from(group)[0]) {
      dispatch(getUsersByGroup(Array.from(group)[0]));
    } else {
      dispatch(resetUsers());
    }
  }, [group]);

  useEffect(() => {
    dispatch(resetUsers());
    setSelectedUsers([]);

    switch (selected) {
      case TABS.EMPLOYEES:
        dispatch(getEmployees());
        break;
      case TABS.TEACHERS:
        dispatch(getTeachers());
        break;
      case TABS.STUDENTS:
        dispatch(getStudents());
        break;
    }
  }, [selected]);

  return (
    <div className='flex flex-col w-full'>
      <div className='grid grid-cols-3 gap-8'>
        <div className='col-span-2 flex flex-col gap-5 items-center bg-white dark:bg-neutral-800/50 p-3 px-8 rounded-3xl'>
          <Tabs
            selectedKey={selected}
            onSelectionChange={(key) => {
              setSelected(key);
            }}
            size='md'
            radius='full'
            aria-label='Tabs sizes'
          >
            {tabs.map((tab) => (
              <Tab title={tab.title} key={tab.key} />
            ))}
          </Tabs>
          {selected == TABS.GROUPS && (
            <Select
              label='Guruh'
              variant='bordered'
              placeholder='Guruhni tanlang'
              selectedKeys={group}
              className='w-[80%]'
              onSelectionChange={setGroup}
            >
              {groups &&
                groups.map((gr) => (
                  <SelectItem key={gr?.groupData?.id} value={gr?.groupData?.id}>
                    {gr?.groupData?.name}
                  </SelectItem>
                ))}
            </Select>
          )}
          {users && (
            <ForestyTable
              isSelection
              handleSelectionChange={setSelectedUsers}
              searchIndexes={searchIndexes}
              tableData={users}
              columns={columns}
              initialVisibleColumns={INITIAL_VISIBLE_COLUMNS}
              editButton={null}
              deleteButton={null}
            />
          )}
        </div>
        <div className='flex flex-col bg-white dark:bg-neutral-800/50 p-3 rounded-3xl'>
          {" "}
          <Textarea
            value={message}
            onValueChange={setMessage}
            variant='bordered'
            label='Xabar matni'
            placeholder='Xabar matnini kiriting...'
            className='w-full'
            minRows={3}
            maxRows={10}
            classNames={
              {
                // input: "resize-y min-h-[150px]",
              }
            }
          />
          <div className='mt-4 ml-5'>
            <Badge content={selectedUsers.length} color='primary'>
              <div className='text-[16px] font-bold w-fit mr-3'>
                {"Qabul qiluvchilar"}
              </div>
            </Badge>
          </div>
          <div className='flex flex-col gap-2 mt-2 bg-neutral-200 dark:bg-neutral-800 p-2 rounded-3xl'>
            {" "}
            <ScrollShadow
              size={40}
              className='overflow-scroll scrollbar-hide flex flex-col w-full gap-3 max-h-[300px]'
            >
              {selectedUsers.length > 0 ? (
                users &&
                selectedUsers.map((u) => {
                  let user = users.find((el) => el.id == u);
                  return (
                    <div className='flex flex-row justify-between gap-3 items-center bg-white dark:bg-neutral-700/50 w-full rounded-2xl p-3'>
                      <div className='flex flex-row items-center gap-3 w-full'>
                        <Avatar
                          src={getProfileAvatar(user?.profilePhoto)}
                          className='w-[40px] h-[40px]'
                        />
                        <div className='flex flex-col'>
                          <span className='font-normal text-[13px] truncate w-[150px]'>{`${user?.user?.lastName} ${user?.user?.firstName} ${user?.user?.fatherName}`}</span>
                          <span className='font-normal text-[13px] text-orange-500'>{`${getPhoneNumberPattern(
                            user?.user?.phoneNumber
                          )}`}</span>
                        </div>
                      </div>
                      <img
                        className='w-[45px] mr-2 p-1 bg-white rounded-md object-cover'
                        src={getProviderLogo(user?.user?.phoneNumber)}
                      />
                    </div>
                  );
                })
              ) : (
                <span className='text-[14px] flex justify-center items-center'>
                  {"No data."}
                </span>
              )}
            </ScrollShadow>
          </div>
          <div className='flex flex-row justify-end mt-6'>
            <Button
              isLoading={sendMsgLoading}
              onPress={() =>
                dispatch(
                  sendMessages({
                    data: {
                      messageText: message,
                      userIds: selectedUsers,
                    },
                  })
                )
              }
              disabled={message === "" || selectedUsers.length === 0}
              color={
                message === "" || selectedUsers.length === 0
                  ? "default"
                  : "primary"
              }
              endContent={<PaperAirplaneIcon className='w-[20px]' />}
            >
              {"Jo'natish"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendSmsPage;
