import React, { useEffect } from "react";
import ForestyTable from "../../components/Table/ForestyTable";
import { useDispatch, useSelector } from "react-redux";
import { getAllBalancePayment } from "../FinancePage/financeSlice";
import {
  INITIAL_VISIBLE_COLUMNS,
  columns,
  searchIndexes,
} from "./data/paymentsTable";

const PaymentsPage = () => {
  const { balancePayments } = useSelector((state) => state.finance);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllBalancePayment());
  }, []);
  return (
    <div className='dark:bg-neutral-800/50 bg-white/80 p-3 rounded-[30px]'>
      {" "}
      {balancePayments && (
        <ForestyTable
          isStriped
          //   extraButton={(student) => (
          //     <RemoveStudentFromGroupModal
          //       handleSubmit={(onClose) =>
          //         dispatch(removeStudentFromGroup({ id: student?.id, onClose }))
          //       }
          //       contextText={`${student["student.user.lastName"]} ${student["student.user.firstName"]} ${student["student.user.fatherName"]}`}
          //       button={
          //         <LeaveButton disabled={student.status === "LEAVED"} isLight />
          //       }
          //     />
          //   )}
          searchIndexes={searchIndexes}
          tableData={balancePayments}
          columns={columns}
          initialVisibleColumns={INITIAL_VISIBLE_COLUMNS}
          editButton={null}
          deleteButton={null}
        />
      )}
    </div>
  );
};

export default PaymentsPage;
