import React, { useState } from "react";

export default function useLessonTime(initialValue) {
  const [times, setTimes] = useState(initialValue);

  const addTime = () => {
    if (times.length > 7) return;
    setTimes([...times, { ...times[times.length - 1] }]);
  };

  const updateFieldChanged = (index, propertyName, val) => {
    if (propertyName === "day") {
      if (val < 0 || val > 6) return;
    }
    if (propertyName === "fromHour" || propertyName === "toHour") {
      if (val < 5 || val > 23) return;
    }
    if (propertyName === "fromMinute" || propertyName === "toMinute") {
      if (val < 0 || val > 11) return;
    }

    let newArr = [...times];

    if (propertyName === "fromHour") {
      if (val === 23) return;
      if (newArr[index]["toHour"] <= val) newArr[index]["toHour"] = val + 1;
    }

    if (propertyName === "toHour") {
      if (val === 5) return;
      if (newArr[index]["fromHour"] >= val) newArr[index]["fromHour"] = val - 1;
    }

    newArr[index][propertyName] = val;

    setTimes(newArr);
  };

  const deleteRow = (index) => {
    const tempArr = [...times];
    tempArr.splice(index, 1);
    setTimes([...tempArr]);
  };

  const clearTimes = () => {
    setTimes([
      {
        day: 0,
        fromHour: 5,
        fromMinute: 0,
        toHour: 6,
        toMinute: 0,
      },
    ]);
  };

  return [times, addTime, deleteRow, updateFieldChanged, clearTimes, setTimes];
}
