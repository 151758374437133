import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash";
import Api from "../../config/Api";
import { toast } from "react-toastify";

export const getAllSubjects = createAsyncThunk(
  "subjects/getAll",
  async (body = {}) => {
    const response = await Api.get("/subject");
    return response.data;
  }
);

export const createSubject = createAsyncThunk(
  "subjects/create",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.post("/subject", body);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSubject = createAsyncThunk(
  "subjects/update",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.put(`/subject/${body?.id}`, body);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSubject = createAsyncThunk(
  "subjects/delete",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.delete(`/subject/${body?.id}`);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const subjectsSlice = createSlice({
  name: "subjects",
  initialState: {
    subjects: null,
    floors: null,
    loading: false,
    error: null,
  },
  reducers: {
    resetError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      ///------------ GET subjects ------------------/////
      .addCase(getAllSubjects.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllSubjects.fulfilled, (state, action) => {
        state.loading = false;
        state.subjects = action.payload?.data;
      })
      .addCase(getAllSubjects.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ CREATE subjects ------------------/////
      .addCase(createSubject.pending, (state) => {
        state.loading = true;
      })
      .addCase(createSubject.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.subjects = [payload?.data, ...state.subjects];
        toast.success(payload?.message);
      })
      .addCase(createSubject.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        toast.error(payload?.message);
      })

      ///------------ delete subjects ------------------/////
      .addCase(deleteSubject.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteSubject.fulfilled, (state, { payload }) => {
        state.loading = false;

        const ctgIndex = findIndex(state.subjects, { id: payload?.data?.id });
        state.subjects.splice(ctgIndex, 1);
        toast.success(payload?.message);
      })
      .addCase(deleteSubject.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        toast.error(payload?.message);
      })

      ///------------ UPDATE subjects ------------------/////
      .addCase(updateSubject.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateSubject.fulfilled, (state, { payload }) => {
        state.loading = false;

        const ctgIndex = findIndex(state.subjects, { id: payload?.data?.id });
        state.subjects[ctgIndex] = payload?.data;
        toast.success(payload?.message);
      })
      .addCase(updateSubject.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        toast.error(payload?.message);
      });
  },
});

export const { resetError } = subjectsSlice.actions;

export default subjectsSlice.reducer;
