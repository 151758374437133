import { Spinner, Tab, Tabs } from "@nextui-org/react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { tabs } from "./data/tabs";
import SettingsMainPage from "./SettingsMainPage";
import PaymentsPage from "./PaymentsPage";
import SettingsUpdatePage from "./SettingsUpdatePage";
import { getSocailLinks } from "../ProfilePage/profileSlice";
import SmsServicePage from "./SmsServicePage";

const SettingsPage = () => {
  const dispatch = useDispatch();
  const [selected, setSelected] = React.useState(
    localStorage.getItem("group-menu") ?? "details"
  );

  const { user, loading } = useSelector((state) => state.profile);

  useEffect(() => {
    dispatch(getSocailLinks());
  }, []);

  return loading ? (
    <Spinner />
  ) : (
    <div className='flex flex-col w-full items-center gap-5'>
      <div className='grid grid-cols-3 w-full'>
        <div className='w-fit'></div>
        <div className='w-full flex justify-center items-center'>
          <Tabs
            selectedKey={selected}
            onSelectionChange={(key) => {
              localStorage.setItem("group-menu", key);
              setSelected(key);
            }}
            size='md'
            radius='full'
            aria-label='Tabs sizes'
          >
            {tabs.map((tab) => (
              <Tab title={tab.title} key={tab.key} />
            ))}
          </Tabs>
        </div>
      </div>
      <div className='bg-white/30 dark:bg-neutral-900 rounded-3xl p-8 w-full'>
        {selected === "details" ? (
          <SettingsMainPage eduCenter={user?.educationCenter} />
        ) : selected === "payments" ? (
          <PaymentsPage user={user} />
        ) : selected === "sms" ? (
          <SmsServicePage user={user} />
        ) : selected === "settings" ? (
          <SettingsUpdatePage eduCenter={user?.educationCenter} />
        ) : null}
      </div>
    </div>
  );
};

export default SettingsPage;
