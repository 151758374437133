import React from "react";

const Logo = ({ color, height }) => {
  return (
    <svg
      height={height}
      version='1.1'
      id='Layer_1'
      x='0px'
      y='0px'
      viewBox='0 0 290 80'
      fill={color}
      className='dark:fill-[#5bc488]'
    >
      <g class='st0'>
        <path
          class='st1'
          d='M120.78,64.62v-0.54l0.7-0.16c0.69-0.18,1.23-0.5,1.63-0.95c0.4-0.45,0.6-1.04,0.6-1.76
		c0-1.34,0.01-2.66,0.03-3.98c0.02-1.32,0.03-2.65,0.03-3.98v-13.6h-3.03v-0.6l1.19-0.22c0.72-0.07,1.25-0.33,1.57-0.76
		s0.56-1.03,0.7-1.79c0.36-1.41,0.89-2.65,1.6-3.71s1.51-2.05,2.41-2.95c1.37-1.3,2.83-2.21,4.36-2.74
		c1.54-0.52,3.04-0.79,4.53-0.79c1.44,0,2.76,0.3,3.96,0.89c1.19,0.6,1.79,1.58,1.79,2.95c0,0.98-0.29,1.74-0.87,2.3
		c-0.58,0.56-1.39,0.84-2.44,0.84c-0.87,0-1.66-0.23-2.38-0.7c-0.72-0.47-1.43-1.3-2.11-2.49l-0.76-0.92
		c-0.4-0.5-0.79-0.75-1.19-0.73c-0.4,0.02-0.69,0.32-0.87,0.89c-0.25,1.26-0.44,2.75-0.57,4.44c-0.13,1.7-0.19,3.36-0.19,4.99h6.07
		v1.08h-6.07V61.1c0,1.37,0.72,2.26,2.17,2.66l1.35,0.33v0.54H120.78z'
        />
        <path
          class='st1'
          d='M152.32,65.44c-2.75,0-5.13-0.57-7.15-1.71c-2.02-1.14-3.59-2.75-4.69-4.85c-1.1-2.09-1.65-4.55-1.65-7.37
		c0-2.82,0.59-5.26,1.76-7.32c1.17-2.06,2.78-3.65,4.82-4.77c2.04-1.12,4.34-1.68,6.91-1.68s4.86,0.55,6.88,1.65
		c2.02,1.1,3.62,2.68,4.8,4.74c1.17,2.06,1.76,4.52,1.76,7.37c0,2.85-0.55,5.32-1.65,7.4c-1.1,2.08-2.66,3.69-4.66,4.82
		C157.45,64.87,155.07,65.44,152.32,65.44z M152.32,64.35c1.26,0,2.28-0.36,3.04-1.08s1.31-2.01,1.65-3.85
		c0.34-1.84,0.51-4.44,0.51-7.8c0-3.4-0.17-6.02-0.51-7.86c-0.34-1.84-0.89-3.12-1.65-3.85c-0.76-0.72-1.77-1.08-3.04-1.08
		s-2.29,0.36-3.06,1.08c-0.78,0.72-1.34,2-1.68,3.85c-0.34,1.84-0.51,4.46-0.51,7.86c0,3.36,0.17,5.96,0.51,7.8
		c0.34,1.84,0.9,3.12,1.68,3.85C150.04,63.99,151.06,64.35,152.32,64.35z'
        />
        <path
          class='st1'
          d='M168.15,64.62v-0.54l0.81-0.22c0.72-0.22,1.22-0.54,1.49-0.98c0.27-0.43,0.41-0.99,0.41-1.68V44.25
		c0-0.79-0.14-1.38-0.41-1.76c-0.27-0.38-0.77-0.64-1.49-0.79l-0.81-0.22v-0.54l9.32-3.14l0.54,0.54L178.5,43v0.43
		c0.51-1.01,1.17-1.94,1.98-2.79c0.81-0.85,1.72-1.54,2.71-2.09c0.99-0.54,1.98-0.81,2.95-0.81c1.37,0,2.42,0.38,3.14,1.14
		s1.08,1.72,1.08,2.87c0,1.23-0.35,2.18-1.06,2.84c-0.7,0.67-1.54,1-2.52,1c-1.52,0-2.85-0.76-4.01-2.28l-0.11-0.11
		c-0.36-0.5-0.78-0.79-1.25-0.84c-0.47-0.05-0.9,0.17-1.3,0.68c-0.36,0.33-0.66,0.71-0.89,1.17c-0.24,0.45-0.46,0.97-0.68,1.54
		v15.12c0,1.45,0.63,2.35,1.9,2.71l1.73,0.49v0.54H168.15z'
        />
        <path
          class='st1'
          d='M204.78,37.74c2.35,0,4.35,0.48,6.02,1.44c1.66,0.96,2.93,2.26,3.82,3.9c0.89,1.64,1.33,3.51,1.33,5.61
		c0,0.4-0.02,0.8-0.05,1.22c-0.04,0.42-0.11,0.79-0.22,1.11h-16.15c0.04,3.9,0.73,6.71,2.09,8.43c1.35,1.72,3.37,2.57,6.04,2.57
		c1.81,0,3.27-0.29,4.39-0.87c1.12-0.58,2.17-1.43,3.14-2.55l0.54,0.49c-1.12,1.99-2.61,3.54-4.47,4.66s-4.09,1.68-6.69,1.68
		c-2.57,0-4.84-0.55-6.83-1.65c-1.99-1.1-3.54-2.68-4.66-4.74s-1.68-4.52-1.68-7.37c0-2.96,0.65-5.48,1.95-7.56
		c1.3-2.08,2.97-3.66,5.01-4.74C200.4,38.29,202.54,37.74,204.78,37.74z M204.62,38.83c-1.05,0-1.94,0.32-2.68,0.95
		c-0.74,0.63-1.32,1.75-1.73,3.36c-0.42,1.61-0.64,3.88-0.68,6.8h9.27c0.36-3.94,0.22-6.77-0.43-8.51
		C207.71,39.69,206.46,38.83,204.62,38.83z'
        />
        <path
          class='st1'
          d='M228.03,65.44c-1.77,0-3.36-0.21-4.77-0.62s-2.71-0.93-3.9-1.54l0.05-6.45h0.54l1.84,3.25
		c0.79,1.45,1.67,2.52,2.63,3.22c0.96,0.71,2.18,1.06,3.66,1.06c1.73,0,3.13-0.39,4.2-1.17c1.07-0.78,1.6-1.82,1.6-3.12
		c0-1.19-0.38-2.17-1.14-2.93s-2.17-1.44-4.23-2.06l-2.28-0.7c-2.2-0.65-3.94-1.66-5.2-3.04c-1.27-1.37-1.9-3.05-1.9-5.04
		c0-2.38,0.94-4.41,2.82-6.07c1.88-1.66,4.57-2.49,8.07-2.49c1.41,0,2.74,0.15,3.98,0.46c1.25,0.31,2.45,0.77,3.6,1.38l-0.32,5.85
		h-0.54l-1.9-3.36c-0.69-1.19-1.36-2.03-2.03-2.52c-0.67-0.49-1.67-0.73-3.01-0.73c-1.23,0-2.38,0.34-3.47,1.03
		s-1.63,1.66-1.63,2.93c0,1.23,0.46,2.2,1.38,2.93c0.92,0.72,2.29,1.39,4.09,2l2.44,0.71c2.6,0.79,4.46,1.89,5.58,3.28
		c1.12,1.39,1.68,3.06,1.68,5.01c0,2.64-1.04,4.75-3.12,6.34C234.71,64.64,231.79,65.44,228.03,65.44z'
        />
        <path
          class='st1'
          d='M252.58,65.44c-2.31,0-4.11-0.58-5.39-1.73c-1.28-1.16-1.92-3-1.92-5.53V39.64h-3.74V39.1l1.3-0.22
		c1.12-0.25,2.09-0.61,2.9-1.08c0.81-0.47,1.62-1.1,2.41-1.9l4.44-4.93h0.54l-0.16,7.59h6.07v1.08h-6.12v19.51
		c0,1.27,0.28,2.22,0.84,2.87c0.56,0.65,1.27,0.98,2.14,0.98c0.76,0,1.43-0.19,2-0.57c0.58-0.38,1.16-0.89,1.73-1.54l0.54,0.54
		c-0.72,1.23-1.72,2.2-2.98,2.93C255.92,65.07,254.38,65.44,252.58,65.44z'
        />
        <path
          class='st1'
          d='M266.99,77.63c-1.45,0-2.59-0.36-3.44-1.08c-0.85-0.72-1.27-1.64-1.27-2.76c0-1.01,0.36-1.82,1.08-2.44
		s1.54-0.92,2.44-0.92s1.66,0.19,2.28,0.57c0.61,0.38,1.16,0.93,1.63,1.65l0.38,0.43c0.79,1.08,1.59,1.07,2.38-0.05
		c0.33-0.54,0.7-1.25,1.11-2.14c0.42-0.89,0.89-2.07,1.44-3.55L264.5,42.19c-0.25-0.69-0.52-1.24-0.81-1.65
		c-0.29-0.42-0.76-0.77-1.41-1.06l-0.98-0.38v-0.54h13.49v0.49l-1.08,0.43c-0.79,0.25-1.2,0.66-1.22,1.22
		c-0.02,0.56,0.12,1.26,0.41,2.09l5.64,14.63l5.37-14.79c0.29-0.83,0.4-1.53,0.33-2.09c-0.07-0.56-0.52-0.95-1.35-1.17l-1.14-0.27
		v-0.54h7.53v0.54l-1.19,0.38c-0.83,0.25-1.41,0.62-1.73,1.08c-0.33,0.47-0.63,1.12-0.92,1.95l-8.72,23.79
		c-1.41,3.79-2.82,6.63-4.23,8.51C271.06,76.69,269.23,77.63,266.99,77.63z'
        />
      </g>
      <polygon
        class='st1'
        points='100.84,79.92 91.59,63.9 95.55,63.9 86.44,48.12 90.27,48.12 82.24,34.21 84.99,34.21 74.43,15.92 
	66.37,29.32 73.91,43.13 69.28,42.01 80.51,62.54 76.07,61.25 81.03,71.87 72.2,58.02 76.84,59.98 70.65,49.26 65.77,39.29 
	70.55,40.41 60.19,22.87 63.63,22.87 50.43,0 37.23,22.87 40.67,22.87 36.19,30.63 41.26,38.98 38.03,38.5 46.22,52.19 41.75,51.87 
	47.55,64.79 38.44,49.79 43.39,50.27 34.24,36.58 36.99,36.58 26.43,19.2 15.87,36.58 18.62,36.58 10.59,49.79 14.42,49.79 
	5.31,64.79 9.28,64.79 0.03,80 47.64,80 52.84,80 88.24,80 86.16,79.92 '
      />
    </svg>
  );
};

export default Logo;
