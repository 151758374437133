import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEmployeePayments, paySalary } from "../employeeSlice";
import ForestyTable from "../../../components/Table/ForestyTable";
import {
  INITIAL_VISIBLE_COLUMNS,
  columns,
  searchIndexes,
} from "./data/paymentsTable";
import CreateEditModal from "../../../components/Modal/CreateEditModal";
import { RecievePaymentButton } from "../../../components/Buttons/CRUDbuttons";
import { emptyValues, fields, validationSchema } from "./data/paymentForm";

const EmployeePaymentsPage = ({ employee }) => {
  const { payments, paymentsLoading } = useSelector((state) => state.employees);

  const dispatch = useDispatch();

  useEffect(() => {
    employee && dispatch(getEmployeePayments(employee?.id));
  }, []);
  return (
    <div>
      {payments && (
        <div className='bg-white p-5 rounded-3xl dark:bg-neutral-800/50'>
          <ForestyTable
            isStriped
            extraButton={(employeePayment) => (
              <CreateEditModal
                // loading={paymentLoading}
                button={
                  <RecievePaymentButton
                    isLight
                    disabled={employeePayment.status === "PAID"}
                  />
                }
                title={"To'lov qilish"}
                // loading={loading}

                fields={fields.map((el) => {
                  if (el.name === "type") {
                    el.selectData = [
                      { id: "CASH", name: "Naqd pul" },
                      { id: "CARD", name: "Karta orqali" },
                    ];
                    return el;
                  }
                  return el;
                })}
                initialValues={emptyValues}
                validationSchema={validationSchema(
                  1000,
                  employeePayment.debtAmount
                )}
                submitBtnText={"To'lov qilish"}
                handleSubmit={(body) =>
                  dispatch(
                    paySalary({
                      data: {
                        ...body,
                        employeePaymentId: employeePayment.id,
                      },
                    })
                  )
                }
              />
            )}
            dataLoading={paymentsLoading}
            searchIndexes={searchIndexes}
            //   viewButton={(id) => (
            //     <NavigateViewButton isLight path={`/students/${id}`} />
            //   )}

            tableData={payments}
            columns={columns}
            initialVisibleColumns={INITIAL_VISIBLE_COLUMNS}
            editButton={null}
            deleteButton={null}
          />
        </div>
      )}
    </div>
  );
};

export default EmployeePaymentsPage;
