export function sortLessonTimesByRoom(lessonTimes) {
  let arr = [...lessonTimes];

  let newArr = [];
  let willCutArr = [];
  let sortedLessonTimes = [];
  for (let i = 0; i <= arr.length - 1; i++) {
    for (let j = i + 1; j < arr.length; j++) {
      if (
        arr[i].groupMonth?.group?.room?.roomNumber ==
        arr[j].groupMonth?.group?.room?.roomNumber
      ) {
        sortedLessonTimes.push(arr[j]);

        willCutArr.push(j);
      }
    }
    for (var k = willCutArr.length - 1; k >= 0; k--)
      arr.splice(willCutArr[k], 1);
    willCutArr = [];
    sortedLessonTimes.push(arr[i]);
    newArr.push({
      roomNumber: arr[i].groupMonth?.group?.room?.roomNumber,
      lessonTimes: sortedLessonTimes,
    });
    sortedLessonTimes = [];
  }
  return [...newArr];
}

export const getTime = (lessonTime) => {
  return `${lessonTime?.fromHour}:${lessonTime?.fromMinute?.minute} - ${lessonTime?.toHour}:${lessonTime?.toMinute?.minute}`;
};
