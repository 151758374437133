import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash";
import Api from "../../config/Api";
import { toast } from "react-toastify";

export const getSentMessages = createAsyncThunk(
  "sms/getSentMessages",
  async (body = {}) => {
    const response = await Api.get("/sms/sentMessages");
    return response.data;
  }
);

export const getEskizUser = createAsyncThunk(
  "sms/getSmsEskizUser",
  async (body = {}) => {
    const response = await Api.get("/eduCenter/smsEskizUser");
    return response.data;
  }
);

export const getUsersByGroup = createAsyncThunk(
  "sms/getUsersByGroup",
  async (body = {}) => {
    const response = await Api.get(`/sms/users/byGroup/${body}`);
    return response.data;
  }
);

export const getTeachers = createAsyncThunk(
  "sms/getTeachers",
  async (body = {}) => {
    const response = await Api.get(`/sms/users/teachers`);
    return response.data;
  }
);
export const getStudents = createAsyncThunk(
  "sms/getStudents",
  async (body = {}) => {
    const response = await Api.get(`/sms/users/students`);
    return response.data;
  }
);
export const getEmployees = createAsyncThunk(
  "sms/getEmployees",
  async (body = {}) => {
    const response = await Api.get(`/sms/users/employees`);
    return response.data;
  }
);

export const sendMessages = createAsyncThunk(
  "sms/sendMessages",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.post("/sms/sendMessages", body?.data);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const smsSlice = createSlice({
  name: "sms",
  initialState: {
    smsEskizUser: null,
    users: [],
    sentMessages: [],

    usersLoading: false,
    getUserloading: false,
    sendMsgLoading: false,
    error: null,
  },
  reducers: {
    resetError: (state) => {
      state.error = null;
    },
    resetUsers: (state) => {
      state.users = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEskizUser.pending, (state) => {
        state.getUserloading = true;
      })
      .addCase(getEskizUser.fulfilled, (state, action) => {
        state.getUserloading = false;
        state.smsEskizUser = action.payload?.data;
      })
      .addCase(getEskizUser.rejected, (state, action) => {
        state.getUserloading = false;
        state.error = action.payload;
      })

      .addCase(getUsersByGroup.pending, (state) => {
        state.usersLoading = true;
      })
      .addCase(getUsersByGroup.fulfilled, (state, action) => {
        state.usersLoading = false;
        state.users = action.payload?.data;
      })
      .addCase(getUsersByGroup.rejected, (state, action) => {
        state.usersLoading = false;
        state.error = action.payload;
      })

      .addCase(getEmployees.pending, (state) => {
        state.usersLoading = true;
      })
      .addCase(getEmployees.fulfilled, (state, action) => {
        state.usersLoading = false;
        state.users = action.payload?.data;
      })
      .addCase(getEmployees.rejected, (state, action) => {
        state.usersLoading = false;
        state.error = action.payload;
      })
      .addCase(getStudents.pending, (state) => {
        state.usersLoading = true;
      })
      .addCase(getStudents.fulfilled, (state, action) => {
        state.usersLoading = false;
        state.users = action.payload?.data;
      })
      .addCase(getStudents.rejected, (state, action) => {
        state.usersLoading = false;
        state.error = action.payload;
      })

      .addCase(getTeachers.pending, (state) => {
        state.usersLoading = true;
      })
      .addCase(getTeachers.fulfilled, (state, action) => {
        state.usersLoading = false;
        state.users = action.payload?.data;
      })
      .addCase(getTeachers.rejected, (state, action) => {
        state.usersLoading = false;
        state.error = action.payload;
      })

      .addCase(getSentMessages.pending, (state) => {
        state.usersLoading = true;
      })
      .addCase(getSentMessages.fulfilled, (state, action) => {
        state.usersLoading = false;
        state.sentMessages = action.payload?.data;
      })
      .addCase(getSentMessages.rejected, (state, action) => {
        state.usersLoading = false;
        state.error = action.payload;
      })

      .addCase(sendMessages.pending, (state) => {
        state.sendMsgLoading = true;
      })
      .addCase(sendMessages.fulfilled, (state, { payload }) => {
        state.sendMsgLoading = false;
        toast.success(payload?.message);
      })
      .addCase(sendMessages.rejected, (state, { payload }) => {
        state.sendMsgLoading = false;
        state.error = payload;
        toast.error(payload?.message);
      });
  },
});

export const { resetError, resetUsers } = smsSlice.actions;

export default smsSlice.reducer;
