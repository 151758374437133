import { BeakerIcon, CheckIcon } from "@heroicons/react/24/outline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import React from "react";
import { faLeaf } from "@fortawesome/free-solid-svg-icons";
import { getTime } from "../../utils/lessonTime";
import { getIcon } from "../../utils/icons";

export default function TimetableItem({ color, checked, icon, lessonTime }) {
  const lightColor = color.slice(0, -1) + ", 0.15)";
  const secondaryColor = color.slice(0, -1) + ", 0.8)";
  return (
    <div
      style={
        lessonTime?.status === "HELD"
          ? { border: "1px solid rgba(0, 166, 82, 0.7)" }
          : null
      }
      className='flex flex-row relative gap-5 bg-foresty-white dark:bg-forestydark-400 items-center justify-between w-[320px] h-[70px] p-3 rounded-xl'
    >
      {lessonTime?.status === "HELD" && (
        <div className='w-[18px] h-[18px] bg-foresty-500 absolute right-[-5px] top-[-5px]  rounded-full p-1'>
          <CheckIcon className='stroke-[3px] stroke-foresty-white' />
        </div>
      )}
      <div className='flex flex-row h-full items-center gap-4'>
        <span
          style={{
            backgroundColor:
              lessonTime?.groupMonth?.group?.[
                lessonTime?.groupMonth?.group?.type
              ]?.color?.hexCode,
          }}
          className='flex justify-center items-center w-[42px] h-[42px] rounded-full p-[8px]'
        >
          {/* <BeakerIcon className="stroke-[1.8px]" style={{ stroke: "#fff" }} /> */}
          <FontAwesomeIcon
            icon={getIcon(
              lessonTime?.groupMonth?.group?.[
                lessonTime?.groupMonth?.group?.type
              ].icon
            )}
            className=' text-foresty-white'
          />
        </span>
        <div className='flex flex-col leading-4'>
          <span className='text-[13px] font-bold text-forestydark-400 dark:text-foresty-white'>
            {lessonTime?.groupMonth?.group?.name}
          </span>
          <span
            style={{
              color:
                lessonTime?.groupMonth?.group?.[
                  lessonTime?.groupMonth?.group?.type
                ]?.color?.lightHexCode,
            }}
            className='text-[12px] font-normal'
          >
            {
              lessonTime?.groupMonth?.group?.[
                lessonTime?.groupMonth?.group?.type
              ]?.name
            }
          </span>
          <span className='text-[12px] font-semibold text-forestydark-300 dark:text-foresty-white/80'>
            {/* {lessonTime?.teacherFullName} */}
          </span>
        </div>
      </div>
      <div className='flex flex-col leading-4 justify-between items-end h-full'>
        <span className='text-[12px] font-semibold text-forestydark-300 dark:text-foresty-white/80 px-1'>
          {`${lessonTime?.groupMonth?.group?.room?.roomNumber}-xona`}
        </span>

        <span
          style={!checked ? { border: "1px solid #ffcb00" } : null}
          className='text-[12px] px-2 py-1 font-bold bg-[#b2b2b2]/10 text-forestydark-800/90 dark:text-[#fff] dark:bg-[#3e3d40]/90 rounded-full'
        >
          {getTime(lessonTime)}
        </span>
      </div>
    </div>
  );
}
