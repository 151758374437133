import React, { useEffect } from "react";
import UserStatus from "../../../components/Status/UserStatus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-regular-svg-icons";
import { joinedDate } from "../../../utils/date";
import { getPhoneNumberString, sentenceCase } from "../../../utils/data";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { Avatar, Chip, Spinner, Badge, Tabs, Tab } from "@nextui-org/react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getStudent } from "../studentsSlice";
import { tabs } from "./data/studentTab";
import { clearDatas } from "../../Documents/documentSlice";
import { getColorStatus } from "../../../utils/status";
import StudentDetailsPage from "./StudentDetailsPage";
import StudentGroupsPage from "./StudentGroupsPage";
import StudentPaymentsPage from "./StudentPaymentsPage";
import StudentDocumentsPage from "./StudentDocumentsPage";
import StudentSettingsPage from "./StudentSettingsPage";
import { getProfileAvatar } from "../../../utils/profile";

const StudentPage = () => {
  const [selected, setSelected] = React.useState(
    localStorage.getItem("group-menu") ?? "details"
  );
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { student, loading } = useSelector((state) => state.students);

  useEffect(() => {
    dispatch(clearDatas());
    dispatch(getStudent(id));
  }, []);
  return loading ? (
    <Spinner />
  ) : (
    <div className='flex flex-col w-full items-center gap-5'>
      <div className='grid grid-cols-3 w-full'>
        <div className='w-fit'>
          <Badge
            content={student?.status}
            color={getColorStatus(student?.status)}
            className={
              student?.status == "FINISHED"
                ? "text-white text-[12px] bg-blue-500"
                : "text-white text-[12px]"
            }
          >
            <div className='flex flex-row bg-white dark:bg-neutral-800 rounded-full p-2 gap-4'>
              <Avatar
                css={{ border: "$white", width: "15px", height: "15px" }}
                src={getProfileAvatar(student?.student?.profilePhoto)}
                //   src={
                //     defaultImage
                //     // student?.student?.user?.avatar != null
                //     //   ? `https://platform.foresty.uz/api/image/${student?.student?.user?.avatar.id}`
                //     //   : defaultImage
                //   }
                isBordered
                color='success'
              />
              <div className='flex flex-col justify-center leading-4'>
                <span className='text-[14px] font-raleway font-extrabold mr-4 '>
                  {student?.student?.user?.lastName +
                    " " +
                    student?.student?.user?.firstName}
                </span>

                <span className='text-[12px] font-bold text-forestydark-100 '>
                  {sentenceCase(student?.student?.user?.role?.roleName)}
                </span>
              </div>
            </div>
          </Badge>
        </div>
        <div className='w-full flex justify-center items-center'>
          <Tabs
            selectedKey={selected}
            onSelectionChange={(key) => {
              localStorage.setItem("group-menu", key);
              setSelected(key);
            }}
            size='md'
            radius='full'
            aria-label='Tabs sizes'
          >
            {tabs.map((tab) => (
              <Tab title={tab.title} key={tab.key} />
            ))}
          </Tabs>
        </div>
      </div>
      <div className='bg-white/30 dark:bg-neutral-900 rounded-3xl p-8 w-full'>
        {selected === "details" ? (
          <StudentDetailsPage student={student} />
        ) : selected === "groups" ? (
          <StudentGroupsPage student={student} />
        ) : selected === "payments" ? (
          <StudentPaymentsPage student={student} />
        ) : selected === "documents" ? (
          <StudentDocumentsPage student={student} />
        ) : selected === "settings" ? (
          <StudentSettingsPage student={student} />
        ) : null}
      </div>
    </div>
  );
};

export default StudentPage;
