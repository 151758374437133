import React from "react";
import { Spinner, Button } from "@nextui-org/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  activeEmployeeUpdateFields,
  newEmployeeUpdateFields,
  updateFileds,
  updateValidationSchemaActive,
  updateValidationSchemaNew,
} from "./data/updateForm";
import { getUpdateInitialValues } from "../utils";
import {
  deleteEmployee,
  finishEmployeeWork,
  updateEmployee,
} from "../employeeSlice";
import CreateEditModal from "../../../components/Modal/CreateEditModal";
import { roles } from "../data";
import DeleteModal from "../../../components/Modal/DeleteModal";
import {
  DeleteButton,
  DeleteButtonText,
  FinishButtonText,
} from "../../../components/Buttons/CRUDbuttons";
import FinishModal from "../../../components/Modal/FinishModal";

const EmployeeSettingsPage = ({ employee }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    employee && (
      <div className='flex flex-col items-center'>
        <div className='flex flex-col gap-8 bg-neutral-50 dark:bg-neutral-800/50 p-5 rounded-2xl w-[800px]'>
          <div className='grid grid-cols-1 w-full gap-5'>
            <CreateEditModal
              size='3xl'
              colsNum={2}
              title={"Xodim ma'lumotlarini tahrirlash"}
              // loading={loading}
              submitBtnText={"Tahrirlash"}
              button={<Button>{"Xodim ma'lumotlarini tahrirlash"}</Button>}
              fields={
                employee?.status == "NEW"
                  ? newEmployeeUpdateFields.map((el) => {
                      if (el.name === "role") {
                        el.selectData = roles;
                        return el;
                      }
                      return el;
                    })
                  : updateFileds.map((el) => {
                      if (el.name === "role") {
                        el.selectData = roles;
                        return el;
                      }
                      return el;
                    })
              }
              initialValues={getUpdateInitialValues(employee)}
              validationSchema={
                employee?.status === "NEW"
                  ? updateValidationSchemaNew
                  : updateValidationSchemaActive
              }
              handleSubmit={(body) =>
                dispatch(updateEmployee({ ...body, id: employee?.id }))
              }
            />
          </div>

          <div className='flex flex-row gap-5 mt-5 rounded-3xl border-[1px] border-red-500 p-4'>
            <FinishModal
              button={
                <FinishButtonText>{"Xodim ishini yakunlash"}</FinishButtonText>
              }
              contextText={`${employee?.employee?.user.lastName} ${employee?.employee?.user.firstName} ${employee?.employee?.user.fatherName} xodimning ish`}
              handleSubmit={() =>
                dispatch(finishEmployeeWork({ id: employee?.id }))
              }
            />
            <DeleteModal
              button={
                <DeleteButtonText>{"Xodimni o'chirish"}</DeleteButtonText>
              }
              contextText={`${employee?.employee?.user.lastName} ${employee?.employee?.user.firstName} ${employee?.employee?.user.fatherName} xodimni`}
              handleSubmit={() =>
                dispatch(
                  deleteEmployee({
                    id: employee?.id,
                    navigate: () => navigate("/employees"),
                  })
                )
              }
            />
          </div>
        </div>
      </div>
    )
  );
};

export default EmployeeSettingsPage;
