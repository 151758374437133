export const getProviderLogo = (phoneNumber) => {
  if (phoneNumber) {
    if (phoneNumber.startsWith("90") || phoneNumber.startsWith("91")) {
      return "https://logos-download.com/wp-content/uploads/2018/07/Beeline_logo_eng-450x403.png";
    } else if (
      phoneNumber.startsWith("99") ||
      phoneNumber.startsWith("77") ||
      phoneNumber.startsWith("95")
    ) {
      return "https://asset.brandfetch.io/ideNRwlCwU/idakxxj8Gs.jpeg";
    } else if (phoneNumber.startsWith("97") || phoneNumber.startsWith("88")) {
      return "https://mobi.uz/local/templates/main_v2/images/logo.svg";
    } else if (
      phoneNumber.startsWith("93") ||
      phoneNumber.startsWith("94") ||
      phoneNumber.startsWith("50")
    ) {
      return "https://ucell.uz/img/ucell.png";
    } else if (phoneNumber.startsWith("33")) {
      return "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSK8z4Lj9un3gTx6115iA-KswKyMvUeQe1lrtOIrCRrHk5ELfdy7ZTOx5vZ18XKI_cD6LM&usqp=CAU";
    } else if (phoneNumber.startsWith("98")) {
      return "https://frankfurt.apollo.olxcdn.com/v1/files/9duigxsgs8sa3-UZ/image;s=1080x1013";
    } else {
      return "https://www.freeiconspng.com/thumbs/telephone-icon/mobile-phone-icon-phone-telephone-icon-22.png";
    }
  }
};
