import React, { useEffect } from "react";
import { getStatusStyle } from "../utils";
import UserStatus from "../../../components/Status/UserStatus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-regular-svg-icons";
import { joinedDate } from "../../../utils/date";
import { getPhoneNumberString, sentenceCase } from "../../../utils/data";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { Avatar, Chip, Spinner, Badge, Tabs, Tab } from "@nextui-org/react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getTeacher } from "../teachersSlice";
import { tabs } from "./data/teacherTab";
import TeacherDetailsPage from "./TeacherDetailsPage";
import { getColorStatus } from "../../../utils/status";
import TeacherDocumentsPage from "./TeacherDocumentsPage";
import { clearDatas } from "../../Documents/documentSlice";
import TeacherGroupsPage from "./TeacherGroupsPage";
import TeacherPaymentsPage from "./TeacherPaymentsPage";
import TeacherSettingsPage from "./TeacherSettingsPage";
import { getProfileAvatar } from "../../../utils/profile";

const TeacherPage = () => {
  const [selected, setSelected] = React.useState(
    localStorage.getItem("group-menu") ?? "details"
  );
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { teacher, loading } = useSelector((state) => state.teachers);

  useEffect(() => {
    dispatch(clearDatas());
    dispatch(getTeacher(id));
  }, []);

  return loading ? (
    <Spinner />
  ) : (
    <div className='flex flex-col w-full items-center gap-5'>
      <div className='grid grid-cols-3 w-full'>
        <div className='w-fit'>
          <Badge
            content={teacher?.status}
            color={getColorStatus(teacher?.status)}
            className={
              teacher?.status == "FINISHED"
                ? "text-white text-[12px] bg-blue-500"
                : "text-white text-[12px]"
            }
          >
            <div className='flex flex-row bg-white dark:bg-neutral-800 rounded-full p-2 gap-4'>
              <Avatar
                css={{ border: "$white", width: "15px", height: "15px" }}
                src={getProfileAvatar(teacher?.teacher?.profilePhoto)}
                isBordered
                color='success'
              />
              <div className='flex flex-col justify-center leading-4'>
                <span className='text-[14px] font-raleway font-extrabold mr-4 '>
                  {teacher?.teacher?.user?.lastName +
                    " " +
                    teacher?.teacher?.user?.firstName}
                </span>

                <span className='text-[12px] font-bold text-forestydark-100 '>
                  {sentenceCase(teacher?.teacher?.user?.role?.roleName)}
                </span>
              </div>
            </div>
          </Badge>
        </div>
        <div className='w-full flex justify-center items-center'>
          <Tabs
            selectedKey={selected}
            onSelectionChange={(key) => {
              localStorage.setItem("group-menu", key);
              setSelected(key);
            }}
            size='md'
            radius='full'
            aria-label='Tabs sizes'
          >
            {tabs.map((tab) => (
              <Tab title={tab.title} key={tab.key} />
            ))}
          </Tabs>
        </div>
      </div>
      <div className='bg-white/30 dark:bg-neutral-900 rounded-3xl p-8 w-full'>
        {selected === "details" ? (
          <TeacherDetailsPage teacher={teacher} />
        ) : selected === "documents" ? (
          <TeacherDocumentsPage teacher={teacher} />
        ) : selected === "groups" ? (
          <TeacherGroupsPage teacher={teacher} />
        ) : selected === "payments" ? (
          <TeacherPaymentsPage teacher={teacher} />
        ) : selected === "settings" ? (
          <TeacherSettingsPage teacher={teacher} />
        ) : null}
      </div>
    </div>
  );
};

export default TeacherPage;
