import React, { useEffect } from "react";
import PageContainer from "../../components/Container/PageContainer";
import PageHeader from "../../components/Dashboard/PageHeader";
import PageBoard from "../../components/Container/PageBoard";
import ForestyTable from "../../components/Table/ForestyTable";
import {
  NavigateCreateButton,
  NavigateViewButton,
} from "../../components/Buttons/NavigateButtons";
import { INITIAL_VISIBLE_COLUMNS, columns, searchIndexes } from "./data";
import { useDispatch, useSelector } from "react-redux";
import { getAllStudents } from "./studentsSlice";

const StudentsPage = () => {
  const dispatch = useDispatch();
  const { students, loading } = useSelector((state) => state.students);

  useEffect(() => {
    dispatch(getAllStudents());
  }, []);

  return (
    <PageContainer>
      <PageHeader title="O'quvchilar" count={students?.length} />
      <PageBoard>
        {students && (
          <ForestyTable
            searchIndexes={searchIndexes}
            viewButton={(id) => (
              <NavigateViewButton isLight path={`/students/${id}`} />
            )}
            createModal={<NavigateCreateButton path={"/students/create"} />}
            tableData={students}
            columns={columns}
            initialVisibleColumns={INITIAL_VISIBLE_COLUMNS}
            editButton={null}
            deleteButton={null}
          />
        )}
      </PageBoard>
    </PageContainer>
  );
};

export default StudentsPage;
