import { Spinner, Tab, Tabs } from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { tabs } from "./data/tabs";
import SmsMessagesPage from "./SmsMessagesPage/SmsMessagesPage";
import SendSmsPage from "./SendSmsPage/SendSmsPage";
import { getEskizUser } from "./smsSlice";

const SMSPage = () => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(
    localStorage.getItem("group-menu") ?? "details"
  );

  const { smsEskizUser, getUserloading } = useSelector((state) => state.sms);

  useEffect(() => {
    dispatch(getEskizUser());
  }, []);

  return getUserloading ? (
    <Spinner />
  ) : (
    <div className='flex flex-col w-full items-center gap-5'>
      <div className='grid grid-cols-3 w-full'>
        <div className='w-fit'></div>
        <div className='w-full flex justify-center items-center'>
          <Tabs
            selectedKey={selected}
            onSelectionChange={(key) => {
              localStorage.setItem("group-menu", key);
              setSelected(key);
            }}
            size='md'
            radius='full'
            aria-label='Tabs sizes'
          >
            {tabs.map((tab) => (
              <Tab title={tab.title} key={tab.key} />
            ))}
          </Tabs>
        </div>
      </div>
      <div className='bg-white/30 dark:bg-neutral-900 rounded-3xl p-8 w-full'>
        {selected === "messages" ? (
          <SmsMessagesPage />
        ) : selected === "sendSMS" ? (
          <SendSmsPage />
        ) : null}
      </div>
    </div>
  );
};

export default SMSPage;
