import * as Yup from "yup";

export const createFields = [
  {
    name: "firstName",
    type: "text",
    label: "Ismi",
    placeholder: "Ismini kiriting...",
    isRequired: true,
  },
  {
    name: "lastName",
    type: "text",
    label: "Familiyasi",
    placeholder: "Familiyasini kiriting...",
    isRequired: true,
  },
  {
    name: "fatherName",
    type: "text",
    label: "Otasining ismi",
    placeholder: "Otasining ismini kiriting...",
    isRequired: true,
  },

  {
    name: "phoneNumber",
    type: "text",
    label: "Tel. raqami",
    // placeholder: "O'quv markaz tel. raqamini kiriting...",
    isRequired: true,
    startContent: "+998",
  },

  {
    name: "birthDate",
    type: "date",
    label: "Tug'ilgan kuni",
    placeholder: "Tug'ilgan kunini kiriting...",
    isRequired: true,
  },
  {
    name: "email",
    type: "text",
    label: "Emaili",
    placeholder: "Emailini kiriting...",
    isRequired: true,
  },

  {
    name: "subjectsId",
    type: "selectSubjects",
    label: "Fanlar",
    placeholder: "Fanlarni tanlash...",
    isRequired: true,
  },
];

export const emptyValues = {
  firstName: "",
  lastName: "",
  fatherName: "",

  email: "",
  phoneNumber: "",
  birthDate: "",
  subjectsId: [],
};

export const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("Bo'sh bo'lmasligi kerak!"),
  lastName: Yup.string().required("Bo'sh bo'lmasligi kerak!"),
  fatherName: Yup.string().required("Bo'sh bo'lmasligi kerak!"),

  email: Yup.string()
    .email("Yaroqsiz email!")
    .required("Bo'sh bo'lmasligi kerak!"),
  phoneNumber: Yup.string()
    .matches(/^\(\d{2}\) \d{3}-\d{2}-\d{2}$/, "Tel. raqamni to'g'ri kiriting!")
    .required("Bo'sh bo'lmasligi kerak!"),
  birthDate: Yup.string().required("Bo'sh bo'lmasligi kerak!"),
  subjectsId: Yup.array()
    .of(Yup.string().required("Item is required"))
    .min(1, "At least one item is required") // Optional, specify the minimum length of the array
    .max(10, "No more than 10 items allowed"),
});

export const getUpdateValidationSchema = () => {
  // let isValueChanged;
  return Yup.object().shape({
    firstName: Yup.string().required("Bo'sh bo'lmasligi kerak!"),
    lastName: Yup.string().required("Bo'sh bo'lmasligi kerak!"),
    fatherName: Yup.string().required("Bo'sh bo'lmasligi kerak!"),

    email: Yup.string()
      .email("Yaroqsiz email!")
      .required("Bo'sh bo'lmasligi kerak!"),
    phoneNumber: Yup.string()
      .matches(
        /^\(\d{2}\) \d{3}-\d{2}-\d{2}$/,
        "Tel. raqamni to'g'ri kiriting!"
      )
      .required("Bo'sh bo'lmasligi kerak!"),
    birthDate: Yup.string().required("Bo'sh bo'lmasligi kerak!"),
    subjectsId: Yup.array()
      .of(Yup.string().required("Item is required"))
      // .test("is-unique", "IDs must be unique", function (value) {
      //   // Use a Set to ensure uniqueness
      //   const uniqueIds = new Set(value);

      //   // Check if the length of unique IDs is the same as the original array
      //   return uniqueIds.size === value.length;
      // })
      // .test("no-old-ids", "O'zgarish kiritilmadi!", function (value) {
      //   const sortedOldIds = subIds.slice().sort();
      //   const sortedNewIds = value.slice().sort();
      //   if (JSON.stringify(sortedNewIds) !== JSON.stringify(sortedOldIds)) {
      //     isValueChanged = true;
      //   }
      //   if (isValueChanged) {
      //     return JSON.stringify(sortedNewIds) !== JSON.stringify(sortedOldIds);
      //   }
      //   return true;
      // })
      .min(1, "Kamida bitta tanlash kerak!") // Optional, specify the minimum length of the array
      .max(10, "10 tadan ko'p bo'lmasligi keral!"),
  });
};

export const columns = [
  { name: "ID", uid: "rowOrder", sortable: true },
  { name: "Avatar", uid: "teacher.profilePhoto", sortable: true },

  { name: "ISMI", uid: "teacher.user.firstName", sortable: true },
  { name: "FAMILIYA", uid: "teacher.user.lastName", sortable: true },
  { name: "OTASINING ISMI", uid: "teacher.user.fatherName", sortable: true },
  { name: "STATUS", uid: "status", sortable: true },

  { name: "TEL. RAQAM", uid: "teacher.user.phoneNumber", sortable: true },
  { name: "FANLAR", uid: "subjects" },
  { name: "SANASI", uid: "created_at", sortable: true },
  // { name: "STATUS", uid: "status", sortable: true },
  { name: "ACTIONS", uid: "actions" },
];

export const INITIAL_VISIBLE_COLUMNS = [
  "status",
  "teacher.profilePhoto",
  "rowOrder",
  "teacher.user.firstName",
  "teacher.user.lastName",
  "teacher.user.fatherName",
  "teacher.user.phoneNumber",
  "subjects",
  "created_at",
  "actions",
];

export const searchIndexes = [
  ["teacher", "user", "firstName"],
  ["teacher", "user", "lastName"],
  ["teacher", "user", "fatherName"],
  ["teacher", "user", "phoneNumber"],
];
