import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash";
import Api from "../../config/Api";
import { toast } from "react-toastify";

export const getAllEmployees = createAsyncThunk(
  "employees/getAll",
  async (body = {}) => {
    const response = await Api.get("/employee");
    return response.data;
  }
);

export const getEmployee = createAsyncThunk(
  "employees/get",
  async (body = {}) => {
    const response = await Api.get(`/employee/${body}`);
    return response.data;
  }
);

export const getEmployeePayments = createAsyncThunk(
  "employees/getPayments",
  async (body = {}) => {
    const response = await Api.get(`/employee/payments/${body}`);
    return response.data;
  }
);

export const createEmployee = createAsyncThunk(
  "employees/create",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.post("/employee", body?.data);
      body?.navigate();
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateEmployee = createAsyncThunk(
  "employees/update",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.put(`/employee/${body?.id}`, body);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteEmployee = createAsyncThunk(
  "employees/delete",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.delete(`/employee/${body?.id}`);
      body?.navigate();
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const finishEmployeeWork = createAsyncThunk(
  "employees/finishWork",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.put(`/employee/finishWork/${body?.id}`);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const paySalary = createAsyncThunk(
  "employees/paySalary",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.post(`/payment/paySalaryEmployee`, body?.data);
      // body?.onClose();
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const employeesSlice = createSlice({
  name: "employees",
  initialState: {
    employee: null,
    employees: null,
    payments: null,

    paymentsLoading: false,
    loading: false,
    updateLoading: false,
    error: null,
  },
  reducers: {
    resetError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder

      ///------------ GET employee ------------------/////
      .addCase(getEmployee.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEmployee.fulfilled, (state, action) => {
        state.loading = false;
        state.employee = action.payload?.data;
      })
      .addCase(getEmployee.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ GET employees ------------------/////
      .addCase(getAllEmployees.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllEmployees.fulfilled, (state, action) => {
        state.loading = false;
        state.employees = action.payload?.data;
      })
      .addCase(getAllEmployees.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ GET employee payments ------------------/////
      .addCase(getEmployeePayments.pending, (state) => {
        state.paymentsLoading = true;
      })
      .addCase(getEmployeePayments.fulfilled, (state, action) => {
        state.paymentsLoading = false;
        state.payments = action.payload?.data;
      })
      .addCase(getEmployeePayments.rejected, (state, action) => {
        state.paymentsLoading = false;
        state.error = action.payload;
      })

      ///------------ CREATE employees ------------------/////
      .addCase(createEmployee.pending, (state) => {
        state.loading = true;
      })
      .addCase(createEmployee.fulfilled, (state, { payload }) => {
        state.loading = false;
        // state.employees = [...state.employees, payload?.data];
        toast.success(payload?.message);
      })
      .addCase(createEmployee.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        toast.error(payload?.message);
      })

      ///------------ delete employees ------------------/////
      .addCase(deleteEmployee.pending, (state) => {})
      .addCase(deleteEmployee.fulfilled, (state, { payload }) => {
        toast.success(payload?.message);
      })
      .addCase(deleteEmployee.rejected, (state, action) => {
        state.error = action.payload;
        toast.error(action.payload?.message);
      })

      ///------------ UPDATE employees ------------------/////
      .addCase(updateEmployee.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(updateEmployee.fulfilled, (state, { payload }) => {
        state.updateLoading = false;
        toast.success(payload?.message);

        // const ctgIndex = findIndex(state.employees, { id: payload?.data?.id });
        state.employee = payload?.data;
      })
      .addCase(updateEmployee.rejected, (state, { payload }) => {
        state.updateLoading = false;
        state.error = payload;
        toast.error(payload?.message);
      })

      ///------------ Finish employee work ------------------/////
      .addCase(finishEmployeeWork.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(finishEmployeeWork.fulfilled, (state, { payload }) => {
        state.updateLoading = false;
        toast.success(payload?.message);

        // const ctgIndex = findIndex(state.employees, { id: payload?.data?.id });
        state.employee = payload?.data;
      })
      .addCase(finishEmployeeWork.rejected, (state, { payload }) => {
        state.updateLoading = false;
        state.error = payload;
        toast.error(payload?.message);
      })

      ///------------ Pay salary employee payment ------------------/////
      .addCase(paySalary.pending, (state) => {
        // state.paymentsLoading = true;
      })
      .addCase(paySalary.fulfilled, (state, { payload }) => {
        // state.paymentsLoading = false;

        const ctgIndex = findIndex(state.payments, {
          id: payload?.data?.id,
        });
        state.payments[ctgIndex] = payload?.data;

        toast.success(payload?.message);
      })
      .addCase(paySalary.rejected, (state, { payload }) => {
        // state.paymentsLoading = false;
        state.error = payload;
        toast.error(payload?.message);
      });
  },
});

export const { resetError } = employeesSlice.actions;

export default employeesSlice.reducer;
