import { faGraduationCap } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { getActivityDate } from "../../utils/date";
import { getActivityIcon } from "../../utils/activity";
import { getMoneyPattern } from "../../utils/regex";

export default function LatestActivityCard({ activity }) {
  return (
    <div className='flex flex-row gap-3 w-full items-center rounded-xl py-3'>
      <div className='flex flex-none justify-center items-center w-[40px] h-[40px] bg-foresty-500/10 rounded-full'>
        <FontAwesomeIcon
          icon={getActivityIcon(activity.activityName)}
          className='text-foresty-500'
        />
      </div>
      <div className='flex flex-col gap-1'>
        <span className='text-[12px] font-bold text-fyTropic dark:text-foresty-white leading-4'>
          {`${
            activity?.educationCenterUser?.user.lastName +
            " " +
            activity?.educationCenterUser?.user.firstName
          } `}
          <span className='text-[12px] font-semibold text-foresty-500'>
            {`${activity.activityName}  `}
            {activity?.secondaryContext && (
              <span className='text-[12px] text-forestyWarning'>
                {activity.activityName.includes("to'lov")
                  ? `${getMoneyPattern(activity?.secondaryContext, ",")} so'm`
                  : `${activity?.secondaryContext}`}
              </span>
            )}
          </span>
          <br />
          <span className='font-thin'>{`${activity.context}`}</span>
        </span>
        <span className=' text-[11px] dark:text-forestydark-200 text-forestydark-100 font-bold'>
          {getActivityDate(activity.created_at)}
        </span>
      </div>
    </div>
  );
}
