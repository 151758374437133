import { LayoutGroup } from "framer-motion";
import React, { useState, useEffect } from "react";
import DayButton from "./dayButton";

export default function CalendarBar({ selectDate, selectedDate }) {
  const [days, setDays] = useState([]);
  useEffect(() => {
    const daysArr = [];
    let today = new Date();

    for (let i = 0; i < 7; i++) {
      today.setDate(today.getDate() - 1);
      daysArr.unshift({
        day: getWeekName(today.getDay()),
        date: today.getDate(),
        month: today.getMonth(),
        year: today.getFullYear(),
        pressed: false,
        isToday: false,
      });
    }
    today = new Date();
    daysArr.push({
      day: getWeekName(today.getDay()),
      date: today.getDate(),
      month: today.getMonth() + 1,
      year: today.getFullYear(),
      presed: true,
      isToday: true,
    });
    for (let i = 0; i < 7; i++) {
      today.setDate(today.getDate() + 1);
      daysArr.push({
        day: getWeekName(today.getDay()),
        date: today.getDate(),
        month: today.getMonth(),
        year: today.getFullYear(),
        pressed: false,
        isToday: false,
      });
    }

    setDays(daysArr);
  }, []);
  function getWeekName(index) {
    switch (index) {
      case 0:
        return "Sun";
      case 1:
        return "Mon";
      case 2:
        return "Tue";
      case 3:
        return "Wed";
      case 4:
        return "Thu";
      case 5:
        return "Fri";
      case 6:
        return "Sat";
    }
  }

  function selectButton(index) {
    const temArr = days.map((item, i) => {
      if (i === index) {
        item.presed = true;
        console.log(item.year, item.month, item.date, 0, 0, 0, 0);
        selectDate(new Date(item.year, item.month, item.date, 0, 0, 0, 0));
        return item;
      }
      item.presed = false;
      return item;
    });

    setDays(temArr);
  }
  return (
    <div className='w-full px-3 py-2 '>
      <div className='flex flex-row dark:bg-[#151515] bg-forestydark-100/10 justify-evenly py-1 text-[12px] rounded-lg'>
        <LayoutGroup>
          {days.map((item, index) => (
            <DayButton
              key={index}
              day={item.day}
              date={item.date}
              isPressed={item.presed}
              index={index}
              selectButton={selectButton}
              isToday={item.isToday}
            />
          ))}
        </LayoutGroup>
      </div>
    </div>
  );
}
