import React from "react";
import { Avatar, Tooltip } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import defaultImage from "../../assets/foresty_green.png";
import { getProfileAvatarById } from "../../utils/profile";

export default function EmployeeCard({ employee }) {
  const navigate = useNavigate();
  return (
    <div className='flex relative flex-col w-[180px] h-[230px] py-6 gap-3 bg-foresty-white dark:bg-forestydark-500 rounded-[30px] justify-between items-center'>
      {/* <span className="w-2 h-2 bg-foresty-500 absolute top-3 right-3 rounded-full" /> */}
      {employee?.online && (
        <span className='absolute top-3 right-3 justify-center items-center '>
          <Tooltip
            css={{ fontSize: "2pt" }}
            content={<span className='text-[12px]'>Online</span>}
            color='success'
            enterDelay={500}
          >
            <span className='flex justify-center items-center rounded-full h-[8px] w-[8px] bg-foresty-500 dark:bg-foresty-500'>
              <span className='animate-ping delay-[5000] h-[8px] w-[8px] rounded-full bg-foresty-500 dark:bg-foresty-500 opacity-75 border-2'></span>
            </span>
          </Tooltip>
        </span>
      )}
      <Avatar
        size='lg'
        css={{ border: "$white", width: "60px", height: "60px" }}
        src={
          getProfileAvatarById(employee?.employee?.profilePhotoId)
          // employee?.employee?.user?.avatar != null
          //   ? `https://platform.foresty.uz/api/image/${employee?.employee?.user?.avatar.id}`
          //   : defaultImage
        }
        isBordered
        color='success'
      />
      <span className='text-[14px] font-bold w-[80%] text-center px-2'>
        {employee?.employee?.user?.lastName +
          " " +
          employee?.employee?.user?.firstName}
      </span>
      <span className='text-[12px] font-bold text-forestydark-100'>
        {employee?.employee?.user?.role?.roleName}
      </span>
      <button
        onClick={() => navigate(`/employees/${employee?.id}`)}
        className='text-[12px] font-semibold text-[#765cff] mt-2'
      >
        Profilni Ko'rish
      </button>
    </div>
  );
}
