import React, { useEffect } from "react";
import PageContainer from "../../components/Container/PageContainer";
import PageHeader from "../../components/Dashboard/PageHeader";
import { useDispatch, useSelector } from "react-redux";
import { getAllEmployees } from "./employeeSlice";
import EmployeeCard from "../../components/Employee/EmployeeCard";
import { uniqueId } from "lodash";
import { PlusIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { getLatestActivities } from "./activitiesSlice";
import LatestActivityCard from "../../components/Employee/latestActivityCard";
import { v4 } from "uuid";

const EmployeesPage = () => {
  const { employees } = useSelector((state) => state.employees);
  const { activities } = useSelector((state) => state.activities);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAllEmployees());
    dispatch(getLatestActivities());
  }, []);
  return (
    <PageContainer>
      <PageHeader title='Xodimlar' count={employees?.length} />
      <div className='flex flex-row gap-5'>
        <div className='flex flex-row flex-wrap w-full px-3 gap-5'>
          {/* {employeeData
            .sort((a, b) => (b.online ? 1 : -1))
            .map((item) => (
              <EmployeeCard employee={item} />
            ))} */}

          {employees &&
            employees.map((item, index) => {
              return <EmployeeCard key={uniqueId()} employee={item} />;
            })}
          <button
            onClick={() => navigate("/employees/addEmployee")}
            className='w-[180px] h-[230px] py-16 px-16 bg-foresty-500/10 hover:bg-foresty-white dark:hover:bg-forestydark-500 transition duration-500 ease-in-out rounded-[30px]'
          >
            <PlusIcon className='stroke-foresty-500' />
          </button>
        </div>
        <div className='flex flex-col gap-3 w-[500px] h-fit rounded-[30px] bg-fyTropic-200/80 dark:bg-forestydark-500 px-8 py-7 mr-5'>
          <span className=' text-fyTropic text-[18px] font-extrabold dark:text-foresty-white/80'>
            Oxirgi faoliyat
          </span>
          <div className='flex flex-col w-full mt-3 gap-3 max-h-[300px] h-fit overflow-scroll scrollbar-hide'>
            {activities &&
              activities.map((el) => (
                <LatestActivityCard key={v4()} activity={el} />
              ))}
          </div>
        </div>
      </div>
    </PageContainer>
  );
};

export default EmployeesPage;
