import React, { useEffect } from "react";
import PageContainer from "../../components/Container/PageContainer";
import PageHeader from "../../components/Dashboard/PageHeader";
import PageBoard from "../../components/Container/PageBoard";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  createPayment,
  deletePayment,
  getAllFinanceIncomes,
  updatePayment,
} from "./financeSlice";
import ForestyTable from "../../components/Table/ForestyTable";
import {
  searchIndexes,
  INITIAL_VISIBLE_COLUMNS,
  columns,
} from "./data/financeTable";
import { EditButton, DeleteButton } from "../../components/Buttons/CRUDbuttons";
import CreateEditModal from "../../components/Modal/CreateEditModal";
import DeleteModal from "../../components/Modal/DeleteModal";
import { emptyValues, fields, validationSchema } from "./data/financeForm";
import { getMoneyPatternInput } from "../../utils/regex";

const IncomesPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { finance } = useSelector((state) => state.finance);

  useEffect(() => {
    dispatch(getAllFinanceIncomes());
  }, []);
  return (
    <PageContainer>
      <PageHeader title='Tushumlar' count={finance?.length} />
      <PageBoard>
        {finance && (
          <ForestyTable
            searchIndexes={searchIndexes}
            createModal={
              <CreateEditModal
                title={"To'lov qo'shish"}
                // loading={loading}
                btnText={"To'lov qo'shish"}
                fields={fields.map((el) => {
                  if (el.name === "paymentType") {
                    el.selectData = [
                      { id: "CASH", name: "Naqd pul" },
                      { id: "CARD", name: "Karta orqali" },
                    ];
                    return el;
                  }
                  return el;
                })}
                initialValues={emptyValues}
                validationSchema={validationSchema(1000, 50000000)}
                submitBtnText={"Qo'shish"}
                handleSubmit={(body) =>
                  dispatch(createPayment({ type: "INCOME", ...body }))
                }
              />
            }
            tableData={finance}
            columns={columns}
            initialVisibleColumns={INITIAL_VISIBLE_COLUMNS}
            editButton={(data) =>
              data?.paymentName === "ANOTHER" && (
                <CreateEditModal
                  button={<EditButton isLight />}
                  title={"Fanni tahrirlash"}
                  // loading={loading}
                  btnText={"Tahrirlash"}
                  fields={fields.map((el) => {
                    if (el.name === "paymentType") {
                      el.selectData = [
                        { id: "CASH", name: "Naqd pul" },
                        { id: "CARD", name: "Karta orqali" },
                      ];
                      return el;
                    }
                    return el;
                  })}
                  initialValues={{
                    ...data,
                    amount: getMoneyPatternInput(data?.amount),
                  }}
                  validationSchema={validationSchema(1000, 50000000)}
                  submitBtnText={"Tahrirlash"}
                  handleSubmit={(body) =>
                    dispatch(updatePayment({ ...body, id: data.id }))
                  }
                />
              )
            }
            deleteButton={(data) =>
              data?.paymentName === "ANOTHER" && (
                <DeleteModal
                  button={<DeleteButton isLight />}
                  contextText={`${data.context} to'lovni`}
                  handleSubmit={() => dispatch(deletePayment({ id: data.id }))}
                />
              )
            }
          />
        )}
      </PageBoard>
    </PageContainer>
  );
};

export default IncomesPage;
