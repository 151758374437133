import React from "react";
import VerticalBoard from "../../components/Container/VerticalBoard";
import ForestyForm from "../../components/Form/ForestyForm";
import { useDispatch, useSelector } from "react-redux";
import { createFields, emptyValues, validationSchema } from "./data";
import { createStudent } from "./studentsSlice";
import { useNavigate } from "react-router-dom";

const CreateStudentPAge = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading } = useSelector((state) => state.students);

  return (
    <VerticalBoard>
      <span className='text-[22px] mt-3 dark:text-foresty-white text-center font-bold'>
        {"O'quvchi qo'shish"}
      </span>

      <ForestyForm
        // isValidate={false}
        isLoading={loading}
        colsNum={2}
        initialValues={emptyValues}
        fields={createFields}
        validationSchema={validationSchema}
        handleSubmit={(body) =>
          dispatch(
            createStudent({ data: body, navigate: () => navigate("/students") })
          )
        }
        submitBtnText="Qo'shish"
      />
    </VerticalBoard>
  );
};

export default CreateStudentPAge;
