import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Slider, Tooltip, Switch, Divider } from "@nextui-org/react";
import { switches, switchesCourse, switchesSubject } from "./data/settingsData";
import CreateEditModal from "../../../components/Modal/CreateEditModal";
import { useDispatch, useSelector } from "react-redux";
import date from "date-and-time";
import * as Yup from "yup";
import {
  activeGroupFields,
  activeValidationSchema,
  courseBasedFieldsNew,
  newGroupFields,
  subjectBasedFieldsNew,
} from "./data/groupForm";
import { validationSchemaCourse, validationSchemaSubject } from "../data/form";
import { getAllTeachers } from "../../TeachersPage/teachersSlice";
import { getAllSubjects } from "../../SubjectPage/subjectsSlice";
import { getAllCourses } from "../../CoursePage/coursesSlice";
import { getAllRooms } from "../../RoomsPage/roomsSlice";
import {
  deleteGroup,
  finishGroup,
  getGroupLessonTimes,
  getGroupSettings,
  getGroupTeacher,
  updateGroup,
  updateGroupSettings,
} from "../groupsSlice";
import { getMoneyPattern, getMoneyPatternInput } from "../../../utils/regex";
import EmptyModal from "../../../components/Modal/EmptyModal";
import LessonTimesModalContent from "./LessonTimesModalContent";
import { Formik } from "formik";
import { v4 } from "uuid";
import DeleteModal from "../../../components/Modal/DeleteModal";
import { DeleteButtonText } from "../../../components/Buttons/CRUDbuttons";
import FinishModal from "../../../components/Modal/FinishModal";

const GroupSettingsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { teachers } = useSelector((state) => state.teachers);
  const { subjects } = useSelector((state) => state.subjects);
  const { courses } = useSelector((state) => state.courses);
  const { rooms } = useSelector((state) => state.rooms);
  const { teacher, lessonTimes, group, settings } = useSelector(
    (state) => state.groups
  );

  useEffect(() => {
    if (group?.status === "NEW") {
      dispatch(getAllTeachers());
      dispatch(getAllSubjects());
      dispatch(getAllCourses());
    }
    group && dispatch(getGroupTeacher(group?.id));

    dispatch(getAllRooms());
    group && dispatch(getGroupLessonTimes(group?.id));
    group && dispatch(getGroupSettings(group?.id));
  }, []);

  return (
    <div className='flex flex-col items-center'>
      <div className='flex flex-col gap-8 bg-neutral-50 dark:bg-neutral-800/50 p-5 rounded-2xl w-[800px]'>
        <div className='grid grid-cols-2 w-full gap-5'>
          <CreateEditModal
            size='3xl'
            colsNum={2}
            title={"Guruh ma'lumotlarini tahrirlash"}
            // loading={loading}
            submitBtnText={"Tahrirlash"}
            button={<Button>{"Guruh ma'lumotlarini tahrirlash"}</Button>}
            fields={
              group?.status === "NEW"
                ? group?.type === "subject"
                  ? subjectBasedFieldsNew.map((el) => {
                      if (el.name === "subjectId") {
                        el.selectData = subjects;
                        return el;
                      } else if (el.name === "teacherId") {
                        el.selectData = teachers?.map((t) => {
                          return {
                            id: t.id,
                            name: `${t["teacher.user.firstName"]} ${t["teacher.user.lastName"]} ${t["teacher.user.fatherName"]}`,
                          };
                        });
                        return el;
                      } else if (el.name === "roomId") {
                        el.selectData = rooms?.map((r) => ({
                          id: r.id,
                          name: `${r.roomNumber}-xona`,
                        }));
                        return el;
                      }
                      return el;
                    })
                  : courseBasedFieldsNew.map((el) => {
                      if (el.name === "courseId") {
                        el.selectData = courses;
                        return el;
                      } else if (el.name === "teacherId") {
                        el.selectData = teachers?.map((t) => {
                          return {
                            id: t.id,
                            name: `${t["teacher.user.firstName"]} ${t["teacher.user.lastName"]} ${t["teacher.user.fatherName"]}`,
                          };
                        });
                        return el;
                      } else if (el.name === "roomId") {
                        el.selectData = rooms?.map((r) => ({
                          id: r.id,
                          name: `${r.roomNumber}-xona`,
                        }));
                        return el;
                      }
                      return el;
                    })
                : activeGroupFields.map((el) => {
                    if (el.name === "roomId") {
                      el.selectData = rooms?.map((r) => ({
                        id: r.id,
                        name: `${r.roomNumber}-xona`,
                      }));
                      return el;
                    }
                    return el;
                  })
            }
            // fields={fields.map((el) => {
            //   if (el.name === "floor") {
            //     el.selectData = floorsData;
            //     return el;
            //   }
            //   return el;
            // })}
            initialValues={
              group?.status === "NEW"
                ? {
                    ...group,
                    startsDate: date.format(
                      new Date(group?.startDate),
                      "YYYY-MM-DD"
                    ),
                    price: getMoneyPatternInput(group.price),
                    teacherId: teacher?.teacherId,
                    agreement: teacher?.agreement,
                  }
                : {
                    ...group,
                    price: getMoneyPattern(group?.price),
                    agreement: teacher?.agreement,
                  }
            }
            validationSchema={
              group?.status === "NEW"
                ? group?.type === "subject"
                  ? validationSchemaSubject
                  : validationSchemaCourse
                : activeValidationSchema
            }
            handleSubmit={(body) =>
              dispatch(updateGroup({ id: group?.id, data: body }))
            }
          />
          <EmptyModal
            button={<Button>{"Guruh dars vaqtlarini o'zgartirish"}</Button>}
            title={"Dars vaqtlarini o'zgartirish"}
            content={(onClose) => {
              return (
                lessonTimes && (
                  <LessonTimesModalContent
                    groupId={group?.id}
                    lessonTimes={lessonTimes}
                    onClose={onClose}
                  />
                )
              );
            }}
            size='2xl'
          />
        </div>
        {settings && (
          <Formik
            // validateOnBlur={t}
            // validateOnBlur={true}
            // validateOnChange={true}
            enableReinitialize
            initialValues={{
              yellowDays: group?.yellowDays,
              isPublic: settings?.isPublic,
              isVisiblePaymentsForTeacher:
                settings?.isVisiblePaymentsForTeacher,
            }}
            validationSchema={Yup.object().shape({
              yellowDays: Yup.number()
                .min(5, "Kelishuv noto'g'ri kiritildi!")
                .max(25, "Kelishuv noto'g'ri kiritildi!")
                .required("Bo'sh bo'lmasligi kerak!"),
            })}
            onSubmit={async (values, { props, setSubmitting }) => {
              dispatch(updateGroupSettings({ id: group?.id, data: values }));
            }}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              touched,
              errors,
              setFieldValue,
              isValid,
              isSubmitting,
              dirty,
            }) => (
              <div className='flex flex-col gap-5 w-full p-5 bg-white shadow-lg dark:bg-neutral-800 rounded-3xl border-[1px] border-neutral-200 dark:border-neutral-700'>
                <Slider
                  size='md'
                  step={5}
                  color='warning'
                  label={
                    <Tooltip
                      className='dark:bg-amber-500/10 bg-amber-500 backdrop-blur-xl'
                      content={
                        <span className='w-[150px] text-center p-2 text-white dark:text-amber-500'>
                          {
                            "Guruh oyining ushbu kunidan keyin barcha to'lanmagan to'lovlar qarzdorlik holatiga o'tadi!"
                          }
                        </span>
                      }
                      color='warning'
                    >
                      <span>{"Guruhning sariq kunlari"}</span>
                    </Tooltip>
                  }
                  showSteps={true}
                  maxValue={30}
                  minValue={0}
                  defaultValue={values["yellowDays"]}
                  value={values["yellowDays"]}
                  className='max-full'
                  onChange={(e) => {
                    setFieldValue("yellowDays", e);
                  }}
                />
                <div className='flex flex-col p-3 bg-white shadow-md dark:bg-neutral-900 rounded-xl w-full border-[1px] border-neutral-200 dark:border-neutral-700/50'>
                  {switchesCourse.map((sw, ind) => (
                    <React.Fragment>
                      {ind !== 0 && <Divider className='my-3' />}
                      <div
                        key={ind}
                        className='flex flex-row items-center justify-between w-full '
                      >
                        <span className='text-[14px]'>{sw.text}</span>
                        <Switch
                          defaultSelected={values[sw.name]}
                          isSelected={values[sw.name]}
                          onValueChange={(e) => {
                            setFieldValue(sw.name, e);
                          }}
                          size='md'
                        />
                      </div>
                    </React.Fragment>
                  ))}
                </div>
                <Button
                  disabled={!isValid || !dirty}
                  type='submit'
                  color={!isValid || !dirty ? "default" : "primary"}
                  onPress={handleSubmit}
                >
                  {"O'zgarishlarni saqlash"}
                </Button>
                <div className='flex flex-row gap-5 mt-5 rounded-3xl border-[1px] border-red-500 p-4'>
                  {group?.type == "subject" && (
                    <FinishModal
                      button={
                        <DeleteButtonText>
                          {"Guruhni to'xtatish"}
                        </DeleteButtonText>
                      }
                      contextText={`${group?.name}`}
                      handleSubmit={() =>
                        dispatch(
                          finishGroup({
                            id: group?.id,
                            // navigate: () => navigate("/groups"),
                          })
                        )
                      }
                    />
                  )}
                  <DeleteModal
                    button={
                      <DeleteButtonText>{"Guruhni o'chirish"}</DeleteButtonText>
                    }
                    contextText={`${group?.name} guruhni`}
                    handleSubmit={() =>
                      dispatch(
                        deleteGroup({
                          id: group?.id,
                          navigate: () => navigate("/groups"),
                        })
                      )
                    }
                  />
                </div>
              </div>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
};

export default GroupSettingsPage;
