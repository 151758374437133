import React from "react";

export default function HomePageWarning({ count, text }) {
  return (
    <div className="flex flex-row justify-between gap-5 items-center w-full bg-foresty-white/80 dark:bg-forestydark-500 rounded-full px-5 py-1 text-[#780e0a] dark:text-foresty-white/90">
      <span className="flex justify-center items-center rounded-full h-[10px] w-[10px] bg-[#f01b14] dark:bg-[#ff526c]">
        <span className="animate-ping h-[10px] w-[10px] rounded-full bg-[#f01b14] dark:bg-[#ff526c] opacity-75 border-2"></span>
      </span>

      <span className="text-[12px] text-center font-raleway font-extrabold">
        {text}
      </span>
      <span className="text-[28px] text-center font-space font-bold dark:drop-shadow-[0px_0px_5px_rgba(255,82,108,0.95)]">
        {count}
      </span>
    </div>
  );
}
