import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faSnowflake,
  faCircleExclamation,
  faLock,
  faCircleDot,
  faPersonCircleCheck,
  faUserCheck,
} from "@fortawesome/free-solid-svg-icons";
import React from "react";

export default function UserStatus({ status, isText }) {
  switch (status) {
    case "ACTIVE":
      return (
        <span className='text-foresty-500 text-[14px] font-bold'>
          <FontAwesomeIcon icon={faCircleCheck} className='mr-1' />
          {isText && "Active"}
        </span>
      );
    case "BLOCKED":
      return (
        <span className='text-[#f4322f] text-[14px] font-bold'>
          <FontAwesomeIcon icon={faLock} className='mr-1' />
          {isText && "Blocked"}
        </span>
      );
    case "FINISH":
      return (
        <span className='text-[#0086ff] text-[14px] font-bold'>
          <FontAwesomeIcon icon={faSnowflake} className='mr-1' />
          {isText && "Finished"}
        </span>
      );
    case "COMPLETED":
      return (
        <span className='text-[#9e73c7] text-[14px] font-bold'>
          <FontAwesomeIcon icon={faUserCheck} className='mr-1' />
          {isText && "Completed"}
        </span>
      );
    case "NEW":
      return (
        <span className='text-[#94BD33] text-[14px] font-bold'>
          <FontAwesomeIcon icon={faCircleDot} className='mr-1' />
          {isText && "New"}
        </span>
      );
  }
}
