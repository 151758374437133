import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllFinanceData, getFinanceByDaily } from "./financeSlice";
import date from "date-and-time";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  Button,
} from "@nextui-org/react";
import {
  ProfitIcon,
  SellingAndBuyingIcon,
} from "../../components/icons/paymentIcons";
import { getMoneyPattern } from "../../utils/regex";
import { useNavigate } from "react-router-dom";

import {
  getDateRangeInitialDatas,
  getOptions,
  options,
} from "./data/chartData";
import { Line, Bar } from "react-chartjs-2";
import { DateRange, DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import { useTheme } from "next-themes";

const FinancePage = () => {
  const { theme } = useTheme();
  const [ranges, setRanges] = useState(getDateRangeInitialDatas());
  const [chartData, setChartData] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { financeData, chartData: financeChartData } = useSelector(
    (state) => state.finance
  );

  useEffect(() => {
    dispatch(getAllFinanceData());
  }, []);

  useEffect(() => {
    dispatch(
      getFinanceByDaily({
        fromDate: ranges.startDate,
        toDate: ranges.endDate,
      })
    );
  }, [ranges]);

  useEffect(() => {
    if (financeChartData !== null) {
      const chartData = {
        labels: financeChartData.map((entry) =>
          date.format(new Date(entry.date), "DD MMM YYYY")
        ),
        datasets: [
          {
            label: "Income",
            data: financeChartData.map((entry) => entry.income),
            borderColor: "rgb(101, 183, 65)",
            backgroundColor: "rgba(101, 183, 65,0.8)",
            borderRadius: 13,

            fill: false,
          },
          {
            label: "Expense",
            data: financeChartData.map((entry) => entry.expense),
            borderColor: "rgb(251, 139, 36)",
            backgroundColor: "rgba(251, 139, 36,0.8)",
            borderRadius: 13,

            fill: false,
          },
          {
            label: "Profit",
            data: financeChartData.map((entry) => entry.profit),
            borderColor: "rgb(126, 48, 225)",
            borderRadius: 13,
            backgroundColor: "rgba(126, 48, 225,0.8)",
            fill: false,
          },
        ],
      };
      setChartData(chartData);
    }
  }, [financeChartData]);

  return (
    <div className='flex flex-col font-space p-8 bg-fyTropic-200/50 dark:bg-forestydark-600 rounded-[30px] gap-5'>
      <div className='flex flex-col gap-5 w-full'>
        {financeData && (
          <div className='grid grid-cols-3 gap-5 w-full font-space'>
            <button
              onClick={() => navigate("/finance/incomes")}
              className='flex flex-row w-full p-5 bg-white dark:bg-neutral-700/30 rounded-3xl gap-3'
            >
              <div className='w-[60px] h-[60px] p-3 bg-green-200/80 dark:bg-green-900 rounded-2xl'>
                <SellingAndBuyingIcon />
              </div>
              <div className='flex flex-col justify-center items-start'>
                <span className='text-[12px] text-neutral-500 dark:text-neutral-400'>
                  {"Tushum"}
                </span>
                <span className='text-[24px] dark:text-white text-neutral-900 font-semibold'>
                  {`UZS ${getMoneyPattern(financeData?.incomesAmount, ",")}`}
                </span>
              </div>
            </button>

            <button
              onClick={() => navigate("/finance/expenses")}
              className='flex flex-row w-full p-5 bg-white dark:bg-neutral-700/30 rounded-3xl gap-3'
            >
              <div className='w-[60px] h-[60px] p-3 bg-orange-100/90 dark:bg-orange-900 rounded-2xl'>
                <SellingAndBuyingIcon type='buy' />
              </div>
              <div className='flex flex-col justify-center items-start'>
                <span className='text-[12px] text-neutral-500 dark:text-neutral-400'>
                  {"Xarajatlar"}
                </span>
                <span className='text-[24px] dark:text-white text-neutral-900 font-semibold'>
                  {`UZS ${getMoneyPattern(financeData?.expensesAmount, ",")}`}
                </span>
              </div>
            </button>

            <div className='flex flex-row w-full p-5 bg-white dark:bg-neutral-700/30 rounded-3xl gap-3'>
              <div className='w-[60px] h-[60px] p-3 bg-purple-100/90 dark:bg-purple-950 rounded-2xl'>
                <ProfitIcon />
              </div>
              <div className='flex flex-col justify-center'>
                <span className='text-[12px] text-neutral-500 dark:text-neutral-400'>
                  {"Umumiy foyda"}
                </span>
                <span className='text-[24px] dark:text-white text-neutral-900 font-semibold'>
                  {`UZS ${getMoneyPattern(financeData?.profitAmount, ",")}`}
                </span>
              </div>
            </div>
          </div>
        )}
        <div className='flex flex-col w-full bg-white dark:bg-neutral-800/50 rounded-3xl p-5'>
          <div className='flex flex-row justify-between'>
            <div className='flex flex-row items-center'>
              <span className='text-[14px] pl-5'>
                {`${date.format(
                  new Date(ranges.startDate),
                  "DD MMM YYYY"
                )} -  ${date.format(new Date(ranges.endDate), "DD MMM YYYY")}`}
              </span>
            </div>

            <Popover placement='right'>
              <PopoverTrigger>
                <Button>{"Vaqt oralig'ini tanlash"}</Button>
              </PopoverTrigger>
              <PopoverContent>
                <div className='px-1 py-2 font-space'>
                  <DateRange
                    rangeColors={["#00a652", "#00a652", "#00a652"]}
                    shownDate={false}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={1}
                    ranges={[ranges]}
                    placement='bottomEnd'
                    onChange={(r) => setRanges(r.selection)}
                  />
                </div>
              </PopoverContent>
            </Popover>
          </div>

          {chartData && (
            <div className='relative m-auto w-full'>
              <Line
                className='w-[80%] text-white'
                options={getOptions(theme)}
                data={chartData}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FinancePage;
