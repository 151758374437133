import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import date from "date-and-time";
import { useNavigate, useParams } from "react-router-dom";
import {
  Avatar,
  Divider,
  Code,
  Progress,
  Spinner,
  Badge,
  Tabs,
  Tab,
} from "@nextui-org/react";
import UserStatus from "../../../components/Status/UserStatus";
import { getAge, joinedDate } from "../../../utils/date";
import { sentenceCase } from "../../../utils/data";
import { getMoneyPattern, getPhoneNumberPattern } from "../../../utils/regex";
import { getProfileAvatar, getProfileAvatarById } from "../../../utils/profile";

const EmployeeDetails = ({ employee }) => {
  const navigate = useNavigate();
  return (
    <div className='flex flex-col w-full'>
      <div className='grid grid-cols-2 gap-5'>
        <div className='flex flex-col bg-white dark:bg-neutral-800/50 w-full rounded-3xl p-8'>
          <div className='flex flex-row gap-5 items-center'>
            <Avatar
              size='lg'
              css={{ border: "$white", width: "80px", height: "80px" }}
              src={getProfileAvatarById(employee?.employee?.profilePhotoId)}
              //   src={
              //     defaultImage
              //     // employee?.employee?.user?.avatar != null
              //     //   ? `https://platform.foresty.uz/api/image/${employee?.employee?.user?.avatar.id}`
              //     //   : defaultImage
              //   }
              isBordered
              color='success'
            />
            <div className='flex flex-col'>
              <span className='flex flex-row gap-3 items-center'>
                <span className='text-[18px] font-raleway font-extrabold'>
                  {`${employee?.employee?.user?.lastName} ${employee?.employee?.user?.firstName} ${employee?.employee?.user?.fatherName}`}
                </span>
                {/* <UserStatus status={employee?.status} isText={true} /> */}
              </span>
              <div className='flex flex-row items-center gap-2'>
                <span className='text-[14px] font-bold text-forestydark-100'>
                  {`Yoshi ${getAge(employee?.employee?.user?.birthDate)}`}
                </span>
                <span className='w-[5px] h-[5px] rounded-full bg-forestydark-100' />
                <span className='text-[14px] font-bold text-forestydark-100 '>
                  {sentenceCase(employee?.employee?.user?.role?.roleName)}
                </span>
              </div>
            </div>
          </div>
          <Divider className='my-4' />
          <div className='flex flex-row justify-between'>
            <div className='w-full flex flex-col justify-center items-center'>
              <span className='text-[12px]'>{"Username"}</span>
              <Code color='default' className='mt-1'>
                {" "}
                {employee?.employee?.user?.username}
              </Code>
            </div>
            <Divider orientation='vertical' />
            <div className='w-full flex flex-col justify-center items-center'>
              <span className='text-[12px]'>{"Email"}</span>
              <Code color='default' className='mt-1'>
                {employee?.employee?.user?.email}
              </Code>
            </div>
          </div>
          <Divider className='my-4' />

          <div className='flex flex-row justify-between'>
            <div className='w-full flex flex-col justify-center items-center'>
              <span className='text-[12px]'>{"Tug'ilgan sanasi"}</span>
              <span className='text-[14px] font-space font-semibold text-amber-500'>
                {joinedDate(employee?.employee?.user?.birthDate)}
              </span>
            </div>
            <Divider orientation='vertical' />
            <div className='w-full flex flex-col justify-center items-center'>
              <span className='text-[12px]'>{"Tel. raqami"}</span>
              <Code color='success' className='mt-1'>
                {getPhoneNumberPattern(employee?.employee?.user?.phoneNumber)}
              </Code>
            </div>
          </div>
          {/* <Divider className='my-4' /> */}
        </div>
        <div className='flex flex-col gap-2 bg-white/50 dark:bg-neutral-800/50 p-2 rounded-[30px]'>
          <div className='flex flex-row w-[full] h-[50%] gap-5'>
            <div className='flex flex-col gap-1 items-center justify-center w-[50%] bg-white dark:bg-neutral-700/40 rounded-3xl p-5'>
              <span className='text-[12px] text-center'>
                {"Ish boshlash sanasi"}
              </span>
              <span className='text-[14px] text-center text-orange-500 dark:text-orange-400 font-semibold'>
                {date.format(
                  new Date(employee?.jobStartsDate),
                  "ddd, MMM DD YYYY"
                )}
              </span>
            </div>
            <div className='flex flex-col items-center justify-center w-[50%] bg-white dark:bg-neutral-700/40 rounded-3xl p-5'>
              <span className='text-[12px] text-center'>{"Sinov muddati"}</span>
              <span className='text-[24px] text-center text-blue-500 dark:text-blue-400 font-semibold'>
                {`${employee?.testDaysCount} kun`}
              </span>
            </div>
            <div className='flex flex-col items-center justify-center w-[50%] bg-white dark:bg-neutral-700/40 rounded-3xl p-5'>
              <span className='text-[12px] text-center'>{"Oylik maosh"}</span>
              <span className='text-[18px] font-space text-center text-green-500 dark:text-green-400 font-semibold'>
                {`${getMoneyPattern(employee?.currentSalary)} so'm`}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeDetails;
