import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect } from "react";
import useSelect from "../../customHooks/useSelection";
import { getMinuteNumberString } from "../../utils/group";
import { v4 } from "uuid";
import GroupTimeButton from "./GroupTimeButton";

export default function GroupTimeRow({
  time,
  changeTimeRow,
  dayOrder,
  deleteBtnVisible,
  deleteRow,
}) {
  const [showWeeks, setShowWeeks] = useState(false);
  const [weeks, setWeeks, changeWeekDay, getSelectedDay] = useSelect([
    { name: "Dushanba", isSelected: false, day: 0 },
    { name: "Seshanba", isSelected: false, day: 1 },
    { name: "Chorshanba", isSelected: false, day: 2 },
    { name: "Payshanba", isSelected: false, day: 3 },
    { name: "Juma", isSelected: false, day: 4 },
    { name: "Shanba", isSelected: false, day: 5 },
    { name: "Yakshanba", isSelected: false, day: 6 },
  ]);

  const weeksRef = useRef();

  useEffect(() => {
    setWeeks(
      weeks.map((el) => {
        if (el.day === time.day) {
          el.isSelected = true;
          return el;
        }
        return el;
      })
    );
    document.addEventListener("click", handleClickOutside, true);
  }, []);
  function handleClickOutside(event) {
    if (weeksRef.current && !weeksRef.current.contains(event.target)) {
      setShowWeeks(false);
    }
  }

  return (
    <div className='flex flex-row w-full justify-between items-center relative'>
      <div className='flex flex-row gap-5 items-center'>
        <span className='text-[12px] font-bold dark:text-foresty-white/80'>
          {`${dayOrder}-kun`}
        </span>
        <div className='relative'>
          <button
            onClick={() => {
              setShowWeeks(!showWeeks);
            }}
            className='flex items-center h-[30px] text-[14px] px-3 rounded-full text-foresty-white bg-foresty-500'
          >
            {getSelectedDay()?.name}
            <FontAwesomeIcon icon={faCaretDown} className='ml-3' />
          </button>
          {showWeeks && (
            <div
              ref={weeksRef}
              className='flex flex-col overflow-scroll scrollbar-hide min-w-[110px] max-h-[130px] absolute w-full bg-foresty-white/80 dark:bg-forestydark-400/50 backdrop-blur-lg rounded-xl shadow-md mt-[5px] p-1 gap-1 z-10'
            >
              {weeks
                .filter((el) => !el.isSelected)
                .map((item, index) => (
                  <button
                    key={v4()}
                    onClick={() => {
                      changeWeekDay(item.day);
                      setShowWeeks(false);
                      changeTimeRow(dayOrder - 1, "day", item.day);
                    }}
                    className='flex flex-none text-[12px] rounded-md items-center dark:text-foresty-white justify-start h-[25px] px-5 hover:bg-foresty-500/10'
                  >
                    {item.name}
                  </button>
                ))}
            </div>
          )}
        </div>
      </div>
      <div className='flex flex-row items-center gap-3'>
        <div className='flex flex-row items-center gap-1'>
          <GroupTimeButton
            context={time.fromHour}
            change={(num) =>
              changeTimeRow(dayOrder - 1, "fromHour", time.fromHour + num)
            }
          />

          <span className='text-[14px] font-bold dark:text-foresty-white'>
            :
          </span>

          <GroupTimeButton
            context={getMinuteNumberString(time.fromMinute)}
            change={(num) =>
              changeTimeRow(dayOrder - 1, "fromMinute", time.fromMinute + num)
            }
          />
        </div>
        <span className='text-[14px] font-bold dark:text-foresty-white'>-</span>
        <div className='flex flex-row items-center gap-1'>
          <GroupTimeButton
            context={time.toHour}
            change={(num) =>
              changeTimeRow(dayOrder - 1, "toHour", time.toHour + num)
            }
          />

          <span className='text-[14px] font-bold dark:text-foresty-white'>
            :
          </span>

          <GroupTimeButton
            context={getMinuteNumberString(time.toMinute)}
            change={(num) =>
              changeTimeRow(dayOrder - 1, "toMinute", time.toMinute + num)
            }
          />
        </div>
        {deleteBtnVisible && (
          <button
            onClick={() => deleteRow(dayOrder - 1)}
            className='w-[20px] h-[20px] absolute right-[-30px] bg-forestyError/10 p-[2px] rounded-md text-forestyError font-bold'
          >
            <XMarkIcon />
          </button>
        )}
      </div>
    </div>
  );
}
