import * as Yup from "yup";

export const roles = [
  {
    name: "ADMINISTRATOR",
    isSelected: false,
    tooltipText: "tizimdagi barcha funksiyalarni boshqara oladi.",
    id: "ADMIN",
  },
  {
    name: "MODERATOR",
    isSelected: false,
    tooltipText:
      "tizimda moliya bo'limidan boshqa barcha funksiyalarni boshqara oladi.",
    id: "MODERATOR",
  },
  {
    name: "FINANCIER",
    isSelected: false,
    tooltipText: "tizimning moliya qismini boshqaradi.",
    id: "FINANCIER",
  },
];

export const createFields = [
  {
    name: "firstName",
    type: "text",
    label: "Ismi",
    placeholder: "Ismini kiriting...",
    isRequired: true,
  },
  {
    name: "lastName",
    type: "text",
    label: "Familiyasi",
    placeholder: "Familiyasini kiriting...",
    isRequired: true,
  },
  {
    name: "fatherName",
    type: "text",
    label: "Otasining ismi",
    placeholder: "Otasining ismini kiriting...",
    isRequired: true,
  },
  {
    name: "role",
    type: "select",
    label: "Role",
    placeholder: "Roleni tanlang...",
    isRequired: true,
    selectionMode: "single",
  },
  {
    name: "phoneNumber",
    type: "text",
    label: "Tel. raqami",
    // placeholder: "O'quv markaz tel. raqamini kiriting...",
    isRequired: true,
    startContent: "+998",
  },

  {
    name: "birthDate",
    type: "date",
    label: "Tug'ilgan kuni",
    placeholder: "Tug'ilgan kunini kiriting...",
    isRequired: true,
  },
  {
    name: "email",
    type: "text",
    label: "Emaili",
    placeholder: "Emailini kiriting...",
    isRequired: true,
  },
  {
    name: "salary",
    type: "text",
    placeholder: "Oylik maoshni kiriting...",
    label: "Oylik maosh",
    isRequired: true,
    endContent: "so'm",
  },

  {
    name: "jobStartsDate",
    type: "date",
    placeholder: "Ish boshlash sanasini kiriting...",
    label: "Ish boshlash sanasi",
    isRequired: true,
  },

  {
    name: "testDaysCount",
    type: "number",
    placeholder: "Sinov muddatini kiriting...",
    label: "Sinov muddati",
    isRequired: true,
  },
];

export const updateFileds = [
  {
    name: "firstName",
    type: "text",
    label: "Ismi",
    placeholder: "Ismini kiriting...",
    isRequired: true,
  },
  {
    name: "lastName",
    type: "text",
    label: "Familiyasi",
    placeholder: "Familiyasini kiriting...",
    isRequired: true,
  },
  {
    name: "fatherName",
    type: "text",
    label: "Otasining ismi",
    placeholder: "Otasining ismini kiriting...",
    isRequired: true,
  },
  {
    name: "role",
    type: "select",
    label: "Role",
    placeholder: "Roleni tanlang...",
    isRequired: true,
    selectionMode: "single",
  },
  {
    name: "phoneNumber",
    type: "text",
    label: "Tel. raqami",
    isRequired: true,
    startContent: "+998",
  },

  {
    name: "birthDate",
    type: "date",
    label: "Tug'ilgan kuni",
    placeholder: "Tug'ilgan kunini kiriting...",
    isRequired: true,
  },
  {
    name: "username",
    type: "text",
    label: "Username",
    placeholder: "Usernameni kiriting...",
    isRequired: true,
  },
  {
    name: "email",
    type: "text",
    label: "Emaili",
    placeholder: "Emailini kiriting...",
    isRequired: true,
  },
  {
    name: "salary",
    type: "text",
    placeholder: "Oylik maoshni kiriting...",
    label: "Oylik maosh",
    isRequired: true,
    endContent: "so'm",
  },

  {
    name: "jobStartsDate",
    type: "date",
    placeholder: "Ish boshlash sanasini kiriting...",
    label: "Ish boshlash sanasi",
    isRequired: true,
  },

  {
    name: "testDaysCount",
    type: "number",
    placeholder: "Sinov muddatini kiriting...",
    label: "Sinov muddati",
    isRequired: true,
  },
];

export const emptyValues = {
  firstName: "",
  lastName: "",
  fatherName: "",

  email: "",
  phoneNumber: "",
  birthDate: "",

  role: "",
  salary: "",
  jobStartsDate: "",
  testDaysCount: "",
};

export const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("Bo'sh bo'lmasligi kerak!"),
  lastName: Yup.string().required("Bo'sh bo'lmasligi kerak!"),
  fatherName: Yup.string().required("Bo'sh bo'lmasligi kerak!"),

  email: Yup.string()
    .email("Yaroqsiz email!")
    .required("Bo'sh bo'lmasligi kerak!"),
  phoneNumber: Yup.string()
    .matches(/^\(\d{2}\) \d{3}-\d{2}-\d{2}$/, "Tel. raqamni to'g'ri kiriting!")
    .required("Bo'sh bo'lmasligi kerak!"),
  birthDate: Yup.string().required("Bo'sh bo'lmasligi kerak!"),

  role: Yup.string().required("Bo'sh bo'lmasligi kerak!"),
  salary: Yup.string()
    // .min(100000, "Oylik maosh noto'g'ri kiritildi!")
    // .max(50000000, "Oylik maosh noto'g'ri kiritildi!")
    .required("Bo'sh bo'lmasligi kerak!"),
  jobStartsDate: Yup.string().required("Bo'sh bo'lmasligi kerak!"),
  testDaysCount: Yup.number()
    .min(1, "Sinov muddati noto'g'ri kiritildi!")
    .max(90, "Sinov muddati noto'g'ri kiritildi!")
    .required("Bo'sh bo'lmasligi kerak!"),
});

export const updateValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("Bo'sh bo'lmasligi kerak!"),
  lastName: Yup.string().required("Bo'sh bo'lmasligi kerak!"),
  fatherName: Yup.string().required("Bo'sh bo'lmasligi kerak!"),

  email: Yup.string()
    .email("Yaroqsiz email!")
    .required("Bo'sh bo'lmasligi kerak!"),
  username: Yup.string().required("Bo'sh bo'lmasligi kerak!"),
  phoneNumber: Yup.string()
    .matches(/^\(\d{2}\) \d{3}-\d{2}-\d{2}$/, "Tel. raqamni to'g'ri kiriting!")
    .required("Bo'sh bo'lmasligi kerak!"),
  birthDate: Yup.string().required("Bo'sh bo'lmasligi kerak!"),

  role: Yup.string().required("Bo'sh bo'lmasligi kerak!"),
  salary: Yup.string()
    // .min(100000, "Oylik maosh noto'g'ri kiritildi!")
    // .max(50000000, "Oylik maosh noto'g'ri kiritildi!")
    .required("Bo'sh bo'lmasligi kerak!"),
  jobStartsDate: Yup.string().required("Bo'sh bo'lmasligi kerak!"),
  testDaysCount: Yup.number()
    .min(1, "Sinov muddati noto'g'ri kiritildi!")
    .max(90, "Sinov muddati noto'g'ri kiritildi!")
    .required("Bo'sh bo'lmasligi kerak!"),
});
