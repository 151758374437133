import React from "react";
import { getMoneyPattern } from "../../utils/regex";
import { joinedDate } from "../../utils/date";
import { Avatar } from "@nextui-org/react";
import { getHeaderImageSrc, getLogoSrc } from "../../utils/profile";
import { useDispatch, useSelector } from "react-redux";
import { SocialLinkButton } from "../../components/Buttons/ProfileButton";

import CreateEditModal from "../../components/Modal/CreateEditModal";
import {
  fillBalanceEmptyValues,
  fillBalanceFields,
  fillBalanceValidationSchema,
} from "./data/balanceForm";
import { createPaymentOrder } from "../FinancePage/financeSlice";
import ActivateButtonModal from "../../components/Buttons/activateButtonModal";
import { activateCurrentPlan } from "../ProfilePage/profileSlice";

const SettingsMainPage = ({ eduCenter }) => {
  const dispatch = useDispatch();
  const { socialLinks } = useSelector((state) => state.profile);
  return (
    <div className='flex flex-row font-space gap-5 '>
      <div className='grow flex flex-col relative bg-fyTropic-200/50 dark:bg-forestydark-500 rounded-[30px] overflow-hidden'>
        <div className='w-full h-[280px]'>
          <img
            className='h-full w-full object-cover bg-contain bg-no-repeat'
            src={getHeaderImageSrc(eduCenter?.headerImage)}
          />
        </div>

        <div className='flex relative flex-col px-8 pb-[50px]'>
          <div className='flex flex-row h-[100px] py-3 gap-5 px-5'>
            <div className='w-[130px] h-[130px] relative top-[-65px]'>
              <div className='w-[130px] h-[130px] absolute bottom-0 left-0'>
                <Avatar
                  isBordered
                  className='w-[130px] h-[130px] z-0 border-white'
                  src={getLogoSrc(eduCenter?.logo)}
                  bordered
                  color='success'
                />
              </div>
            </div>
            <div className='flex flex-col leading-7'>
              <span className='text-[22px] font-raleway font-extrabold'>
                {eduCenter?.name}
              </span>
              <span className='text-[16px] font-bold text-foresty-500'>
                {`@${eduCenter?.username}`}
              </span>
            </div>
          </div>
          {socialLinks && (
            <>
              {" "}
              <span className='text-[14px] font-bold ml-8 mt-5'>
                {"Ijtimoiy tarmoqlar"}
              </span>
              <div className='flex flex-row flex-wrap gap-5 ml-5 mt-2'>
                {Object.keys(socialLinks)
                  .filter(
                    (el) =>
                      socialLinks[el] &&
                      socialLinks[el] != "" &&
                      !el.includes("education") &&
                      !el.includes("id") &&
                      !el.includes("create") &&
                      !el.includes("update")
                  )
                  .map((el) => (
                    <SocialLinkButton icon={el} text={socialLinks[el]} />
                  ))}
              </div>
            </>
          )}
          <span className='text-[14px] font-bold ml-8 mt-8'>
            {"Markaz haqida"}
          </span>
          <div className='flex flex-row flex-wrap gap-5 ml-5 mt-2'>
            <pre className='text-[14px] font-space break-words overflow-auto whitespace-pre-wrap'>
              {eduCenter?.description}
            </pre>
          </div>
        </div>
      </div>
      <div className='flex flex-none flex-col gap-3 w-[400px] h-fit bg-fyTropic-200/50 dark:bg-forestydark-400/40 p-5 rounded-[30px]'>
        <div className='flex flex-col relative bg-foresty-white dark:bg-forestydark-400/50 py-5 px-8 w-full rounded-lg h-fit'>
          {/* <button className="absolute w-[25px] h-[25px] right-3 top-3 p-[3px] bg-forestydark-100/10 rounded-full">
            <ClockIcon className=" text-forestydark-200 stroke-[2px]" />
          </button> */}
          <span className='text-[14px] text-foresty-500 font-bold '>
            {"Umumiy balans"}
          </span>
          <span className='text-[24px] text-fyTropic dark:text-foresty-white font-bold'>
            {`UZS ${getMoneyPattern(eduCenter?.balance, ",")}`}
          </span>
        </div>
        <CreateEditModal
          // loading={paymentLoading}
          button={
            <button className='w-full py-2 bg-fyTropic-200 text-forestydark-200 dark:text-forestydark-100 dark:bg-forestydark-400 font-bold font-raleway rounded-lg text-[14px]'>
              {"Hisobni to'ldirish"}
            </button>
          }
          title={"Balansni to'dirish"}
          // loading={loading}

          fields={fillBalanceFields}
          initialValues={fillBalanceEmptyValues}
          validationSchema={fillBalanceValidationSchema(1000, 50000000)}
          submitBtnText={"To'lov qilish"}
          handleSubmit={(body) => {
            dispatch(createPaymentOrder(body));
          }}
        />

        <div
          className={
            eduCenter?.currentPlan?.status === "ACTIVE"
              ? "flex flex-col gap-[1px] py-4 px-6 bg-gradient-to-tl from-foresty-600 to-foresty-500 shadow-lg mt-3 rounded-xl"
              : "flex flex-col gap-[1px] py-4 px-6 bg-gradient-to-tl from-forestyError to-forestyWarning shadow-lg mt-3 rounded-xl"
          }
        >
          <span className='text-[14px] text-foresty-white/80'>Joriy tarif</span>

          <span className='mt-1 text-[28px] font-raleway font-bold text-foresty-white'>
            {eduCenter?.currentPlan?.plan.name}
          </span>

          <span className='text-[16px] font-space font-bold text-foresty-white'>
            {`UZS ${getMoneyPattern(
              eduCenter?.currentPlan?.plan.price,
              ","
            )}/oyiga`}
          </span>

          {eduCenter?.currentPlan?.status === "ACTIVE" && (
            <span className='text-[12px] font-space font-normal text-foresty-white text-right mt-2'>
              {"Keyingi to'lov yechilish sanasi"} <br />{" "}
              {joinedDate(eduCenter?.currentPlan?.expireDate)}
            </span>
          )}
          <span className='text-[12px] font-space font-normal text-foresty-white text-right mt-2'>
            {eduCenter?.currentPlan?.status.description}
          </span>
        </div>
        {eduCenter?.currentPlan?.status === "INACTIVE" && (
          <ActivateButtonModal
            balance={eduCenter?.balance}
            price={eduCenter?.currentPlan?.plan.price}
            submitHandler={() => dispatch(activateCurrentPlan())}
          />
        )}
      </div>
    </div>
  );
};

export default SettingsMainPage;
