import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash";
import Api from "../../config/Api";
import { toast } from "react-toastify";

export const getHomePageData = createAsyncThunk(
  "home/getData",
  async (body = {}) => {
    const response = await Api.get("user/homeData");
    return response.data;
  }
);

export const getHomeCountsForChart = createAsyncThunk(
  "home/getCountsChart",
  async (body = {}) => {
    const response = await Api.get("user/countsChart");
    return response.data;
  }
);

export const getHomeDebtors = createAsyncThunk(
  "home/getDebtors",
  async (body = {}) => {
    const response = await Api.get("user/homeDebtors");
    return response.data;
  }
);

const homeSlice = createSlice({
  name: "home",
  initialState: {
    counts: null,
    chartData: null,
    debtors: null,
    loading: false,
    error: null,
  },
  reducers: {
    resetError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      ///------------ GET subjects ------------------/////
      .addCase(getHomePageData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getHomePageData.fulfilled, (state, action) => {
        state.loading = false;
        state.counts = action.payload?.data;
      })
      .addCase(getHomePageData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ GET subjects ------------------/////
      .addCase(getHomeDebtors.pending, (state) => {
        state.loading = true;
      })
      .addCase(getHomeDebtors.fulfilled, (state, action) => {
        state.loading = false;
        state.debtors = action.payload?.data;
      })
      .addCase(getHomeDebtors.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ GET subjects ------------------/////
      .addCase(getHomeCountsForChart.pending, (state) => {
        state.loading = true;
      })
      .addCase(getHomeCountsForChart.fulfilled, (state, action) => {
        state.loading = false;

        let respData = action.payload?.data;
        console.log(respData);

        if (
          respData?.employee !== null &&
          respData?.teacher !== null &&
          respData?.group !== null &&
          respData?.student !== null
        ) {
          const extractedData = Object.entries(respData).map(
            ([key, values]) => {
              return values.map(({ count, date }) => ({ key, count, date }));
            }
          );

          // Step 2: Merge data based on date
          const mergedData = {};
          extractedData.forEach((dataArray) => {
            dataArray.forEach(({ key, count, date }) => {
              if (!mergedData[date]) {
                mergedData[date] = { date };
              }
              mergedData[date][key] = count;
            });
          });

          // Step 3: Sort the merged data by date
          const sortedData = Object.values(mergedData).sort(
            (a, b) => new Date(a.date) - new Date(b.date)
          );

          // Step 4: Convert to the desired format
          const finalResult = sortedData.map(
            ({ date, employee, group, student, teacher }) => ({
              date,
              employees: employee || 0,
              groups: group || 0,
              students: student || 0,
              teachers: teacher || 0,
            })
          );

          console.log(finalResult);

          state.chartData = finalResult;
        } else {
          state.chartData = [];
        }
      })
      .addCase(getHomeCountsForChart.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetError } = homeSlice.actions;

export default homeSlice.reducer;
