import React, { useEffect } from "react";
import ForestyTable from "../../../components/Table/ForestyTable";
import { useDispatch, useSelector } from "react-redux";
import { getActivitiesByEmployeeId } from "../activitiesSlice";
import {
  INITIAL_VISIBLE_COLUMNS,
  columns,
  searchIndexes,
} from "./data/activitesTable";

const EmployeeActivitesPage = ({ employee }) => {
  // const { employees } = useSelector((state) => state.employees);
  const { activities } = useSelector((state) => state.activities);
  const dispatch = useDispatch();
  // const navigate = useNavigate();

  useEffect(() => {
    //   dispatch(getAllEmployees());
    dispatch(getActivitiesByEmployeeId(employee?.id));
  }, []);
  return (
    <div className='dark:bg-neutral-800/50 bg-white/80 p-3 rounded-[30px]'>
      {" "}
      {activities && (
        <ForestyTable
          isStriped
          //   extraButton={(student) => (
          //     <RemoveStudentFromGroupModal
          //       handleSubmit={(onClose) =>
          //         dispatch(removeStudentFromGroup({ id: student?.id, onClose }))
          //       }
          //       contextText={`${student["student.user.lastName"]} ${student["student.user.firstName"]} ${student["student.user.fatherName"]}`}
          //       button={
          //         <LeaveButton disabled={student.status === "LEAVED"} isLight />
          //       }
          //     />
          //   )}
          searchIndexes={searchIndexes}
          tableData={activities}
          columns={columns}
          initialVisibleColumns={INITIAL_VISIBLE_COLUMNS}
          editButton={null}
          deleteButton={null}
        />
      )}
    </div>
  );
};

export default EmployeeActivitesPage;
