import {
  BeakerIcon,
  ChevronUpDownIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Modal } from "@nextui-org/react";
import React, { useState, useEffect } from "react";
import TimetableItem from "./timetableItem";
import { useNavigate } from "react-router-dom";

import {
  faFlask,
  faSeedling,
  faMagnet,
  faSquareRootVariable,
  faCarBattery,
  faEarthAsia,
  faLandmark,
  faBook,
  faBookOpen,
} from "@fortawesome/free-solid-svg-icons";
import { todayDateText } from "../../utils/date";
import { v4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { getAllGroupLessonsByDate } from "../CalendarPage/calendarSlice";

export default function TimetableBoard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [timeTable, setTimeTable] = useState([]);
  const { groupLessons } = useSelector((state) => state.calendar);

  useEffect(() => {
    let today = new Date();

    dispatch(
      getAllGroupLessonsByDate({
        data: {
          date: new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate(),
            0,
            0,
            0,
            0
          ).toISOString(),
        },
      })
    );
  }, []);

  return (
    <div className='flex flex-col w-full py-5 px-2 pb-8 bg-foresty-white/80 dark:bg-forestydark-500 rounded-[30px] gap-3 font-space'>
      <div className='flex flex-row justify-between'>
        <div className='flex flex-col pl-5'>
          <span className='text-[18px] font-extrabold text-fyTropic dark:text-foresty-white'>
            Jadval
          </span>
          <span className='text-[14px] font-medium text-forestydark-200'>
            {todayDateText(true)}
          </span>
        </div>
        <button
          onClick={() => navigate("/calendar")}
          className='w-[25px] h-[25px] mr-3 p-[2px] rounded-lg hover:bg-forestydark-400/5 dark:hover:bg-foresty-white/5'
        >
          <ChevronUpDownIcon className='rotate-[45deg] stroke-[1.5px] stroke-forestydark-300 dark:stroke-foresty-white/50' />
        </button>
      </div>
      <div className='flex flex-col w-full gap-8'>
        <div className='flex flex-col w-full gap-3 items-center h-fit max-h-[300px] overflow-auto p-3'>
          {groupLessons &&
            groupLessons.map((item) => (
              <TimetableItem
                lessonTime={item}
                key={v4()}
                color={"rgba(28,29,84)"}
                checked={true}
                icon={faFlask}
              />
            ))}
          {groupLessons?.length == 0 && (
            <span className='text-[14px] font-raleway text-[#999]'>
              Ma'lumotlar mavjud emas.
            </span>
          )}

          {/* <TimetableItem
            color={"rgba(28,29,84)"}
            checked={true}
            icon={faFlask}
          />
          <TimetableItem
            color={"rgba(254,189,0)"}
            checked={true}
            icon={faSeedling}
          />

          <TimetableItem color={"rgba(128,0,0)"} checked={true} icon={faBook} />
          <TimetableItem
            color={"rgba(255,116,0)"}
            checked={true}
            icon={faBookOpen}
          />

          <TimetableItem
            color={"rgba(68,121,255)"}
            checked={false}
            icon={faEarthAsia}
          />
          <TimetableItem
            color={"rgba(158,115,199)"}
            checked={false}
            icon={faCarBattery}
          />
          <TimetableItem
            color={"rgba(111,67,42)"}
            checked={false}
            icon={faLandmark}
          />
          <TimetableItem
            color={"rgba(34,139,34)"}
            checked={false}
            icon={faSquareRootVariable}
          /> */}
        </div>
      </div>
    </div>
  );
}
