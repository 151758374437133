import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash";
import Api from "../../config/Api";
import { toast } from "react-toastify";
import { Base64 } from "js-base64";

export const getAllBalancePayment = createAsyncThunk(
  "finance/getBalancePayment",
  async (body = {}) => {
    const response = await Api.get("/balancePayment");
    return response.data;
  }
);

export const createPaymentOrder = createAsyncThunk(
  "finance/createPaymentOrder",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.post("/paymentOrder", {
        ...body,
        amount: body?.amount * 100,
      });
      const stringForBase64 = `m=64302a365a04ae2eb6227229;ac.order=${response.data.data.id};a=${response.data.data.orderSum}`;
      const base64 = Base64.encode(stringForBase64);

      const url = `https://checkout.paycom.uz/${base64}`;
      window.open(url, "_blank");
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAllFinanceData = createAsyncThunk(
  "finance/getFinanceData",
  async (body = {}) => {
    const response = await Api.get("/finance");
    return response.data;
  }
);

export const getAllFinanceIncomes = createAsyncThunk(
  "finance/getAllIncomes",
  async (body = {}) => {
    const response = await Api.get("/finance/incomes");
    return response.data;
  }
);

export const getAllFinanceExpenses = createAsyncThunk(
  "finance/getAllExpenses",
  async (body = {}) => {
    const response = await Api.get("/finance/expenses");
    return response.data;
  }
);

export const createPayment = createAsyncThunk(
  "finance/create",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.post("/finance", body);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getFinanceByDaily = createAsyncThunk(
  "finance/getByDaily",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.post("/finance/daily", body);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const updatePayment = createAsyncThunk(
  "finance/update",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.put(`/finance/${body?.id}`, body);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const deletePayment = createAsyncThunk(
  "finance/delete",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.delete(`/finance/${body?.id}`);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const financeSlice = createSlice({
  name: "finance",
  initialState: {
    chartData: null,
    financeData: null,
    balancePayments: null,

    finance: null,
    loading: false,
    error: null,
  },
  reducers: {
    resetError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder

      ///------------ CREATE payment ORDER ------------------/////
      .addCase(createPaymentOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(createPaymentOrder.fulfilled, (state, { payload }) => {
        state.loading = false;
        toast.success(payload?.message);
      })
      .addCase(createPaymentOrder.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        toast.error(payload?.message);
      })

      ///------------ GET finance ------------------/////
      .addCase(getAllBalancePayment.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllBalancePayment.fulfilled, (state, action) => {
        state.loading = false;
        state.balancePayments = action.payload?.data?.sort(
          (a, b) => a.created_at < b.created_at
        );
      })
      .addCase(getAllBalancePayment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ GET finance ------------------/////
      .addCase(getAllFinanceData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllFinanceData.fulfilled, (state, action) => {
        state.loading = false;
        state.financeData = action.payload?.data;
      })
      .addCase(getAllFinanceData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ GET finance incomes ------------------/////
      .addCase(getAllFinanceIncomes.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllFinanceIncomes.fulfilled, (state, action) => {
        state.loading = false;
        state.finance = action.payload?.data;
      })
      .addCase(getAllFinanceIncomes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ GET finance expenses ------------------/////
      .addCase(getAllFinanceExpenses.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllFinanceExpenses.fulfilled, (state, action) => {
        state.loading = false;
        state.finance = action.payload?.data;
      })
      .addCase(getAllFinanceExpenses.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ GET finance expenses ------------------/////
      .addCase(getFinanceByDaily.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFinanceByDaily.fulfilled, (state, action) => {
        state.loading = false;
        state.chartData = action.payload?.data;
      })
      .addCase(getFinanceByDaily.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ CREATE finance ------------------/////
      .addCase(createPayment.pending, (state) => {
        state.loading = true;
      })
      .addCase(createPayment.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.finance = [payload?.data, ...state.finance];
        toast.success(payload?.message);
      })
      .addCase(createPayment.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        toast.error(payload?.message);
      })

      ///------------ delete finance ------------------/////
      .addCase(deletePayment.pending, (state) => {
        state.loading = true;
      })
      .addCase(deletePayment.fulfilled, (state, { payload }) => {
        state.loading = false;

        const ctgIndex = findIndex(state.finance, { id: payload?.data?.id });
        state.finance.splice(ctgIndex, 1);
        toast.success(payload?.message);
      })
      .addCase(deletePayment.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        toast.error(payload?.message);
      })

      ///------------ UPDATE finance ------------------/////
      .addCase(updatePayment.pending, (state) => {
        state.loading = true;
      })
      .addCase(updatePayment.fulfilled, (state, { payload }) => {
        state.loading = false;

        const ctgIndex = findIndex(state.finance, { id: payload?.data?.id });
        state.finance[ctgIndex] = payload?.data;
        toast.success(payload?.message);
      })
      .addCase(updatePayment.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        toast.error(payload?.message);
      });
  },
});

export const { resetError } = financeSlice.actions;

export default financeSlice.reducer;
