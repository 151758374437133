import {
  UserCircleIcon,
  CubeIcon,
  UserGroupIcon,
  CalendarDaysIcon,
  BanknotesIcon,
  WrenchScrewdriverIcon,
  DocumentDuplicateIcon,
  RectangleStackIcon,
  Squares2X2Icon,
  BuildingOffice2Icon,
  ChatBubbleBottomCenterIcon,
  PaperAirplaneIcon,
  ChatBubbleLeftRightIcon,
  AcademicCapIcon,
  UsersIcon,
} from "@heroicons/react/24/solid";

export const TABS = {
  GROUPS: "GROUPS",
  TEACHERS: "TEACHERS",
  STUDENTS: "STUDENTS",
  EMPLOYEES: "EMPLOYEES",
};

export const tabs = [
  {
    title: (
      <div className='flex items-center space-x-2'>
        <Squares2X2Icon className='w-[20px]' />
        <span>{"Guruhlar"}</span>
      </div>
    ),
    key: TABS.GROUPS,
  },

  {
    title: (
      <div className='flex items-center space-x-2'>
        <AcademicCapIcon className='w-[20px]' />
        <span>{"O'quvchilar"}</span>
      </div>
    ),
    key: TABS.STUDENTS,
  },
  {
    title: (
      <div className='flex items-center space-x-2'>
        <UserGroupIcon className='w-[20px]' />
        <span>{"O'qituvchilar"}</span>
      </div>
    ),
    key: TABS.TEACHERS,
  },
  {
    title: (
      <div className='flex items-center space-x-2'>
        <UsersIcon className='w-[20px]' />
        <span>{"Xodimlar"}</span>
      </div>
    ),
    key: TABS.EMPLOYEES,
  },
];
