export const columns = [
  { name: "ID", uid: "rowOrder", sortable: true },
  { name: "F.I.Sh", uid: "fullName", sortable: true },

  { name: "Role", uid: "user.role", sortable: true },
  //   { name: "FAMILIYA", uid: "user.lastName", sortable: true },
  //   { name: "OTASINING ISMI", uid: "user.fatherName", sortable: true },
  //   { name: "Status", uid: "user.status", sortable: true },
  { name: "TEL. RAQAM", uid: "user.phoneNumber", sortable: true },

  // { name: "STATUS", uid: "status", sortable: true },
  // { name: "ACTIONS", uid: "actions" },
];

export const INITIAL_VISIBLE_COLUMNS = [
  "rowOrder",
  "fullName",
  //   "user.firstName",
  //   "user.lastName",
  //   "user.fatherName",
  "user.role",
  "user.phoneNumber",
];

export const searchIndexes = [
  ["user", "firstName"],
  ["user", "lastName"],
  ["user", "fatherName"],
  ["user", "phoneNumber"],
];
