import {
  UserCircleIcon,
  CubeIcon,
  UserGroupIcon,
  CalendarDaysIcon,
  BanknotesIcon,
  WrenchScrewdriverIcon,
  DocumentDuplicateIcon,
  RectangleStackIcon,
  Squares2X2Icon,
  BuildingOffice2Icon,
  ChatBubbleBottomCenterIcon,
} from "@heroicons/react/24/solid";

export const tabs = [
  {
    title: (
      <div className='flex items-center space-x-2'>
        <BuildingOffice2Icon className='w-[20px]' />
        <span>{"Markaz ma'lumotlari"}</span>
      </div>
    ),
    key: "details",
  },

  {
    title: (
      <div className='flex items-center space-x-2'>
        <BanknotesIcon className='w-[20px]' />
        <span>{"To'lovlar"}</span>
      </div>
    ),
    key: "payments",
  },

  {
    title: (
      <div className='flex items-center space-x-2'>
        <ChatBubbleBottomCenterIcon className='w-[20px]' />
        <span>{"Sms xizmat"}</span>
      </div>
    ),
    key: "sms",
  },

  {
    title: (
      <div className='flex items-center space-x-2'>
        <WrenchScrewdriverIcon className='w-[20px]' />
        <span>{"Sozlamalar"}</span>
      </div>
    ),
    key: "settings",
  },
];
