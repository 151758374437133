export const columns = [
  { name: "ID", uid: "rowOrder", sortable: true },
  //   { name: "NOMI", uid: "name", sortable: true },
  { name: "To'lov qiymati", uid: "paySum", sortable: true },
  { name: "To'lov turi", uid: "type", sortable: true },

  { name: "SANASI", uid: "created_at", sortable: true },
  // { name: "STATUS", uid: "status", sortable: true },
];

export const INITIAL_VISIBLE_COLUMNS = [
  "rowOrder",
  "paySum",
  "type",

  //   "name",
  "created_at",
];

export const searchIndexes = ["paySum"];
