import React from "react";

export default function HomePageCard({ card }) {
  return (
    <div className='w-[180px] bg-gradient-to-tl dark:from-forestydark-200/80 dark:via-forestydark-500 dark:to-forestydark-200/80  from-fyTropic-200 via-fyTropic-200 to-fyTropic-200 p-[0.8px] h-fit rounded-[28px] shadow-lg'>
      <div className='relative group flex flex-col w-full h-fit rounded-[28px] bg-foresty-white dark:bg-forestydark-500 py-6 px-6 gap-1'>
        <div className='absolute rounded-[28px] w-full h-full -mt-6 -ml-6 bg-gradient-to-tr from-forestydark-300 via-forestydark-500 to-forestydark-300 opacity-0 dark:group-hover:opacity-100 transition duration-500 ease-in-out'></div>
        <div className='flex flex-row justify-start items-center gap-3 relative z-10'>
          {card.icon}
          <span className='text-[14px] dark:text-foresty-white/50 font-space font-semibold overflow-hidden'>
            {card.title}
          </span>
        </div>
        <div className='flex flex-wrap w-full items-center justify-between relative z-10'>
          <span className='text-fyTropic ml-1 dark:text-foresty-white font-space font-black text-[36px] text-center'>
            {card.count}
          </span>
          {/* <span className='bg-foresty-500/20 rounded-lg py-1 px-2 text-foresty-500 font-space font-bold text-[12px]'>
            {card.percentage}
          </span> */}
        </div>
      </div>
    </div>
  );
}
