import React, { useEffect } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import {
  Input,
  CheckboxGroup,
  Checkbox,
  Link,
  User,
  Chip,
  cn,
  ScrollShadow,
  ModalBody,
  ModalFooter,
  Button,
} from "@nextui-org/react";
import { getAllStudents } from "../../StudentsPage/studentsSlice";
import { getColorStatus } from "../../../utils/status";
import * as JsSearch from "js-search";
import { searchIndexesStudentsModal } from "./data/studentsTable";
import { getPhoneNumberPattern } from "../../../utils/regex";
import defaultImage from "../../../assets/foresty_green.png";
import { addStudentToGroup } from "../groupsSlice";

const GroupStudentsModalContent = ({ onClose, groupId }) => {
  const { students } = useSelector((state) => state.students);
  const { addStudentLoading } = useSelector((state) => state.groups);
  const [groupSelected, setGroupSelected] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllStudents());
  }, []);

  const hasSearchFilter = Boolean(searchValue);

  const filteredItems = React.useMemo(() => {
    if (students) {
      let filteredDatas = [...students];
      if (hasSearchFilter) {
        console.log(filteredDatas);

        var search = new JsSearch.Search("id");

        search.indexStrategy = new JsSearch.AllSubstringsIndexStrategy();
        search.searchIndex = new JsSearch.UnorderedSearchIndex();
        JsSearch.StopWordsMap.bob = true;

        searchIndexesStudentsModal.map((element) => {
          search.addIndex(element);
        });

        search.addDocuments([...students]);

        filteredDatas = search.search(searchValue);
      }
      return filteredDatas;
    }
  }, [students, searchValue]);

  const onSearchChange = React.useCallback((value) => {
    if (value) {
      setGroupSelected([]);
      setSearchValue(value);
    } else {
      setSearchValue("");
    }
  }, []);

  const onClear = React.useCallback(() => {
    setGroupSelected([]);

    setSearchValue("");
  }, []);

  return (
    students && (
      <>
        <ModalBody>
          <div className='flex flex-col w-full gap-5'>
            <Input
              size='sm'
              variant='bordered'
              classNames={{
                base: "w-full",
                inputWrapper: "border-1 rounded-full",
              }}
              className='rounded-full'
              isClearable
              // className='w-full sm:max-w-[44%]'
              placeholder='Qidirish...'
              startContent={<MagnifyingGlassIcon className='w-[18px]' />}
              value={searchValue}
              onClear={() => onClear()}
              onValueChange={onSearchChange}
            />

            <CheckboxGroup
              value={groupSelected}
              onChange={(e) => {
                e.length > 1 && e.shift();
                setGroupSelected(e);
              }}
            >
              <div className='flex w-full max-h-[50vh]'>
                <ScrollShadow
                  size={40}
                  className='overflow-scroll scrollbar-hide flex flex-col w-full gap-3'
                >
                  {filteredItems.map((st) => (
                    <CustomCheckbox
                      key={st.id}
                      value={st.id}
                      user={{
                        name: `${st?.student.user.lastName} ${st?.student.user.firstName} ${st?.student.user.fatherName}`,
                        avatar:
                          "https://avatars.githubusercontent.com/u/30373425?v=4",
                        username: st?.student.user.username,
                        url: "https://twitter.com/jrgarciadev",
                        role: `+998 ${getPhoneNumberPattern(
                          st?.student.user.phoneNumber
                        )}`,
                        status: st.status,
                      }}
                      statusColor={getColorStatus(st.status)}
                    />
                  ))}
                </ScrollShadow>
              </div>
            </CheckboxGroup>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color='danger' variant='flat' onPress={onClose}>
            {"Bekor qilish"}
          </Button>
          <Button
            onPress={() => {
              dispatch(
                addStudentToGroup({
                  data: { studentId: groupSelected[0], groupId },
                  onClose,
                })
              );
            }}
            isLoading={addStudentLoading}
            disabled={groupSelected.length == 0}
            color={groupSelected.length == 0 ? "default" : "primary"}
          >
            {"Qo'shish"}
          </Button>
        </ModalFooter>
      </>
    )
  );
};

const CustomCheckbox = ({ user, statusColor, value }) => {
  return (
    <Checkbox
      aria-label={user.name}
      classNames={{
        base: cn(
          "inline-flex max-w-full w-full bg-content1 m-0",
          "hover:bg-content2 items-center justify-center",
          "cursor-pointer rounded-lg gap-2 p-4 border-2 border-transparent",
          "data-[selected=true]:border-primary"
        ),
        label: "w-full",
      }}
      value={value}
      className='w-full'
    >
      <div className='w-full flex justify-between gap-2'>
        <User
          avatarProps={{ size: "md", src: defaultImage }}
          description={
            <Link isExternal href={`/students/${value}`} size='sm'>
              @{user.username}
            </Link>
          }
          name={user.name}
        />
        <div className='flex flex-col items-end gap-1'>
          <span className='text-tiny text-default-500'>{user.role}</span>
          <Chip color={statusColor} size='sm' variant='flat'>
            {user.status}
          </Chip>
        </div>
      </div>
    </Checkbox>
  );
};

export default GroupStudentsModalContent;
