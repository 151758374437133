import { useTheme } from "next-themes";
import React from "react";
import { useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  Button,
  useDisclosure,
  ModalFooter,
  ModalBody,
} from "@nextui-org/react";
import { getMoneyPattern } from "../../utils/regex";

export default function ActivateButtonModal({ balance, price, submitHandler }) {
  const { theme } = useTheme();
  const { isOpen, onOpen, onClose, onOpenChange } = useDisclosure();

  return (
    <>
      <button
        onClick={onOpen}
        className='w-full py-3 text-[14px] bg-orange-500 rounded-xl'
      >
        {"Ta'rifni aktivlashtirish"}
      </button>
      <Modal
        closeButton
        aria-labelledby='modal-title'
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        css={{ backgroundColor: theme === "dark" ? "#1f1f1f" : "White" }}
        width='500px'
      >
        <ModalContent>
          <ModalHeader>
            <span className='text-[16px] font-bold font-raleway'>
              Ta'rif rejani aktivlashtirish
            </span>
          </ModalHeader>

          <ModalBody>
            <div className='flex flex-col gap-8 px-5 items-center text-[14px]'>
              {balance < price ? (
                <span className='text-center text-orange-500'>
                  {
                    "Ta'rif rejani faollashtirish uchun hisobingizda yetarli mablag' mavjud emas!"
                  }
                </span>
              ) : (
                <span className='text-center text-green-500'>{`Ta'rif rejani faollashtirish uchun hisobingizdan ${getMoneyPattern(
                  price
                )} so'm mablag' yechiladi!`}</span>
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            <div className='grid grid-cols-2 gap-5 w-full text-[14px] px-5 font-bold font-raleway pb-3'>
              <button
                onClick={onClose}
                className='w-full flex justify-center items-center py-3 rounded-xl dark:bg-forestydark-400 dark:text-forestydark-100 bg-forestydark-100/30 text-forestydark-200'
              >
                {"Bekor qilish"}
              </button>
              <div className='relative pl-[100%]'>
                <button
                  onClick={async () => {
                    const success = await submitHandler();
                    if (success) {
                      onClose();
                    }
                  }}
                  disabled={balance < price}
                  className='absolute w-full ml-[-100%] flex justify-center items-center py-3  rounded-xl bg-foresty-500 disabled:bg-forestydark-100 text-foresty-white'
                >
                  {"Aktivlashtirish"}
                </button>
              </div>
            </div>
            {/* <Button auto onPress={closeHandler} css={{ width: "50%" }}>
          Yo'q
        </Button>
        <Button
          css={{ width: "50%" }}
          auto
          flat
          color="error"
          onPress={async () => {
            await submitHandler();
            closeHandler();
          }}
        >
          Ha
        </Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
