import {
  UserCircleIcon,
  CubeIcon,
  UserGroupIcon,
  CalendarDaysIcon,
  BanknotesIcon,
  WrenchScrewdriverIcon,
  DocumentDuplicateIcon,
  RectangleStackIcon,
  Squares2X2Icon,
  BuildingOffice2Icon,
  ChatBubbleBottomCenterIcon,
  PaperAirplaneIcon,
  ChatBubbleLeftRightIcon,
} from "@heroicons/react/24/solid";

export const tabs = [
  {
    title: (
      <div className='flex items-center space-x-2'>
        <ChatBubbleLeftRightIcon className='w-[20px]' />
        <span>{"Jo'natmalar"}</span>
      </div>
    ),
    key: "messages",
  },

  {
    title: (
      <div className='flex items-center space-x-2'>
        <PaperAirplaneIcon className='w-[20px]' />
        <span>{"SMS yuborish"}</span>
      </div>
    ),
    key: "sendSMS",
  },
];
