import { useSelector } from "react-redux";
import { Outlet, Navigate, useLocation } from "react-router-dom";

export const ProtectRoutes = () => {
  const token = localStorage.getItem("token");
  return token ? <Outlet /> : <Navigate to='/login' exact />;
};

export const RequireAuth = ({ allowedRoles }) => {
  const location = useLocation();
  const { user } = useSelector((state) => state.profile);

  return allowedRoles?.includes(user?.user?.role.roleName) ? (
    <Outlet />
  ) : (
    <Navigate to='/404' state={{ from: location }} replace />
  );
};

export const AuthenticatedRoutes = () => {
  const location = useLocation();
  const token = localStorage.getItem("token");

  return !token ? (
    <Outlet />
  ) : (
    <Navigate to='/' state={{ from: location }} replace />
  );
};

// export const UnverifiedUserRoutes = () => {
//   const location = useLocation();
//   const { unverifiedUser } = useAuth();
//   return unverifiedUser ? (
//     <Outlet />
//   ) : (
//     <Navigate to='/' state={{ from: location }} replace />
//   );
// };
