import { Spinner } from "@nextui-org/react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getEmployee, updateEmployee } from "./employeeSlice";
import VerticalBoard from "../../components/Container/VerticalBoard";
import ForestyForm from "../../components/Form/ForestyForm";
import {
  roles,
  updateFileds,
  updateValidationSchema,
  validationSchema,
} from "./data";
import { getUpdateInitialValues } from "./utils";

const EditEmployeePage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { employee, loading, updateLoading } = useSelector(
    (state) => state.employees
  );

  useEffect(() => {
    dispatch(getEmployee(id));
  }, []);

  return loading ? (
    <Spinner />
  ) : (
    <VerticalBoard>
      <span className='text-[22px] mt-3 dark:text-foresty-white text-center font-bold'>
        {"Xodimni tahrirlash"}
      </span>
      {employee && (
        <ForestyForm
          isLoading={updateLoading}
          colsNum={2}
          initialValues={getUpdateInitialValues(employee)}
          fields={updateFileds.map((el) => {
            if (el.name === "role") {
              el.selectData = roles;
              return el;
            }
            return el;
          })}
          validationSchema={updateValidationSchema}
          handleSubmit={(body) =>
            dispatch(updateEmployee({ ...body, id: employee?.id }))
          }
          submitBtnText='Tahrirlash'
        />
      )}
    </VerticalBoard>
  );
};

export default EditEmployeePage;
