import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@nextui-org/react";

import { useNavigate } from "react-router-dom";
import CreateEditModal from "../../../components/Modal/CreateEditModal";
import { createFields, validationSchema } from "../data";
import { deleteStudent, updateStudent } from "../studentsSlice";
import DeleteModal from "../../../components/Modal/DeleteModal";
import { DeleteButtonText } from "../../../components/Buttons/CRUDbuttons";
import { getUpdateInitialValues } from "./utils/utils";

const StudentSettingsPage = ({ student }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //   useEffect(() => {
  //     dispatch(getAllSubjects());
  //   }, []);
  return (
    student && (
      <div className='flex flex-col items-center'>
        <div className='flex flex-col gap-8 bg-neutral-50 dark:bg-neutral-800/50 p-5 rounded-2xl w-[800px]'>
          <div className='grid grid-cols-1 w-full gap-5'>
            <CreateEditModal
              size='3xl'
              colsNum={2}
              title={"O'quvchi ma'lumotlarini tahrirlash"}
              // loading={loading}
              submitBtnText={"Tahrirlash"}
              button={<Button>{"O'quvchi ma'lumotlarini tahrirlash"}</Button>}
              fields={createFields}
              initialValues={getUpdateInitialValues(student)}
              validationSchema={validationSchema}
              handleSubmit={(body) =>
                dispatch(updateStudent({ ...body, id: student?.id }))
              }
            />
          </div>

          <div className='flex flex-row gap-5 mt-5 rounded-3xl border-[1px] border-red-500 p-4'>
            <DeleteModal
              button={
                <DeleteButtonText>{"O'quvchini o'chirish"}</DeleteButtonText>
              }
              contextText={`${student?.student?.user.lastName} ${student?.student?.user.firstName} ${student?.student?.user.fatherName} o'qituvchini`}
              handleSubmit={() =>
                dispatch(
                  deleteStudent({
                    id: student?.id,
                    navigate: () => navigate("/students"),
                  })
                )
              }
            />
          </div>
        </div>
      </div>
    )
  );
};

export default StudentSettingsPage;
