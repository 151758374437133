import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Select, SelectItem } from "@nextui-org/react";
import React from "react";
import { Icon } from "../../../utils/icons";

const SelectIcon = ({
  field,
  values,
  isSmall,
  touched,
  errors,
  setFieldValue,
}) => {
  return (
    <Select
      size={isSmall ? "sm" : "md"}
      defaultSelectedKeys={new Set([values[field.name]])}
      variant='faded'
      isInvalid={touched[field.name] && Boolean(errors[field.name])}
      errorMessage={errors[field.name]}
      isRequired={field?.isRequired}
      classNames={
        {
          // listboxWrapper: "grid grid-cols-2 border-2 h-40 w-40",
        }
      }
      className='text-black dark:text-white w-full flex flex-row'
      selectionMode={field?.selectionMode}
      // selectedKeys={values[field?.name]}
      // name={field?.name}
      // onSelectionChange={handleChange}
      selectedKeys={new Set([values[field?.name]])}
      label={field.label}
      placeholder={field.placeholder}
      onChange={(e) => {
        setFieldValue(field?.name, e.target.value);
      }}
      renderValue={(items) => {
        return items.map((item) => (
          <div key={item.key} className='flex items-center gap-2'>
            <FontAwesomeIcon
              className='w-[30px] h-[30px]'
              icon={Icon[item.key]}
            />
          </div>
        ));
      }}
    >
      {field?.selectData &&
        field?.selectData.map((selData) => (
          <SelectItem
            textValue={selData?.name}
            variant={selData?.hexCode ? "light" : "flat"}
            style={
              selData?.hexCode && {
                backgroundColor: selData?.hexCode,
                color: "#fff",
              }
            }
            // className='text-black dark:text-white'
            key={selData?.id}
            value={selData?.id}
            renderValue={(items) => {
              return <div className='flex flex-wrap gap-2'>{"fhjdfbh"}</div>;
            }}
          >
            <FontAwesomeIcon
              className='w-[30px] h-[30px]'
              icon={Icon[selData?.id]}
            />
          </SelectItem>
        ))}
    </Select>
  );
};

export default SelectIcon;
