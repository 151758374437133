export const columns = [
  { name: "ID", uid: "rowOrder", sortable: true },
  { name: "NOMI", uid: "groupData.name", sortable: true },
  { name: "STATUS", uid: "groupData.status", sortable: true },
  { name: "O'QITUVCHI", uid: "teacherFullName", sortable: true },
  { name: "OYLIK NARXI", uid: "groupData.price", sortable: true },
  { name: "DARS VAQTLARI", uid: "lessonTimes" },
  // { name: "DARS BOSHLANISH SANASI", uid: "startDate", sortable: true },

  { name: "XONA", uid: "groupData.room.roomNumber", sortable: true },

  { name: "SANASI", uid: "groupData.created_at", sortable: true },
  // { name: "STATUS", uid: "status", sortable: true },
  { name: "ACTIONS", uid: "actions" },
];

export const INITIAL_VISIBLE_COLUMNS = [
  "groupData.status",
  "rowOrder",
  "lessonTimes",
  "teacherFullName",
  "groupData.name",
  "groupData.room.roomNumber",
  "groupData.price",
  // "startDate",
  "groupData.created_at",
  "actions",
];

export const searchIndexes = ["name"];
