import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEmployee } from "../employeeSlice";
import { useNavigate, useParams } from "react-router-dom";
import { Avatar, Progress, Spinner, Badge, Tabs, Tab } from "@nextui-org/react";
import { getPhoneNumberString, sentenceCase } from "../../../utils/data";
import { tabs } from "../data/employee";
import { getColorStatus } from "../../../utils/status";
import EmployeeDetails from "./EmployeeDetails";
import EmployeeDocumentsPage from "./EmployeeDocumentsPage";
import EmployeePaymentsPage from "./EmployeePaymentsPage";
import EmployeeSettingsPage from "./EmployeeSettingsPage";
import { clearDatas } from "../../Documents/documentSlice";
import EmployeeActivitesPage from "./EmployeeActivitesPage";
import { getProfileAvatar, getProfileAvatarById } from "../../../utils/profile";

const EmployeePage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selected, setSelected] = React.useState(
    localStorage.getItem("group-menu") ?? "details"
  );
  const { employee, loading } = useSelector((state) => state.employees);

  useEffect(() => {
    dispatch(clearDatas());

    dispatch(getEmployee(id));
  }, []);

  return loading ? (
    <Spinner />
  ) : (
    <div className='flex flex-col w-full items-center gap-5'>
      <div className='grid grid-cols-3 w-full'>
        <div className='w-fit'>
          <Badge
            content={employee?.status}
            color={getColorStatus(employee?.status)}
            className={
              employee?.status == "FINISHED"
                ? "text-white text-[12px] bg-blue-500"
                : "text-white text-[12px]"
            }
          >
            <div className='flex flex-row bg-white dark:bg-neutral-800 rounded-full p-2 gap-4'>
              <Avatar
                css={{ border: "$white", width: "15px", height: "15px" }}
                src={getProfileAvatarById(employee?.employee?.profilePhotoId)}
                //   src={
                //     defaultImage
                //     // employee?.employee?.user?.avatar != null
                //     //   ? `https://platform.foresty.uz/api/image/${employee?.employee?.user?.avatar.id}`
                //     //   : defaultImage
                //   }
                isBordered
                color='success'
              />
              <div className='flex flex-col justify-center leading-4'>
                <span className='text-[14px] font-raleway font-extrabold mr-4 '>
                  {employee?.employee?.user?.lastName +
                    " " +
                    employee?.employee?.user?.firstName}
                </span>

                <span className='text-[12px] font-bold text-forestydark-100 '>
                  {sentenceCase(employee?.employee?.user?.role?.roleName)}
                </span>
              </div>
            </div>
          </Badge>
        </div>
        <div className='w-full flex justify-center items-center'>
          <Tabs
            selectedKey={selected}
            onSelectionChange={(key) => {
              localStorage.setItem("group-menu", key);
              setSelected(key);
            }}
            size='md'
            radius='full'
            aria-label='Tabs sizes'
          >
            {tabs.map((tab) => (
              <Tab title={tab.title} key={tab.key} />
            ))}
          </Tabs>
        </div>
      </div>
      <div className='bg-white/30 dark:bg-neutral-900 rounded-3xl p-8 w-full'>
        {selected === "details" ? (
          <EmployeeDetails employee={employee} />
        ) : selected === "documents" ? (
          <EmployeeDocumentsPage employee={employee} />
        ) : selected === "payments" ? (
          <EmployeePaymentsPage employee={employee} />
        ) : selected === "activities" ? (
          <EmployeeActivitesPage employee={employee} />
        ) : selected === "settings" ? (
          <EmployeeSettingsPage employee={employee} />
        ) : null}

        {/* {selected === "details" ? (
          <DetailsPage group={group} />
        ) : selected === "students" ? (
          <GroupStudentsPage groupId={group?.id} />
        ) : selected === "lessonTimes" ? (
          <GroupMonthsPage groupId={group?.id} />
        ) : selected === "settings" ? (
          <GroupSettingsPage group={group} />
        ) : selected === "payments" ? (
          <GroupPaymentsPage groupId={group?.id} />
        ) : null} */}
      </div>
    </div>

    // <div className='flex flex-col gap-4 bg-fyTropic-200/50 dark:bg-forestydark-600 w-full rounded-xl dark:text-foresty-white font-space'>
    //   <div className='flex flex-col font-space '>
    //     <div className={getStatusStyle(employee?.status)}>
    //       <div className='flex flex-row items-center justify-between'>
    //         <div className='flex flex-row gap-2 items-center'>
    //           <button
    //             className='flex justify-center items-center h-[20px] w-[20px]'
    //             onClick={() => navigate(-1)}
    //           >
    //             <FontAwesomeIcon
    //               icon={faArrowLeft}
    //               className='text-forestydark-100'
    //             />
    //           </button>
    //           <span className='text-[14px] font-raleway font-extrabold'>
    //             {employee?.employee?.user?.lastName +
    //               " " +
    //               employee?.employee?.user?.firstName}
    //           </span>
    //         </div>

    //         <RoleCheck role={[ROLE.OWNER]}>
    //           <EditProfileButton
    //             onClick={() => navigate(`/employees/edit/${employee?.id}`)}
    //           />
    //         </RoleCheck>
    //       </div>

    //       <div className='flex flex-row gap-5 items-center'>
    //         <Avatar
    //           size='lg'
    //           css={{ border: "$white", width: "80px", height: "80px" }}
    //           //   src={
    //           //     defaultImage
    //           //     // employee?.employee?.user?.avatar != null
    //           //     //   ? `https://platform.foresty.uz/api/image/${employee?.employee?.user?.avatar.id}`
    //           //     //   : defaultImage
    //           //   }
    //           isBordered
    //           color='success'
    //         />
    //         <div className='flex flex-col'>
    //           <span className='flex flex-row gap-3 items-center'>
    //             <span className='text-[22px] font-raleway font-extrabold'>
    //               {employee?.employee?.user?.lastName +
    //                 " " +
    //                 employee?.employee?.user?.firstName}
    //             </span>
    //             <UserStatus status={employee?.status} isText={true} />
    //           </span>
    //           <div className='flex flex-row items-center gap-2'>
    //             <span className='text-[14px] font-bold text-forestydark-100'>
    //               {`Yoshi ${getAge(employee?.employee?.user?.birthDate)}`}
    //             </span>
    //             <span className='w-[5px] h-[5px] rounded-full bg-forestydark-100' />
    //             <span className='text-[14px] font-bold text-forestydark-100 '>
    //               {sentenceCase(employee?.employee?.user?.role?.roleName)}
    //             </span>
    //           </div>
    //         </div>
    //       </div>

    //       <div className='flex flex-row gap-5 px-2'>
    //         <span className='flex flex-row w-full items-center text-[12px] gap-2 font-bold dark:text-foresty-white text-forestydark-300'>
    //           <FontAwesomeIcon
    //             icon={faCalendarDays}
    //             className='mr-1 text-[14px] text-forestydark-100'
    //           />
    //           {` Qo'shilgan ${joinedDate(employee?.created_at)}`}
    //         </span>
    //         {/* <NetworkStatus isOnline={true} /> */}
    //       </div>

    //       <div className='flex flex-row justify-between items-end gap-5'>
    //         <div className='flex flex-row gap-5'>
    //           {/* <EmailButton
    //             enabled={employee?.employee?.user?.enabled}
    //             id={employee?.employee?.user?.id}
    //             email={employee?.employee?.user?.email}
    //           /> */}
    //           <span className='px-8 py-[5px] h-fit bg-foresty-white/50 dark:bg-forestydark-400 border-[1.5px] border-forestydark-100/20 text-[12px] font-bold text-forestydark-200 dark:text-foresty-white/80 rounded-lg'>
    //             <FontAwesomeIcon icon={faPhone} className='mr-2' />
    //             {getPhoneNumberString(employee?.employee?.user?.phoneNumber)}
    //           </span>
    //         </div>
    //         <div className='flex flex-col items-end justify-end font-space'>
    //           <span className='text-[14px] text-forestydark-300 dark:text-foresty-500'>
    //             Oylik maoshi
    //           </span>
    //           <span className='text-[18px] text-fyTropic font-bold dark:text-foresty-white'>
    //             {`${getMoneyPattern(employee?.currentSalary)} so'm`}
    //           </span>
    //         </div>
    //       </div>
    //     </div>

    //     <span className='h-[1px] w-full dark:bg-forestydark-300 bg-forestydark-100/30' />

    //     <div className='grid grid-cols-3 px-8 gap-3 bg-foresty-white/10 dark:bg-forestydark-500 py-5 rounded-b-xl'></div>
    //   </div>
    // </div>
  );
};

export default EmployeePage;
