import React from "react";
import Logo from "../../components/Logo";
import { Formik } from "formik";
import { emptyValues, validationSchema } from "./data";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { Button, Input } from "@nextui-org/react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { login } from "./authSlice";

const LoginPage = () => {
  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => setIsVisible(!isVisible);

  const dispatch = useDispatch();

  return (
    <div className='flex flex-row w-screen h-screen fixed'>
      <div className='flex flex-col justify-center items-center sm:items-start gap-10 sm:justify-between  w-full bg-gradient-to-tl from-fyTropic-100 to-fyTropic-200 dark:to-forestydark-600 dark:from-forestydark-800'>
        <div className='flex flex-col gap-10 justify-center items-center w-full h-full'>
          <a href='/'>
            <Logo color={"#00373d"} height={"40px"} />
          </a>
          <div className='flex flex-col items-center md:w-[440px] w-[90%] bg-[#fff] dark:bg-forestydark-500/80 px-[40px] py-[32px] rounded-[13px] gap-5 shadow-fyTropic/10 dark:shadow-forestydark-900/30 shadow-2xl'>
            <span className='text-[24px] font-lora font-bold dark:text-foresty-white text-fyTropic-500'>
              {"Tizimga kirish !"}
            </span>
            <Formik
              enableReinitialize
              //   validateOnMount={false}
              //   validateOnChange={false}
              //   validateOnBlur={false}
              initialValues={emptyValues}
              validationSchema={validationSchema}
              onSubmit={(body) => dispatch(login(body))}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                touched,
                errors,
                setFieldValue,
              }) => (
                <form
                  onSubmit={async (e) => {
                    e.preventDefault();
                    handleSubmit();
                    // dispatch(login(values)).then(() => navigate("/"));
                  }}
                  className='flex flex-col gap-5 w-full font-madefor mt-8'
                >
                  <Input
                    isInvalid={touched?.username && Boolean(errors?.username)}
                    errorMessage={errors?.username}
                    value={values?.username}
                    type='text'
                    // style={{ color: "black", fontWeight: 500 }}
                    name='username'
                    placeholder='Usernameni kiriting...'
                    label='Username'
                    onChange={handleChange}
                  />
                  <Input
                    isInvalid={touched?.password && Boolean(errors?.password)}
                    errorMessage={errors?.password}
                    value={values?.password}
                    name='password'
                    // style={{ color: "black", fontWeight: 500 }}
                    label='Parol'
                    placeholder='Parolni kiriting...'
                    onChange={handleChange}
                    endContent={
                      <button
                        className='focus:outline-none'
                        type='button'
                        onClick={toggleVisibility}
                      >
                        {isVisible ? (
                          <EyeSlashIcon className='w-[20px] text-2xl text-default-400 pointer-events-none' />
                        ) : (
                          <EyeIcon className='w-[20px] text-2xl text-default-400 pointer-events-none' />
                        )}
                      </button>
                    }
                    type={isVisible ? "text" : "password"}
                  />
                  <Button type='submit' color='primary'>
                    {"Kirish"}
                  </Button>
                </form>
              )}
            </Formik>
            {/* {loc.pathname === "/login/demo" && (
              <div className='flex flex-row items-start gap-2 w-full px-2'>
                <span className='text-[12px] text-orange-500'>Demo uchun</span>
                <div className='flex flex-col gap-1 text-[12px]'>
                  <span>username: adminforesty</span>
                  <span>password: Foresty@123</span>
                </div>
              </div>
            )}
            <ForestyInput
              handleKeyDown={handleKeyDown}
              type='text'
              label='Username'
              placeholder='Usernameni kiriting...'
              name='username'
              value={loginData.username}
              onChange={handleChange}
            />
            <ForestyInput
              handleKeyDown={handleKeyDown}
              type='password'
              label='Parol'
              placeholder='Parolni kiriting...'
              name='password'
              value={loginData.password}
              onChange={handleChange}
            />
            <Spacer y={0} />
            {/* <a
              href=""
              className="text-[13px] font-semibold font-raleway text-neutral-400 hover:text-blue-500"
            >
              {"Akkountni tasdiqlash"}
            </a> */}
            {/* <ForestyButton onClick={loginHandler}>Login</ForestyButton> */}{" "}
          </div>
          <p className='text-[14px] font-lora text-[#00373d] dark:text-forestydark-100'>
            &copy; Barcha huquqlar himoyalangan.
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
