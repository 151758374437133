import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGroupStudents, removeStudentFromGroup } from "../groupsSlice";
import ForestyTable from "../../../components/Table/ForestyTable";
import {
  INITIAL_VISIBLE_COLUMNS,
  columns,
  searchIndexes,
} from "./data/studentsTable";
import EmptyModal from "../../../components/Modal/EmptyModal";
import GroupStudentsModalContent from "./GroupStudentsModalContent";
import RemoveStudentFromGroupModal from "../../../components/Modal/RemoveStudentFromGroupModal";
import { LeaveButton } from "../../../components/Buttons/CRUDbuttons";

const GroupStudentsPage = ({ groupId }) => {
  const { students } = useSelector((state) => state.groups);

  const dispatch = useDispatch();

  useEffect(() => {
    groupId && dispatch(getGroupStudents(groupId));
  }, []);
  return (
    <div className='dark:bg-neutral-800/50 bg-white/80 p-3 rounded-[30px]'>
      {" "}
      {students && (
        <ForestyTable
          isStriped
          extraButton={(student) => (
            <RemoveStudentFromGroupModal
              handleSubmit={(onClose) =>
                dispatch(removeStudentFromGroup({ id: student?.id, onClose }))
              }
              contextText={`${student?.student?.student?.user?.lastName} ${student?.student?.student?.user?.firstName} ${student?.student?.student?.user?.fatherName}`}
              button={
                <LeaveButton disabled={student.status === "LEAVED"} isLight />
              }
            />
          )}
          searchIndexes={searchIndexes}
          //   viewButton={(id) => (
          //     <NavigateViewButton isLight path={`/students/${id}`} />
          //   )}
          createModal={
            <EmptyModal
              title={"O'quvchi qo'shish"}
              content={(onClose) => {
                return (
                  <GroupStudentsModalContent
                    onClose={onClose}
                    groupId={groupId}
                  />
                );
              }}
              size='2xl'
              btnText={"O'quvchi qo'shish"}
            />
          }
          tableData={students}
          columns={columns}
          initialVisibleColumns={INITIAL_VISIBLE_COLUMNS}
          editButton={null}
          deleteButton={null}
        />
      )}
    </div>
  );
};

export default GroupStudentsPage;
