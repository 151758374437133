import * as Yup from "yup";

export const fields = [
  {
    name: "floor",
    type: "select",
    label: "Qavat",
    placeholder: "Qavatni tanlang...",
    isRequired: true,
  },
  {
    name: "roomNumber",
    type: "number",
    label: "Xona raqami",
    placeholder: "Xona raqamini kiriting...",
    isRequired: true,
  },
  {
    name: "roomName",
    type: "text",
    label: "Xona nomi",
    placeholder: "Xona nomini kiriting...",
    // isRequired: true,
  },
];

export const emptyValues = {
  roomName: "",
  roomNumber: "",
  floor: "",
};

export const validationSchema = Yup.object().shape({
  roomName: Yup.string(),
  roomNumber: Yup.number("Sonlarda kiriting!").required(
    "Nomi bo'sh bo'lmasligi kerak!"
  ),
  floor: Yup.string().required("Nomi bo'sh bo'lmasligi kerak!"),
});

export const floorsData = [
  { id: 0, name: "0-qavat" },
  { id: 1, name: "1-qavat" },
  { id: 2, name: "2-qavat" },
  { id: 3, name: "3-qavat" },
  { id: 4, name: "4-qavat" },
  { id: 5, name: "5-qavat" },
];
